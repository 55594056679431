<template>
  <div>
    <b-modal
      size="lg"
      v-model="oncontrol"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      class="border-0 border-white"
    >
      <template #modal-header>
        <div class="w-100 d-flex justify-content-start align-items-center">
          <tabler-icon
            icon="Menu2Icon"
            size="20"
            class="cursor-pointer mr-2"
          ></tabler-icon>
          <h4 class="font-weight-bolder p-0 m-0 text-white">Tracking</h4>
        </div>
      </template>
      <b-container fluid class="p-1">
        <b-table
          slot="table"
          ref="refTracking"
          no-provider-filtering
          :items="getTracking"
          :fields="fieldsTracking"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          sticky-header="70vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(process_name)="data">
            <span class="badge" :class="classProcess(data.item.process_id)">
              {{ data.value }}
            </span>
          </template>
          <template #cell(created_at)="data">
            {{ data.value | myGlobalDay }}
          </template>
        </b-table>
      </b-container>
      <template #modal-footer>
        <b-button variant="danger" @click="closeModalTracking">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import serviceDashboard from "@/views/assistance/views/dashboard/services/dashboard.services.js";
export default {
  props: {
    idJustification: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fieldsTracking: [
        {
          key: "created_by_name",
          label: "Process by",
        },
        {
          key: "process_name",
          label: "Process",
        },
        {
          key: "created_at",
          label: "Created at",
        },
      ],
      items: [],
      oncontrol: false,
    };
  },
  methods: {
    closeModalTracking() {
      this.$emit("closeModal");
    },
    async refreshTable() {
      this.$refs.refTracking.refresh();
    },
    classProcess(process_id) {
      switch (process_id) {
        case 1:
          return "bg-secondary";
        case 2:
          return "bg-warning";
        case 4:
          return "bg-success";
        case 6:
          return "bg-primary";
        default:
          return "bg-danger";
      }
    },
    async getTracking() {
      try {
        const {data} = await serviceDashboard.getTrackingJustification({
          id_justification: this.idJustification,
        });
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
        console.log("tracking justification ", error);
      }
    },
  },
  mounted() {
    this.oncontrol = true;
  },
};
</script>