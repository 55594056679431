export default [
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Month",
        model: null,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "January" },
            { value: 2, label: "February" },
            { value: 3, label: "March" },
            { value: 4, label: "April" },
            { value: 5, label: "May" },
            { value: 6, label: "June" },
            { value: 7, label: "July" },
            { value: 8, label: "August" },
            { value: 9, label: "September" },
            { value: 10, label: "October" },
            { value: 11, label: "November" },
            { value: 12, label: "December" }
        ],
        reduce: "value",
        selectText: "label",
        cols: 6,
        visible: true,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Year",
        model: null,
        options: [],
        reduce: "value",
        selectText: "label",
        cols: 6,
        visible: true,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Process",
        model: null,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "Pending" },
            { value: 2, label: "Approved By Chief" },
            { value: 3, label: "Rejected By Chief" },
            { value: 4, label: "Approved By Managment" },
            { value: 5, label: "Rejected By Managment" },
            { value: 6, label: "Approved By Human Talent" },
            { value: 7, label: "Rejected By Human Talent" },
        ],
        reduce: "value",
        selectText: "label",
        cols: 12,
        visible: true,
    },
]