<template>
  <div class="px-2 mt-1">
    <b-modal
      ref="modal-organization"
      no-close-on-backdrop
      size="xl"
      :title="'Organization Chart'"
      hide-footer
      @hidden="$emit('hidden')"
    >
      <!--ORGANIZATION CHART-->
      <template>
        <!--GENERAL ORGANIZATION CHART-->
        <b-card
          v-if="operation == 2"
          class="mt-2 position-relative shadow-none p-0"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-center "
            >
              <h3 class="p-3 title-chart title-general">
                <span>GENERAL ORGANIZATION CHART</span>
                <br>
                <b-button
                  variant="success"
                  style="right: 10px;top: 10px;"
                  class="mt-1"
                  @click="restartChart('general')"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    size="15"
                    class="mr-1"
                    :class="loadChartGeneral ? 'load':''"
                  />
                  Restart
                </b-button>
              </h3>
            </b-col>
          </b-row>
          <!--buttons to ZOOM IN or ZOOM OUT-->
          <b-col cols="12">
            <div
              class="d-flex justify-content-around align-items-center p-1"
            >
              <aside class="d-flex p-1">
                <strong
                  class="cursor-pointer text-primary"
                  @click="zoomTree(1)"
                >
                  <feather-icon
                    icon="ZoomInIcon"
                    size="20"
                    class="hover-me"
                  />
                  Zoom In
                </strong>
                <strong
                  class="mx-2 cursor-pointer text-warning"
                  @click="zoomTree(-1)"
                >
                  <feather-icon
                    icon="ZoomOutIcon"
                    size="20"
                    class="hover-me"
                  />
                  Zoom out
                </strong>
                <strong
                  v-if="zoom[0] !== zoomNormal"
                  class="text-info cursor-pointer"
                  @click="zoomTree(0)"
                >
                  <feather-icon
                    icon="RotateCwIcon"
                    size="20"
                    class="hover-me"
                  />
                  No Zoom
                </strong>
              </aside>
            </div>
          </b-col>
          <!--TREE DATA-->
          <b-skeleton-img
            v-if="loadChartGeneral"
            no-aspect
            height="400px"
          />
          <vue-tree
            v-else
            id="vue-tree-org-general"
            ref="vue-tree-org-general"
            :key="treeKey"
            link-style="straight"
            style="width: 100%; height: 75vh;"
            :config="treeConfig"
            :dataset="treeGeneral"
            direction="vertical"
            class="p-0 position-relative"
            collapsed-enabled
          >
            <template v-slot:node="{ node }">
              <!--CARDS-->
              <div
                class="position-relative d-flex justify-content-center align-items-center w-auto rounded show-element"
                :style="isDarkSkin ? 'background-color: #17171A;' : ''"
                :class="node.value ? node.value.toLowerCase()==='ceo'?'container-card-main':'container-card-general' : ''"
                style="font-size: 13px;padding: 20px 10px 10px 10px;max-width: 180px;min-width: 180px;min-height: 110px;"
              >
                <!--avatar-->
                <!--image CEO-->
                <img
                  v-if="node.value ? node.value.toLowerCase() === 'ceo' : false"
                  src="@/assets/images/logo/amg_logo.svg"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
                <!--image MODULES-->
                <img
                  v-else
                  :src="node.avatar?`${process.BASE_URL}assets${node.avatar}`:require('@/assets/images/logo/amg_logo.svg')"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
                <div class="d-block">
                  <strong
                    class="departament w-100 d-flex justify-content-center alig-items-center text-center text-uppercase"
                  >{{
                    node.value }}</strong>
                  <span
                    class="w-100 d-flex justify-content-center alig-items-center text-center"
                    :class="node.responsible === 1?'responsible':''"
                    style="text-transform: capitalize !important;"
                  >{{ node.name && hasBrackets(node.name) ? removeBracketsAndQuotes(node.name) : node.name }}</span>
                </div>
              </div>
            </template>
          </vue-tree>
        </b-card>
        <!--DEPARTAMENTAL ORGANIZATION CHART-->

        <b-card
          v-if="operation == 1"
          class="mt-2 position-relative shadow-none p-0"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-center position-relative"
            >
              <h3 class="p-3 title-chart title-departamental d-block">
                <span>DEPARTMENTAL ORGANIZATION CHART</span><br>
                <b-button
                  variant="primary"
                  style="right: 10px;top: 10px;"
                  class="mt-1"
                  @click="restartChart('departamental')"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    size="15"
                    class="mr-1"
                    :class="loadChartDepartamental ? 'load':''"
                  />
                  Restart
                </b-button>
              </h3>
            </b-col>
            <b-col cols="12">
              <div
                class="d-flex justify-content-around align-items-center p-1"
              >
                <aside class="d-flex p-1">
                  <strong
                    class="cursor-pointer text-primary"
                    @click="zoomTree(1)"
                  >
                    <feather-icon
                      icon="ZoomInIcon"
                      size="20"
                      class="hover-me"
                    />
                    Zoom In
                  </strong>
                  <strong
                    class="mx-2 cursor-pointer text-warning"
                    @click="zoomTree(-1)"
                  >
                    <feather-icon
                      icon="ZoomOutIcon"
                      size="20"
                      class="hover-me"
                    />
                    Zoom out
                  </strong>
                  <strong
                    v-if="zoom[0] !== zoomNormal"
                    class="text-info cursor-pointer"
                    @click="zoomTree(0)"
                  >
                    <feather-icon
                      icon="RotateCwIcon"
                      size="20"
                      class="hover-me"
                    />
                    No Zoom
                  </strong>
                </aside>
              </div>
            </b-col>
          </b-row>
          <!--TREE-->
          <b-skeleton-img
            v-if="loadChartDepartamental"
            no-aspect
            height="400px"
          />
          <vue-tree
            v-else
            id="vue-tree-org-departamental"
            ref="vue-tree-org-departamental"
            :key="treeDepartamentalKey"
            link-style="straight"
            style="width: 100%; height: 75vh;"
            :config="treeConfig"
            :dataset="treeDepartamental"
            direction="vertical"
            class="p-0"
            collapse-enabled
          >
            <template v-slot:node="{ node }">
              <div
                class="position-relative d-flex justify-content-center align-items-center w-auto rounded show-element"
                :style="isDarkSkin ? 'background-color: #17171A;' : ''"
                :class="node.value ? node.value.toLowerCase() === 'ceo' ? 'container-card-main': node.employee?'container-card-employee':'container-card-departamental' : ''"
                style="font-size: 13px;padding: 20px 10px 10px 10px;"
              >
                <!--image CEO-->
                <img
                  v-if="node.value ? node.value.toLowerCase() === 'ceo': false"
                  src="@/assets/images/logo/amg_logo.svg"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
                <!--image EMPLOYEE-->
                <img
                  v-else-if="node.employee"
                  :src="node.avatar?node.avatar:'https://icon-library.com/images/users-icon-png/users-icon-png-9.jpg'"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
                <!--image MODULES-->
                <img
                  v-else
                  :src="node.avatar?`${process.BASE_URL}assets${node.avatar}`:require('@/assets/images/logo/amg_logo.svg')"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
                <!--data-->
                <div class="d-block w-100 px-1">
                  <strong
                    class="departament w-100 d-flex justify-content-center alig-items-center text-center text-uppercase"
                  >{{
                    node.value }}</strong>
                  <span
                    class="w-100 d-flex justify-content-center align-items-center text-center"
                    :class="node.responsible === 1 ? 'responsible' : ''"
                    style="text-transform: capitalize !important; margin-top: 3px;"
                  >
                    {{ node.name && hasBrackets(node.name) ? removeBracketsAndQuotes(node.name) : node.name }}
                  </span>
                  <!--roles-->
                  <div class="w-100 d-flex justify-content-between align-items-center text-center pt-1">
                    <template v-if="node.roles===null">
                      <feather-icon
                        :id="`departament-roles-of-${node.value}`"
                        icon="UsersIcon"
                        size="20"
                        class="text-danger icon"
                      />
                      <b-tooltip
                        :target="`departament-roles-of-${node.value}`"
                        triggers="hover"
                        variant="light"
                        placement="bottom"
                      >
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="showModalRoles(node)"
                        >
                          View Roles
                        </b-button>
                      </b-tooltip>
                    </template>
                    <template v-else-if="node.roles ? JSON.parse(node.roles).length === 1 : false">
                      <feather-icon
                        v-if="!node.have_mof"
                        icon="BookOpenIcon"
                        size="20"
                        class="text-secondary icon"
                      />
                      <feather-icon
                        v-else
                        :id="`departament-mof-of-${node.value}`"
                        icon="BookOpenIcon"
                        size="20"
                        class="text-primary icon"
                      />
                      <b-tooltip
                        :target="`departament-mof-of-${node.value}`"
                        triggers="hover"
                        variant="light"
                        placement="bottom"
                      >
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="getMOF(JSON.parse(node.roles)[0].id)"
                        >
                          View MOF
                        </b-button>
                      </b-tooltip>
                    </template>
                    <feather-icon
                      v-if="!node.employee"
                      :id="`department-of-${node.value}`"
                      icon="ListIcon"
                      size="20"
                      class="text-info"
                    />
                    <b-tooltip
                      :target="`department-of-${node.value}`"
                      triggers="hover"
                      variant="light"
                      placement="bottom"
                    >
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        @click="getDepartament(node)"
                      >
                        Department
                      </b-button>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </vue-tree>
        </b-card>
      </template>
      <!--MODAL ROLES AND MOFS-->
      <modal-roles
        v-if="showRoles"
        :node-id="nodeId"
        @closeModal="closeModalRoles"
      />

      <!--Modal Review-->
      <modal-review
        v-if="showModalReviewMofByCeo"
        :mof-data="selectedMOF"
        :readonly="true"
        @hidden="showModalReviewMofByCeo = false"
        @reload="refreshTable()"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VueTree from '@ssthouse/vue-tree-chart';
import ModalRoles from '@/views/commons/organization-chart/components/modals/ModalRoles.vue';
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';
import ModalReview from '@/views/commons/components/manual-of-functions/modals/ModalReviewByCEO.vue';

export default {
  components: {
    VueTree,
    ModalRoles,
    ModalReview,
  },
  props: {
    operation: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      optionShowSelected: 'department',
      optionsShow: [
        { text: 'Selected Department', value: 'department' },
        { text: 'All Deparments', value: 'all' },
      ],
      showRoles: false,
      showModal: false,
      treeGeneral: {
        value: 'AMG',
        name: 'CEO-ROGER SEGURA',
        avatar: '@/assets/images/logo/amg_logo.svg',
        roles: null,
        children: [
          {
            value: 'AREA COMERCIAL',
            name: '',
            roles: null,
            avatar: '@/assets/images/logo/amg_logo.svg',
            children: [
              // {
              //   value: 'DEBT SOLUTION', name: 'cargo y nombre', roles: null, avatar: '', children: [],
              // },
              // {
              //   value: 'CREDI EXPERT', name: 'cargo y nombre', roles: null, avatar: '', children: [],
              // },
              // {
              //   value: 'BUSSINESS', name: 'cargo y nombre', roles: null, avatar: '', children: [],
              // },
              // {
              //   value: 'OTRO DEPARTAMENTO', name: 'cargo y nombre', roles: null, avatar: '', children: [],
              // },
            ],

          },
          {
            value: 'AREA TECNOLOGICA',
            name: '',
            roles: null,
            avatar: '@/assets/images/logo/amg_logo.svg',
            children: [
              // {
              //   value: 'PARAGON',
              //   name: 'cargo y nombre',
              //   avatar: '@/assets/images/logo/amg_logo.svg',
              //   roles: null,
              //   children: null,
              //
              // },
              // {
              //   value: 'OTRO', name: 'cargo y nombre', roles: null, avatar: '@/assets/images/logo/amg_logo.svg', children: [],
              // },
              // {
              //   value: 'OTRO', name: 'cargo y nombre', roles: null, avatar: '@/assets/images/logo/amg_logo.svg', children: [],
              // },
              // {
              //   value: 'OTRO', name: 'cargo y nombre', roles: null, avatar: '@/assets/images/logo/amg_logo.svg', children: [],
              // },
            ],

          },
          {
            value: 'Administracion',
            name: '',
            roles: '',
            avatar: '@/assets/images/logo/amg_logo.svg',
            children: [],

          },
          {
            value: 'RRHH',
            name: '',
            roles: '',
            avatar: '@/assets/images/logo/amg_logo.svg',
            children: [],

          },
        ],
      },
      treeDepartamental: {},
      // tempTreeGeneral: [],
      // tempTreeDepartamental: [],

      treeConfig: { nodeWidth: 210, nodeHeight: 100, levelHeight: 220 },
      paramsRoles: [],
      zoom: {
        0: 1, // index of first tree
        1: 1, // index of second tree
      },
      zoomNormal: 1,
      treeKey: 0,
      treeDepartamentalKey: 0,
      loadChartGeneral: true,
      loadChartDepartamental: false,
      showModalReviewMofByCeo: false,
      selectedMOF: {},
      process: null,
      nodeId: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-organization');
    this.process = process.env;
    if (this.operation === 1) {
      this.getNodeOrganizationChart();
    } else {
      this.getOrganizationChartGeneral();
    }
  },
  methods: {
    removeBracketsAndQuotes(name) {
      return name.replace(/[\[\]"]/g, '');
    },
    hasBrackets(str) {
      // Verificar si la cadena contiene corchetes [ ]
      return str.includes('[') && str.includes(']');
    },
    refreshOrganizationChart() {
      this.showModal = false;
      this.treeKey += 1;
      this.treeDepartamentalKey += 1;
    },
    async getMOF(rolId) {
      try {
        const params = {
          page: 1,
          perpage: 100,
          campo: null,
          tab: 2,
          user_id: null,
          rol_id: rolId,
        };
        const { data } = await WorkAreasService.getMof(params);
        this.selectedMOF = data?.data[0] || {};
        this.showModalReviewMofByCeo = true;
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
      }
    },

    async getOrganizationChartGeneral() {
      try {
        this.loadChartGeneral = true;
        const params = {
          work_area_id: 1,
          general: true,
        };
        const { data } = await WorkAreasService.getDataOrganizationChartGeneral(params);

        this.treeGeneral = data;
        this.loadChartGeneral = false;
      } catch (error) {
        this.loadChartGeneral = false;
        console.log(error);
      }
    },
    async getOrganizationChartDepartaments() {
      try {
        this.loadChartDepartamental = true;
        const params = {
          work_area_id: 1,
          general: false,
        };
        const { data } = await WorkAreasService.getDataOrganizationChartGeneral(params);

        this.treeDepartamental = data;
        this.loadChartDepartamental = false;
      } catch (error) {
        this.loadChartDepartamental = false;
        console.log(error);
      }
    },

    async getNodeOrganizationChart() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
        };
        const data = await WorkAreasService.getNodeOrganizationChart(params);
        // this.treeDepartamental = data.data;
        this.getDepartament(data.data);
        // console.log(this.treeDepartamental);
      } catch (error) {
        this.loadChartDepartamental = false;
        console.log(error);
      }
    },

    async getDepartament(node) {
      try {
        this.loadChartDepartamental = true;
        const params = {
          work_area_id: node.id,
          is_general: false,
        };
        const { data } = await WorkAreasService.getDataOrganizationChart(params);

        node.children = data;
        this.treeDepartamental = node;
        this.treeDepartamentalKey += 1;
        this.loadChartDepartamental = false;
      } catch (error) {
        this.loadChartDepartamental = false;
        console.log(error);
      }
    },
    openModalAssign() {
      this.showModal = true;
    },
    showModalRoles(node) {
      this.showRoles = true;
      this.nodeId = node.id;
      // const parsedRoles = JSON.parse(roles);
      // this.paramsRoles = this.removeDuplicateRoles(parsedRoles);
    },
    removeDuplicateRoles(roles) {
      const uniqueRoles = [];
      const roleIds = new Set();

      for (const role of roles) {
        if (!roleIds.has(role.id)) {
          uniqueRoles.push(role);
          roleIds.add(role.id);
        }
      }

      return uniqueRoles;
    },
    closeModalRoles() {
      this.showRoles = false;
    },
    zoomTree(value, indexTree = 0) {
      // indexTree: order in HTML
      const treeCards = document.querySelectorAll('.dom-container');
      const treeLines = document.querySelectorAll('.vue-tree');
      const { transform } = getComputedStyle(treeLines[indexTree]);
      const scaleTranslate = transform.match(/matrix\(([^)]+)\)/)[1].split(', ');
      let translateX = parseInt(scaleTranslate[4], 10);
      let translateY = parseInt(scaleTranslate[5], 10);

      // limits
      const maxZoom = 1.3;
      const minZoom = 0.4;

      if (value === 1) {
        this.zoom[indexTree] += !(this.zoom[indexTree] >= maxZoom) ? 0.1 : 0;
      } else if (value === -1) {
        this.zoom[indexTree] -= !(this.zoom[indexTree] <= minZoom) ? 0.1 : 0;
      } else {
        this.zoom[indexTree] = this.zoomNormal;
        translateX = parseInt(treeCards[indexTree].getBoundingClientRect().width / 2, 10);
        translateY = 0;
      }
      // we add zoom to tree (lines and cards)
      treeLines[indexTree].style.transform = `scale(${this.zoom[indexTree]}) translate(${translateX}px , ${translateY}px)`;
      treeCards[indexTree].style.transform = `scale(${this.zoom[indexTree]}) translate(${translateX}px , ${translateY}px)`;
    },
    async restartChart(type) {
      if (type.toLowerCase() === 'departamental') {
        await this.getNodeOrganizationChart();
      } else if (type.toLowerCase() === 'general') {
        await this.getOrganizationChartGeneral();
      }
    },
    async getMember(node) {
      try {
        const params = {
          work_area_id: node.id,
        };
        const { data } = await WorkAreasService.getMember(params);
        node.children = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&display=swap');

.title-chart {
  font-family: 'ADLaM Display', cursive;
  letter-spacing: 2px;
  font-size: 35px;
}
.title-chart.title-general{
  border-bottom: 4px dashed #52BE80;
}
.title-chart.title-departamental{
  border-bottom: 4px dashed #5DADE2;
}
.title-chart.title-general span{
  background: -webkit-linear-gradient(#F7DC6F,#52BE80);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title-chart.title-departamental span{
  background: -webkit-linear-gradient(rgb(151, 227, 248),#5DADE2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.avatar-card{
  position: absolute;
  top: -40px;
  padding: 8px;
  border: 3px solid rgba(128, 128, 128, 0.658)
}

.container-card-main,
.container-card-general,
.container-card-departamental,
.container-card-employee {
  background-color: white;
  transition: 0.3s ease-in-out;
  max-width: 200px;min-width: 180px;min-height: 110px;
}
.container-card-main{
  /* border: 1px solid rgb(42, 218, 218); */
  border: 1.5px solid #F1C40F;
  transform: scale(1.2);
  color: #c5a113;
}
.container-card-general{
  border: 1px solid #F5B041;
}
.container-card-departamental{
  border: 1px solid #F1C40F;
}
.container-card-employee{
  border: 1px solid #52BE80;
}

.container-card-main .avatar-card{
  /* border: 3px solid rgb(42, 218, 218);
  background-color: rgb(42, 218, 218); */
  border: 3px solid #F1C40F;
  background-color: #F1C40F;
  transition: 0.3s ease-in-out;
}
.container-card-general .avatar-card{
  border: 3px solid #F5B041;
  /* background-color: #F5B041; */
  background-color: white;
  transition: 0.3s ease-in-out;
}
.container-card-departamental .avatar-card{
  border: 3px solid #F5B041;
  /* background-color: #F5B041; */
  background-color: white;
  transition: 0.3s ease-in-out;
}
.container-card-employee .avatar-card {
  border: 3px solid #52BE80;
  background-color: #52BE80;
  transition: 0.3s ease-in-out;
}
.container-card-main:hover .avatar-card,
.container-card-general:hover .avatar-card,
.container-card-departamental:hover .avatar-card,
.container-card-employee:hover .avatar-card{
  animation: flip 1s ease-in-out infinite alternate;
}

.container-card-main::before,
.container-card-general::before,
.container-card-departamental::before,
.container-card-employee::before {
  content: '';
  width: 100%;
  height: 5px;
  /* background-color: red; */
  position: absolute;
  top: 0px;
  border-radius: 5px 5px 0% 0%;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.container-card-main::before {
  /* background-color:  rgb(42, 218, 218); */
  background-color: #F1C40F;
}
.container-card-general::before {
  background-color: #F5B041;
}

.container-card-departamental::before {
  background-color: #F5B041;
}

.container-card-employee::before {
  background-color: #52BE80;
}

.container-card-main:hover,
.container-card-general:hover,
.container-card-departamental:hover,
.container-card-employee:hover {
  color: white !important;
  background-color: transparent !important;
}

.container-card-main:hover::before,
.container-card-general:hover::before,
.container-card-departamental:hover::before,
.container-card-employee:hover::before {
  height: 100%;
  transition: 0.5s ease-in-out;
  z-index: -1;
  border-radius: 5px;
}

.container-card-main:hover .icon,
.container-card-general:hover .icon,
.container-card-departamental:hover .icon,
.container-card-employee:hover .icon {
  color: white !important;
  transition: 0.5s ease-in-out;
}

.container-card-employee .responsible{
  font-weight: bold;
  font-size: 15px;
  margin-top: 2px;
  letter-spacing: 0.5px;
  color: #229954;
}
.dom-container {
  transform: scale(0.1);
}

.show-element {
  animation: appear 1s ease-in-out;
}
.load{
  animation: loading 1s ease-in-out infinite;
}
@keyframes appear {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}
@keyframes loading{
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
}
@keyframes flip{
  from{
    transform: rotateY(0deg);
  }to{
    transform: rotateY(360deg);
  }
}
</style>
