<template>
  <div>
    <!-- modal upload medical rest -->
    <b-modal
      v-model="onclose"
      scrollable
      size="lg"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      class="border-0 border-white"
      @hidden="closeModal"
    >
      <template #modal-title>
        <div class="d-flex justify-content-start align-items-center text-white">
          <tabler-icon
            icon="UploadIcon"
            size="20"
            class="mr-1"
          />
          <h4 class="m-0 p-0 text-white">
            {{ action ? "Show " : dataJustification.id ? "Edit " : "Upload " }}
            medical rest
          </h4>
        </div>
      </template>
      <b-container class="p-1">
        <validation-observer ref="formMedicalRest">
          <b-row>
            <b-col cols="6">
              <validation-provider
                v-if="[17,2,11,26].includes(currentUser.role_id)"
                v-slot="{ errors }"
                name="employee"
                rules="required"
              >
                <b-form-group
                  v-if="[17,2,11,26].includes(currentUser.role_id)"
                  label="Employee"
                >
                  <v-select
                    id="employee"
                    v-model="medicalRest.employee_id"
                    :disabled="action == 'show' ? true : false"
                    :options="usersByModule"
                    :reduce="(val) => val.id"
                    label="value"
                    placeholder="Select a employee ..."
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="action != 'show'"
                v-slot="{ errors }"
                name="File"
                :rules="isUpdateJustification ? '' : 'required'"
              >
                <b-form-group label="File">
                  <b-form-file
                    ref="file"
                    v-model="fileToUpload"
                    placeholder="Upload file medical rest"
                    accept=".jpg, .png, .jpeg, .pdf, .doc, .docx"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    @change="fileFill"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="start_date"
                rules="required"
              >
                <b-form-group label="Start Date">
                  <kendo-datepicker
                    id="start_date"
                    v-model="medicalRest.start_date"
                    v-mask="'##/##/####'"
                    :first-day-of-week="1"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :disabled="action == 'show' ? true : false"
                    :state="
                      errors[0] || (!validateDate && medicalRest.end_date) || maxDays
                        ? false
                        : null
                    "
                    :class="
                      errors[0] || (!validateDate && medicalRest.end_date) || maxDays
                        ? 'border-danger'
                        : ''
                    "
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="end_date"
                rules="required"
              >
                <b-form-group label="End Date">
                  <kendo-datepicker
                    id="end_date"
                    v-model="medicalRest.end_date"
                    v-mask="'##/##/####'"
                    :first-day-of-week="1"
                    :min="minDateRange"
                    :max="maxDateRange"
                    placeholder="MM/DD/YYYY"
                    :disabled="action == 'show' || !medicalRest.start_date ? true : false"
                    :format="'MM/dd/yyyy'"
                    :state="
                      errors[0] || (!validateDate && medicalRest.end_date) || maxDays
                        ? false
                        : null
                    "
                    :class="
                      errors[0] || (!validateDate && medicalRest.end_date) || maxDays
                        ? 'border-danger'
                        : ''
                    "
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                  <small
                    v-if="!validateDate && medicalRest.end_date"
                    class="text-danger"
                  >End date cannot be less than start date</small>
                  <small
                    v-if="maxDays"
                    class="text-danger"
                  >
                    Date range cannot be more than 6 months
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              class="pl-1 d-block justify-content-center align-items-center text-right"
            >
              <div
                style="width: 100%; height: 300px"
              >
                <a
                  v-if="action == 'show'"
                  :href="medicalRest.fileImg"
                  target="_blank"
                  class="bg-primary text-white rounded text-decoration-none"
                  style="padding: 5px 10px"
                  download
                >
                  View file
                  <feather-icon icon="EyeIcon" />
                </a>
                <img
                  v-if="isImage | !medicalRest.fileImg"
                  :src="
                    medicalRest.fileImg
                      ? medicalRest.fileImg
                      : 'https://programacion.net/files/article/20160819020822_image-not-found.png'
                  "
                  alt="Medical rest"
                  width="100%"
                  height="100%"
                  style="height: 300px"
                >
                <div
                  v-else-if="['doc', 'docx'].includes(medicalRest.extension)"
                  class="text-primary list-group-item-primary d-flex justify-content-center align-items-center rounded-lg"
                  style="width:100%;height:100%"
                >
                  <tabler-icon
                    icon="FileTextIcon"
                    size="50"
                  />
                  <label
                    for=""
                    class="text-primary d-flex justify-content-center align-content-center mt-2 ml-2 text-uppercase"
                    style="letter-spacing: 5px; font-size: 20px"
                  >{{ medicalRest.extension }}</label>
                </div>
                <iframe
                  v-else
                  :src="medicalRest.fileImg"
                  frameborder="0"
                  width="100%"
                  height="100%"
                  style="height: 300px"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-group label="Description">
                  <b-form-textarea
                    id="medicalRest_motive"
                    v-model="medicalRest.motive"
                    class="text-area-box roboto-class"
                    name="text"
                    cols="30"
                    rows="2"
                    max-length="255"
                    placeholder="What is the reason?"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :disabled="action == 'show' ? true : false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>

        <b-row
          v-if="validateDates != []"
          class="mt-1"
        >
          <b-col>
            <h4>Validate Hours Date</h4>
            <hr>
            <template v-if="validateDates.length">
              <div class="scroll-container">
                <b-list-group>
                  <validation-observer ref="formHoursValidate">
                    <b-list-group-item
                      v-for="(validateDate, index) in validateDates"
                      :key="validateDate.date"
                    >
                      <b-row>
                        <b-col cols="6">
                          <span>{{ validateDate.date | myDateGlobal }}</span>
                        </b-col>
                        <b-col cols="3">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="`hour-${index}`"
                            rules="required|between:0,8"
                          >
                            <b-form-input
                              v-model="validateDate.hours"
                              :disabled="action == 'show' ? true : false"
                              type="number"
                              min="0"
                              max="8"
                              :max-length="2"
                              :state="errors[0] ? false : null"
                              :class="errors[0] ? 'border-danger' : null"
                              class="mb-0"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-danger"
                            >{{
                              validateDate.hours > 8 ? "Max 8" : errors[0]
                            }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </validation-observer>
                </b-list-group>
              </div>
            </template>
            <template v-else>
              <b-card
                class="shadow-none"
                :style="
                  !isDarkSkin
                    ? 'background-color: #d6eaf8'
                    : 'background-color: #3E3E3F'
                "
              >
                <b-container
                  class="d-flex justify-content-center align-items-center"
                  style="height: 150px"
                >
                  <div
                    class="text-center"
                    style="color: #2980b9"
                  >
                    <tabler-icon
                      icon="MoodHappyIcon"
                      size="40"
                      class="mr-1 mb-2"
                    />
                    <br>
                    <span style="font-size: 25px">AMG is happy that you are well !!!</span>
                  </div>
                </b-container>
              </b-card>
            </template>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            v-if="action != 'show'"
            variant="primary"
            @click="insertUpdateJustification"
          >Save</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import serviceDashboard from '@/views/assistance/views/dashboard/services/dashboard.services';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    employee_id: {
      type: Number,
      default: 0,
    },
    action: {
      type: Number,
      default: null,
    },
    dataJustification: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      keyImg: 0,
      onclosePreview: false,
      onclose: true,
      fileToUpload: [],
      medicalRest: {
        employee_id: null,
        image: '',
        fileImg: '',
        start_date: null,
        end_date: null,
        motive: '',
        extension: '',
      },
      validateDates: [],
      validateCounterStartDate: 0,
      validateCounterEndDate: 0,
      usersByModule: [],
    };
  },
  computed: {
    isImage() {
      const extension = ['png', 'jpg', 'jpeg'];
      return extension.includes(this.medicalRest.extension);
    },
    validateDate() {
      if (this.medicalRest.start_date > this.medicalRest.end_date) {
        return false;
      }
      return true;
    },
    isUpdateJustification() {
      return !!this.dataJustification.id;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    maxDays() {
      if ((this.medicalRest.end_date != null && this.medicalRest.end_date != null) && this.calculateDateRangeInDays(this.medicalRest.start_date, this.medicalRest.end_date) > 180) {
        return true;
      }
      return false;
    },
    maxDateRange() {
      let date = null;
      if ((this.medicalRest.start_date != null)) {
        date = moment(this.medicalRest.start_date, 'MM/dd/yyyy').add(180, 'days').toDate();
      }
      return date;
    },
    minDateRange() {
      const date = null;
      if ((this.medicalRest.start_date != null)) {
        return moment(this.medicalRest.start_date, 'MM/dd/yyyy').toDate();
      }
      return date;
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'medicalRest.start_date': function (val) {
      if (val) {
        if (this.dataJustification.id == null) {
          this.validateCounterStartDate = 1;
        }
        if (this.validateCounterStartDate === 1) {
          this.listarFechas();
        }

        if (this.dataJustification.id) {
          this.validateCounterStartDate = 1;
        }
      }
    },
    // eslint-disable-next-line func-names
    'medicalRest.end_date': function (val) {
      if (val) {
        if (this.dataJustification.id == null) {
          this.validateCounterEndDate = 1;
        }

        if (this.validateCounterEndDate === 1) {
          this.listarFechas();
        }

        if (this.dataJustification.id) {
          this.validateCounterEndDate = 1;
        }
      }
    },
  },
  created() {
    if (this.dataJustification.id) {
      this.medicalRest.start_date = moment(
        this.dataJustification.start_date,
        'YYYY-MM-DD',
      ).format('MM-DD-YYYY');
      this.medicalRest.end_date = moment(
        this.dataJustification.end_date,
        'YYYY-MM-DD',
      ).format('MM-DD-YYYY');
      if (this.dataJustification.observation === '') {
        this.medicalRest.motive = this.dataJustification.motive_j;
      } else {
        this.medicalRest.motive = this.dataJustification.observation;
      }
      this.medicalRest.fileImg = this.dataJustification.image;
      this.medicalRest.extension = this.dataJustification.extension;
      // eslint-disable-next-line camelcase
      const justification_hours = this.dataJustification.justification_hours
        ? JSON.parse(this.dataJustification.justification_hours)
        : [];
      // eslint-disable-next-line camelcase
      this.validateDates = justification_hours;
    }
  },
  async mounted() {
    this.listUserByModule();
    this.medicalRest.employee_id = this.employee_id;
  },
  methods: {
    calculateDateRangeInDays(startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

      // Convert the input dates to JavaScript Date objects
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Calculate the difference in days
      const diffInDays = Math.round(Math.abs((startDateObj - endDateObj) / oneDay));

      return diffInDays;
    },
    listarFechas() {
      this.validateDates = [];
      const fechaInicial = moment(this.medicalRest.start_date);
      const fechaFinal = moment(this.medicalRest.end_date);
      const fechas = [];

      while (fechaInicial <= fechaFinal) {
        const dayOfWeek = fechaInicial.day();
        let hours = 0;

        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          hours = 8; // Lunes a viernes
        } else if (dayOfWeek === 6) {
          hours = 6; // Sábado
        }

        fechas.push({
          date: fechaInicial.format('YYYY-MM-DD'),
          hours,
        });

        fechaInicial.add(1, 'day');
      }

      this.validateDates = fechas;
    },

    async insertUpdateJustification() {
      const result = await this.$refs.formMedicalRest.validate();
      const resultHours = await this.$refs.formHoursValidate.validate();
      // form medical invalid, here invalid dates or invalid hours
      if (
        !result
        || this.medicalRest.start_date > this.medicalRest.end_date
        || !resultHours
      ) {
        return;
      }
      const nameEmployee = this.usersByModule.find(
        user => user.id === this.medicalRest.employee_id,
      )?.value;
      const resultState = await this.$swal.fire({
        title: 'MEDICAL REST',
        html:
          `<div class='p-1'> <b>Name:</b> ${
            nameEmployee
          }<br><b>Start Date:</b> ${
            this.medicalRest.start_date
          }<br><b>End Date:</b> ${
            this.medicalRest.end_date
          }<br><b>Motive:</b> ${
            this.medicalRest.motive
          }</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save!',
        cancelButtonText: 'Cancel!',
        reverseButtons: true,
      });
      if (resultState.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            created_by_id: this.currentUser.user_id,
            update_by_id: this.currentUser.user_id,
            user_id: this.medicalRest.employee_id,
            image: this.medicalRest.image,
            fileImg: this.medicalRest.fileImg,
            start_date: moment(
              this.medicalRest.start_date,
              'MM-DD-YYYY',
            ).format('YYYY-MM-DD'),
            end_date: moment(this.medicalRest.end_date, 'MM-DD-YYYY').format(
              'YYYY-MM-DD',
            ),
            motive: this.medicalRest.motive,
            type_justification: 4,
            date_overtime: null,
            id_justification: this.dataJustification.id
              ? this.dataJustification.id
              : null,
            validateDates: this.validateDates,
            role_id: this.currentUser.role_id,
          };
          const { data } = params.id_justification
            ? await serviceDashboard.updateJustification(params)
            : await serviceDashboard.insertJustification(params);
          if (data.state === -1) {
            this.showInfoSwal(
              'You have already registered a medical rest for this date',
              'Warning',
            );
          } else {
            this.showSuccessSwal();
            this.$refs.formMedicalRest.reset();
            this.$emit('refreshData');
            this.closeModal();
          }
          this.removePreloader();
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal();
          console.log(error);
        }
      }
    },
    closeModal() {
      this.oncontrol = false;
      this.$emit('closeModal');
    },
    async fileFill(event) {
      // imagen, pdf
      const accept = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const image = event.srcElement.files[0];

      if (image) {
        if (!accept.includes(image.type)) {
          this.showInfoSwal('Only image or pdf Allowed', 'Warning');
          this.fileToUpload = [];
        } else {
          this.medicalRest.image = image.name;
          this.medicalRest.extension = image.name.split('.').pop();
          const reader = new FileReader();
          reader.readAsDataURL(image);
          // eslint-disable-next-line no-return-assign
          reader.onload = () => (this.medicalRest.fileImg = reader.result);
        }
      }
    },
    async listUserByModule() {
      try {
        const { data } = await serviceDashboard.getEmployeeByModule({
          employee_name: null,
          module_id: this.currentUser.main_module,
        });
        this.usersByModule = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.scroll-container {
  height: 200px; /* Ajusta la altura deseada */
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-container ::-webkit-scrollbar {
  display: none;
}

li {
  margin-bottom: 10px;
}
</style>
