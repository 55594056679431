<template>
  <div>
    <div
      class="title-modules text-dark my-2 d-flex align-items-center justify-content-between"
    >
      <span>Your modules </span>
      <div
        v-if="parentModuleId"
        class="title-modules text-dark d-flex align-items-center clickable"
        @click="returnModules"
      >
        <feather-icon icon="ChevronLeftIcon" size="20" />
        <span> Return </span>
      </div>
    </div>
    <div class="container-modules" style="gap: 1rem">
      <template v-if="!parentModuleId">
        <div
          v-for="(item, index) in userModules"
          :key="index"
          class="modules-item"
        >
          <img
            :src="`/assets/${item.module_icon}`"
            width="65"
            alt="Logo"
            height="65"
            class="mt-13 card-logo"
            :class="moduleWithoutLogo(item.module_id) ? '' : 'gray-home'"
            @click="openModule(item)"
          />
          <p class="font-name-home text-dark">
            {{ item.module_name }}
          </p>
        </div>
        <div class="modules-item">
          <div style="cursor: pointer" @click="openMessenger()">
            <img
              src="/assets/images/modules-icons/messenger.svg"
              width="65"
              alt="Logo"
              height="65"
              class="mt-13 card-logo"
            />
            <p class="font-name-home text-dark">MESSENGER</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in subModules"
          :key="index"
          class="modules-item"
        >
          <img
            :src="`/assets/${item.module_icon}`"
            width="65"
            alt="Logo"
            height="65"
            class="mt-13 card-logo"
            :class="'gray-home'"
            @click="openModule(item)"
          />
          <p class="font-name-home text-dark">
            {{ changeNames(item.module_name) }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import userService from "@/service/user/user.service";
import AlertActionService from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/services";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { FeatherIcon },
  data() {
    return {
      subModules: [],
      parentModuleId: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      alertActive: "CeDigitalCsAlerts/getStatusAlert",
    }),
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
    userModules() {
      if (this.currentUser) {
        if (Array.isArray(this.currentUser.arrRoles)) {
          return this.currentUser.arrRoles.filter(
            (item) => item.parent_id == null && item.module_id !== 6
          );
        }
        return JSON.parse(this.currentUser.arrRoles);
      }
      return [];
    },
    userSubModules() {
      return this.currentUser.arrRoles.filter(
        (item) => item.parent_id != null || item.module_id === 6
      );
    },
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_GET_USER_STATUS_SUBMODULE_SESSION:
        "UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION",
      A_OPEN_MODAL_ACTIONS: "CeDigitalWelcomeActions/A_OPEN_MODAL_ACTIONS",
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    moduleWithoutLogo(module_id) {
      return [3, 5, 7, 8, 11, 12, 15, 18, 21, 27, 14, 28, 29, 30].includes(
        module_id
      );
    },
    async openModule(item) {
      const { module_id, module_route } = item;
      const subModules = this.currentUser.arrRoles.filter(
        (item) =>
          item.parent_id === module_id ||
          (module_id == 21 && item.module_id === 6)
      );
      if (subModules.length > 0) {
        this.subModules = subModules;
        this.parentModuleId = module_id;
      } else {
        await this.changeSubModule(item);
      }
    },
    async changeSubModule(item) {
      try {
        const moduleCe = [22, 23, 24, 26];
        if (moduleCe.includes(item.module_id)) {
          const moduleData = await userService.getCurrentActiveModule({
            user_id: this.currentUser.user_id,
          });
          if (moduleData.status === 200) {
            if (moduleData.data.length > 0) {
              if (moduleData.data[0].module_id !== item.module_id) {
                const response = await this.showConfirmSwal(
                  "Are you sure you want to change sub module?",
                  "Remember that you will lose all unsaved data in the other sub modules."
                );
                if (response.isConfirmed) {
                  await this.moveToSubModule(item);
                }
              } else {
                await this.moveToSubModule(item);
              }
            } else {
              await this.moveToSubModule(item);
            }
          }
        } else {
          await this.$router.push({ path: item.module_route });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async moveToSubModule(item) {
      try {
        await this.$router.push({ path: item.module_route });
        const { data } = await AlertActionService.getAlertAccepted({
          user_id: this.currentUser.user_id,
        });
        if (data?.length > 0 && this.moduleId === 22) {
          this.$store.commit("AutoAlertStore/OPEN_ALERT_WITH_ID", {
            bool: true,
            id_alert: data[0].id,
            client_data: data[0],
          });
          // alert
        } else if (this.moduleId === 22) {
          await this.$store.dispatch(
            "UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION",
            {
              user_id: this.currentUser.user_id,
              module_id: this.moduleId,
            }
          );
        } else {
          const params = {
            created_by: this.currentUser.user_id,
            sub_module_id: item.module_id,
            status_sub_module_id: 1,
          };
          this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: 1 }]);
          await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
        }
      } catch (err) {
        console.log(err);
      }
    },
    openMessenger() {
      const token = localStorage.getItem("accessToken");
      const encryptToken = this.encryptToken(token);
      window.open(
        `${process.env.VUE_APP_MESSENGER_NEW_ROUTE}/autologin/${encryptToken}`,
        "_blank"
      );
    },
    encryptToken(token) {
      const encrypted = CryptoJS.AES.encrypt(
        `${token}`,
        "EstAEsUnaKeyCrEadAAmg1234"
      ).toString();
      return encrypted.replaceAll("/", "-");
    },
    changeNames(name) {
      const names = {
        "CUSTOMER SERVICE DIGITAL": "C.S. DIGITAL",
        "CUSTOMER SERVICE REGULAR": "C.S. REGULAR",
      };
      return names[name] || name;
    },
    returnModules() {
      this.subModules = [];
      this.parentModuleId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 115px;
}
.font-name-home {
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  text-align: center;
  font-family: "Avenir", sans-serif;
  margin-top: 10px;
}
.card-logo {
  min-height: 8em;
  min-width: 8em;
  padding: 1em;
  border-radius: 9px;
  box-shadow: 0px 0px 8.83905029296875px 0px #00000040;
  cursor: pointer;
}
.title-modules {
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 26.67px;
  word-wrap: break-word;
}
.container-modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}
@media (width <= 600px) {
  .container-modules {
    justify-content: center;
  }
}
</style>
