var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-home"},[_c('div',{staticClass:"pt-1 center-card-home"},[(_vm.isCeo)?_c('div',{staticClass:"btn-back"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.redirectDashboard}},[_c('feather-icon',{staticClass:"mr-05",attrs:{"icon":"CornerDownLeftIcon"}}),_vm._v(" Go to Dashboard ")],1)],1):_vm._e(),_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.userParentModules),function(item,index){return _c('b-col',{key:index,staticClass:"text-center mb-5 center-fix",attrs:{"md":"2","sm":"4","cols":"6"}},[(_vm.isCeo && item.module_id === 5)?_c('offer-menu-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Settlements'),expression:"'Settlements'",modifiers:{"hover":true,"top":true}}],staticClass:"menu-badge"}):_vm._e(),_c('router-link',{staticClass:"card-logo",attrs:{"to":{ path: item.module_route }}},[_c('img',{staticClass:"mt-13",class:[3, 5, 6, 7, 8, 11, 12, 15, 18, 21, 27, 14].includes(
                item.module_id
              )
                ? ''
                : 'gray-home',attrs:{"src":("/assets/" + (item.module_icon)),"width":"65","alt":"Logo","height":"65"}}),_c('p',{staticClass:"font-name-home",class:[
              _vm.skin == 'dark' ? 'text-light' : 'text-dark',
              item.module_id != 21 && 'mt-1' ],style:(item.module_id == 21
                ? 'margin-top: 10px !important; white-space: break-spaces;'
                : '')},[_vm._v(" "+_vm._s(item.module_name)+" ")])])],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }