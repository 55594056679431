<template>
  <b-modal
    v-model="showModal"
    size="sm"
    header-bg-variant="ligth"
    header-text-variant="primary"
    body-bg-variant="ligth"
    body-text-variant="dark"
    footer-bg-variant="ligth"
    footer-text-variant="dark"
    centered
    class="border-0 border-white"
    @hidden="closeModal"
  >
    <template #modal-title>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <h4 class="font-weight-bolder text-center w-100">
          {{ details.date_mark | myDateGlobal }}
        </h4>
      </div>
    </template>
    <b-container fluid v-if="dayOffWeek<7">
      <b-list-group class="pointer-events-none">
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Work start :</strong></b-col>
            <b-col
              class="text-left"
            ><span>{{ details.first_mark | myFullTime }}</span></b-col>
          </b-row>
        </b-list-group-item>


        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px" >
            <b-col><strong for="">Work End :</strong></b-col>
            <b-col
              class="text-left"
            ><span>{{ details.last_mark === details.first_mark? null : details.last_mark | myFullTime }}</span></b-col>
          </b-row>
        </b-list-group-item>
      <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Overtime :</strong></b-col>
            <b-col
              class="text-left"
            ><span>{{ details.overtime ? `${details.overtime} h` : "-" }}
            </span></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Total Hours :</strong></b-col>
            <b-col
              class="text-left"
            ><b>{{ details.working_hours | countHoursMinute }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Rounded hours :</strong></b-col>
            <b-col
              class="text-left"
            ><span>{{
              details.working_hours_rounded
                ? `${details.working_hours_rounded} h`
                : "-"
            }}</span></b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item class="border-success mt-1 list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Approved Justifications:</strong></b-col>
            <b-col
              class="text-left d-flex justify-content-start align-items-center"
            ><b>{{ `${details.overtime_approved} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-success list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Worked Hours:</strong></b-col>
            <b-col
              class="text-left"
            ><b>{{ `${details.working_mark_hours_valid} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-success list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Hours validated:</strong></b-col>
            <b-col
              class="text-left"
            ><b>{{ `${details.total_hours_validate} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-container>
    <b-container fluid v-else>
      <b-list-group class="pointer-events-none">
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Work start :</strong></b-col>
            <b-col
                class="text-left"
            ><span>{{ details.first_mark | myFullTime }}</span></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Start break:</strong></b-col>
            <b-col
                class="text-left"
            >  <span>{{ parseInt(details.num_marks)<2 ? null : details.second_mark | myFullTime }}</span></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">End break :</strong></b-col>
            <b-col
                class="text-left"
            >

              <span>{{ parseInt(details.num_marks)<3 ? null : details.third_mark | myFullTime }}</span>

            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">

            <b-col><strong for="">Work End :</strong></b-col>
            <b-col
                class="text-left"
            ><span>{{ parseInt(details.num_marks)<4 ? null : details.last_mark | myFullTime }}</span></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Overtime :</strong></b-col>
            <b-col
                class="text-left"
            ><span>{{ details.overtime ? `${details.overtime} h` : "-" }}
            </span></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Total Hours :</strong></b-col>
            <b-col
                class="text-left"
            ><b>{{ details.working_hours | countHoursMinute }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-0">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Rounded hours :</strong></b-col>
            <b-col
                class="text-left"
            ><span>{{
                details.working_hours_rounded
                    ? `${details.working_hours_rounded} h`
                    : "-"
              }}</span></b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item class="border-success mt-1 list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Approved Justifications:</strong></b-col>
            <b-col
                class="text-left d-flex justify-content-start align-items-center"
            ><b>{{ `${details.overtime_approved} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-success list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Worked Hours:</strong></b-col>
            <b-col
                class="text-left"
            ><b>{{ `${details.working_mark_hours_valid} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="border-success list-group-item-success">
          <b-row style="font-size: 15px">
            <b-col><strong for="">Hours validated:</strong></b-col>
            <b-col
                class="text-left"
            ><b>{{ `${details.total_hours_validate} h` }}</b></b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <b-badge
          class="p-1 rounded-pill text-white bg-transparent"
          style="font-size: 12px"
          :style="
            [
              'NOT REGISTER',
              'HOLIDAYS',
              'INCONSISTENCY',
              'UNMARKED',
              'ATTENDANCE',
              'DELAY',
              'MEDICAL REST',
              'BIRTHAY',
            ].includes(details.state)
              ? optionsState[details.state]
              : 'border:2px solid orange;color:orange !important;'
          "
        >{{ details.state }}</b-badge>
        <b-button
          variant="danger"
          @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import serviceDashboard from "@/views/assistance/views/dashboard/services/dashboard.services";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  props: {
    details: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      optionsState: {
        UNMARKED: 'background-color:#fc424a !important;font-size:12px !important;',
        FAULTS:
          'background-color:#fc424a !important;color:white !important;font-size:12px !important;',
        'MEDICAL REST': 'background-color:#85C1E9;',
        VACATIONS:
          'background-color:#F7DC6F !important;color:white;font-size:12px !important;',
        HOLIDAYS:
          'background-color:#8E44AD !important;color:white !important;font-size:12px !important;',
        'NOT REGISTER':
          'color:#5D6D7E !important;border:1px solid #5D6D7E !important;font-size:11px;background-color:white',
        'NO REGISTERED':
          'color:#5D6D7E !important;border:1px solid #5D6D7E !important;font-size:11px;background-color:white',
        ABSENCE: 'background-color:#fc424a !important;font-size:12px !important;',
        INCONSISTENCY:
          'background-color:#EB984E !important;font-size:12px !important;',
        ATTENDANCE: 'background-color:#2ECC71 !important;font-size:12px !important;',
      },
      showModal: false,
      dayOffWeek:null
    };
  },
  computed: {

    ...mapGetters({

      currentUser: 'auth/currentUser',

    }),

  },
  methods: {
    async getEmployeeScheduleById() {
      try {
        const params = {
          employee_id: this.details.employee_id,
          mark_time: (this.details.date_mark &&  this.details.first_mark) ?  moment(this.details.date_mark + " " + this.details.first_mark).format('YYYY-MM-DD HH:mm:ss') :null,

        };
        const { data } = await serviceDashboard.getEmployeesById(params);

        this.dayOffWeek = data.data[0].hours_worked;

      } catch (error) {
        this.showErrorSwal();
      }
    },
    closeModal() {
      this.showModal = false;
      this.$emit('hidden');
    },
  },
  mounted() {
    this.getEmployeeScheduleById();
    this.showModal = true;
  },
};
</script>
