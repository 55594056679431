var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-table"},[_c('b-table',{ref:"refClientsList",staticStyle:{"border-radius":"7px"},attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.miItems,"fields":_vm.myFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"filter":_vm.searchInput,"striped":""},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(name_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("nameDate")(data.item.date_mark))+" "+_vm._s(_vm._f("numDate")(data.item.date_mark))+" "),_c('br'),(data.item.tolerance == 'late')?_c('b-badge',{staticClass:"mt-1",staticStyle:{"border-radius":"8px","background":"#ff6d6d"},attrs:{"variant":"danger"}},[_vm._v(" LATE ")]):_vm._e()]}},{key:"cell(sessionusers)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.sessionusers)+" ")]}},{key:"cell(session_started)",fn:function(data){return [_c('span',{staticClass:"text-white font-weight-bolder",staticStyle:{"padding":"8px"}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(first_mark)",fn:function(data){return [_c('span',{staticClass:"state-style",class:_vm.typeAssistance(data.item.type)
            ? ''
            : !data.item.state
            ? 'bg-secondary'
            : !data.value
            ? 'bg-danger'
            : 'bg-primary',style:(_vm.typeAssistance(data.item.type) ? _vm.optionsState[data.item.type] : '')},[(_vm.typeAssistance(data.item.type))?[_vm._v(_vm._s(data.item.type))]:_c('label',{staticClass:"text-white text-montserrat",staticStyle:{"font-weight":"600","line-height":"18px","font-size":"16px"}},[_vm._v(_vm._s(data.value || data.item.type))])],2)]}},{key:"cell(second_mark)",fn:function(data){return [_c('span',{staticClass:"state-style",class:_vm.typeAssistance(data.item.type)
            ? ''
            : !data.item.state
            ? 'bg-secondary'
            : !data.value
            ? 'bg-danger'
            : 'bg-primary',style:(_vm.typeAssistance(data.item.type) ? _vm.optionsState[data.item.type] : '')},[(_vm.typeAssistance(data.item.type))?[_vm._v(" "+_vm._s(data.item.type))]:_c('label',{staticClass:"text-white text-montserrat",staticStyle:{"font-weight":"600","line-height":"18px","font-size":"16px"}},[_vm._v(_vm._s(data.value || data.item.type))])],2)]}},{key:"cell(third_mark)",fn:function(data){return [_c('span',{staticClass:"state-style",class:_vm.typeAssistance(data.item.type)
            ? ''
            : !data.item.state
            ? 'bg-secondary'
            : !data.value
            ? 'bg-danger'
            : 'bg-primary',staticStyle:{"padding":"8px"},style:(_vm.typeAssistance(data.item.type) ? _vm.optionsState[data.item.type] : '')},[(_vm.typeAssistance(data.item.type))?[_vm._v(_vm._s(data.item.type))]:_c('label',{staticClass:"text-white text-montserrat",staticStyle:{"font-weight":"600","line-height":"18px","font-size":"16px"}},[_vm._v(_vm._s(data.value || data.item.type))])],2)]}},{key:"cell(fourth_mark)",fn:function(data){return [_c('span',{staticClass:"state-style",class:_vm.typeAssistance(data.item.type)
            ? ''
            : !data.item.state
            ? 'bg-secondary'
            : !data.value
            ? 'bg-danger'
            : 'bg-primary',style:(_vm.typeAssistance(data.item.type) ? _vm.optionsState[data.item.type] : '')},[(_vm.typeAssistance(data.item.type))?[_vm._v(_vm._s(data.item.type))]:_c('label',{staticClass:"text-white text-montserrat",staticStyle:{"font-weight":"600","line-height":"18px","font-size":"16px"}},[_vm._v(_vm._s(data.value || data.item.type))])],2)]}},{key:"cell(last_mark)",fn:function(data){return [_c('span',{staticClass:"state-style",class:_vm.typeAssistance(data.item.type)
            ? ''
            : !data.item.state
            ? 'bg-secondary'
            : !data.value
            ? 'bg-danger'
            : 'bg-primary',style:(_vm.typeAssistance(data.item.type) ? _vm.optionsState[data.item.type] : '')},[(_vm.typeAssistance(data.item.type))?[_vm._v(_vm._s(data.item.type))]:_c('label',{staticClass:"text-white text-montserrat",staticStyle:{"font-weight":"600","line-height":"18px","font-size":"16px"}},[_vm._v(_vm._s(data.value || data.item.type))])],2)]}},{key:"cell(working_hours)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("countHoursMinute")(data.item.working_hours))+" ")])]}},{key:"cell(working_hours_rounded)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(((data.item.working_hours_rounded) + " h")))])]}},{key:"cell(working_mark_hours_valid)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(((data.item.working_mark_hours_valid) + " h")))])]}},{key:"cell(overtime_approved)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(((data.item.overtime_approved) + " h"))+" ")])]}},{key:"cell(total_hours_validate)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(((data.item.total_hours_validate) + " h"))+" ")])]}},{key:"cell(holidays)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(((data.item.holidays) + " h"))+" ")])]}},{key:"custom-foot",fn:function(){return [_c('b-tr',[_c('b-td',{attrs:{"colspan":"7"}}),_c('b-td',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"text-montserrat py-1 my-2",staticStyle:{"font-weight":"400","font-size":"19px","line-height":"14px"}},[_vm._v("Total (week)")])])]),_c('b-td',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('label',{staticClass:"d-flex justify-content-center align-items-center total-tag my-2",style:(_vm.isDarkSkin
                  ? 'background: #292C33'
                  : 'background: #F6F6FE; color: #18173D !important;')},[_vm._v(_vm._s(_vm.roundedHours)+"h")])])]),_c('b-td',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('label',{staticClass:"d-flex justify-content-center align-items-center total-tag my-2",style:(_vm.isDarkSkin
                  ? 'background: #292C33'
                  : 'background: #F6F6FE; color: #18173D !important;')},[_vm._v(_vm._s(_vm.validHoursWorked)+"h")])])]),_c('b-td',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('label',{staticClass:"d-flex justify-content-center align-items-center total-tag my-2",style:(_vm.isDarkSkin
                  ? 'background: #292C33'
                  : 'background: #F6F6FE; color: #18173D !important;')},[_vm._v(_vm._s(_vm.overtimeApproved)+"h")])])]),_c('b-td',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('label',{staticClass:"d-flex justify-content-center align-items-center total-tag my-2",style:(_vm.isDarkSkin
                  ? 'background: #292C33'
                  : 'background: #F6F6FE; color: #18173D !important;')},[_vm._v(_vm._s(_vm.holidays)+"h")])])]),_c('b-td',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('label',{staticClass:"d-flex justify-content-center align-items-center total-tag my-2",style:(_vm.isDarkSkin
                  ? 'background: #292C33'
                  : 'background: #F6F6FE; color: #18173D !important;')},[_vm._v(_vm._s(_vm.totalHours)+"h")])])])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }