<template>
  <div class="px-1 px-lg-2">
    <!-- <template v-if="loading">
      <b-row
        class="loading-spinner"
        style="margin-top: 5rem; margin-bottom: 5rem"
      >
        <b-col
          cols="12"
          class="d-flex"
          style="align-items: center; justify-content: center"
        >
          <div class="spinner" />
        </b-col>
      </b-row>
    </template> -->
    <template>
      <b-row>
        <b-col cols="7">
          <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
          <div
            class="d-flex justify-content-center align-items-center position-relative"
            style="height: 100%"
          >
            <div id="cont" data-pct="100">
              <svg
                id="svg"
                width="100%"
                height="100%"
                viewPort="0 0 80 100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  r="35%"
                  cx="50%"
                  cy="50%"
                  fill="transparent"
                  stroke-dashoffset="0"
                />
                <circle
                  id="hours-completed"
                  r="35%"
                  cx="50%"
                  cy="50%"
                  fill="transparent"
                  stroke-dasharray="565.48"
                  stroke-dashoffset="0"
                />
              </svg>
            </div>

            <div
              class="position-absolute d-flex justify-content-center align-items-center flex-column"
              style="margin-top: 20px"
            >
              <strong
                class="m-0 p-0 d-flex"
                style="font-size: 30px; line-height: 20px"
                >{{ hoursOfMonth }}</strong
              >
              <span
                class="d-flex justify-content-center align-items-center text-center m-0 p-0"
                >Hours</span
              >
            </div>
          </div>
        </b-col>
        <b-col
          cols="5"
          class="d-flex justify-content-start align-items-center flex-wrap"
        >
          <div class="d-flex justify-content-start align-items-start mb-1">
            <span class="square-blue" />
            <span
              >Completed hours<b>({{ hoursCompleted }})</b></span
            >
          </div>
          <div class="d-flex justify-content-start align-items-start">
            <span class="square-skyblue" />
            <span
              >Missing hours<b>({{ hoursToBeCompleted }})</b></span
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <div
            class="rounded list-group-item-success p-1 rounded w-100 rounded d-flex justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/images/icons/clockColor.svg')"
              class="mr-1"
            />
            <span class="mr-1" style="font-size: 16px">Accumulated hours</span>
            <b-badge variant="success" style="font-size: 15px">
              {{ hoursAccumulated }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import MainService from "@/views/main/menu.service";

export default {
  props: {
    month: {
      type: Number,
      default: () => 0,
      required: true,
    },
    year: {
      type: Number,
      default: () => 0,
      required: true,
    },
    userId: {
      type: Number,
      default: () => 0,
      required: true,
    },
  },
  data() {
    return {
      hoursAccumulated: 0,
      hoursCompleted: 0,
      hoursToBeCompleted: 0,
      currentDate: moment(`${this.year}-${this.month}`, "YYYY-MM").format(
        "YYYY-MM-DD"
      ),
      loading: false,
    };
  },
  computed: {
    hoursOfMonth() {
      let hours = 0;
      const totalDays = parseInt(
        moment(`${this.year}-${this.month}`, "YYYY-MM")
          .endOf("months")
          .format("DD"),
        10
      );
      for (let index = 1; index <= totalDays; index += 1) {
        const day = parseInt(
          moment(`${this.year}-${this.month}-${index}`, "YYYY-MM-DD").day(),
          10
        );
        if (day === 6) {
          // saturdary
          hours += 6;
        } else if (day !== 0) {
          // from monday to friday
          hours += 8;
        }
      }

      return hours;
    },
  },
  async mounted() {
    this.getTotalPerMonth();
  },
  methods: {
    setProgressPercentaje() {
      const circle = document.querySelector("#hours-completed");
      const offset = 565.48;

      let value =
        offset -
        offset *
          (this.hoursCompleted < 0
            ? 0
            : this.hoursCompleted / this.hoursOfMonth);
      value = value > 0 ? value : 0;
      if (circle.style) {
        circle.style.stroke = "#0993E3";
      }
      circle.animate(
        [
          { strokeDashoffset: offset },
          { strokeDashoffset: value + 130 < offset ? value + 130 : offset },
        ],
        {
          duration: 1000,
          fill: "forwards",
        }
      );
    },

    async getOvertimePending() {
      try {
        const params = {
          month: this.month,
          year: this.year,
          user_id: this.userId,
        };
        const { data } = await MainService.getOvertimePending(params);
        if (data.data.length === 0) return;
        this.hoursAccumulated =
          parseInt(data.data[0].overtime) +
          parseInt(data.data[0].overtime_pending);
      } catch (error) {
        console.log("error in getOvertimePending:", error);
      }
    },

    async getTotalPerMonth() {
      try {
        this.loading = true;
        const params = {
          month: this.month,
          year: this.year,
          user_id: this.userId,
        };
        const { data } = await MainService.getTotalPerMonth(params);
        const {
          holidays,
          totalOvertime,
          totalOvertimeApproved,
          totalRoundingHours,
        } = data;
        this.getOvertimePending();
        this.hoursCompleted =
          holidays + totalOvertimeApproved + totalRoundingHours;
        this.hoursToBeCompleted = this.hoursOfMonth - this.hoursCompleted || 0;
        this.hoursAccumulated =
          this.hoursToBeCompleted < 0 ? 0 : this.hoursToBeCompleted;
        this.setProgressPercentaje();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error in getTotalPerMonth:", error);
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Avenir;
}
.square-blue,
.square-skyblue {
  display: flex;
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  min-height: 22px !important;
  border-radius: 5px;
  margin-right: 8px;
}
.square-blue {
  background-color: #0993e3;
}
.square-skyblue {
  background-color: #98d9ffab;
}
.square-blue ~ span,
.square-skyblue ~ span {
  font-size: 16px;
  line-height: 16px;
}
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 2s linear infinite;
  margin-bottom: 10px;
}

.padspace {
  padding: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #98d9ff;
  stroke-width: 2em;
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-linecap: round;
}

#cont {
  height: 200px;
  width: 200px;

  border-radius: 100%;
  position: relative;
}
</style>
