var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{staticClass:"border-0 border-white",attrs:{"scrollable":"","size":"lg","header-bg-variant":"primary","header-text-variant":"light","body-bg-variant":"ligth","body-text-variant":"dark","footer-bg-variant":"ligth","footer-text-variant":"dark","no-close-on-backdrop":""},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center text-white"},[_c('tabler-icon',{staticClass:"mr-1",attrs:{"icon":"UploadIcon","size":"20"}}),_c('h4',{staticClass:"m-0 p-0 text-white"},[_vm._v(" "+_vm._s(_vm.action ? "Show " : _vm.dataJustification.id ? "Edit " : "Upload ")+" medical rest ")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-around align-items-center"},[(_vm.action != 'show')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.insertUpdateJustification}},[_vm._v("Save")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.onclose),callback:function ($$v) {_vm.onclose=$$v},expression:"onclose"}},[_c('b-container',{staticClass:"p-1"},[_c('validation-observer',{ref:"formMedicalRest"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[([17,2,11,26].includes(_vm.currentUser.role_id))?_c('validation-provider',{attrs:{"name":"employee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [([17,2,11,26].includes(_vm.currentUser.role_id))?_c('b-form-group',{attrs:{"label":"Employee"}},[_c('v-select',{class:errors[0] ? 'border-danger rounded' : '',attrs:{"id":"employee","disabled":_vm.action == 'show' ? true : false,"options":_vm.usersByModule,"reduce":function (val) { return val.id; },"label":"value","placeholder":"Select a employee ...","state":errors[0] ? false : null},model:{value:(_vm.medicalRest.employee_id),callback:function ($$v) {_vm.$set(_vm.medicalRest, "employee_id", $$v)},expression:"medicalRest.employee_id"}})],1):_vm._e()]}}],null,false,3240832153)}):_vm._e(),(_vm.action != 'show')?_c('validation-provider',{attrs:{"name":"File","rules":_vm.isUpdateJustification ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File"}},[_c('b-form-file',{ref:"file",class:errors[0] ? 'border-danger rounded' : '',attrs:{"placeholder":"Upload file medical rest","accept":".jpg, .png, .jpeg, .pdf, .doc, .docx","state":errors[0] ? false : null},on:{"change":_vm.fileFill},model:{value:(_vm.fileToUpload),callback:function ($$v) {_vm.fileToUpload=$$v},expression:"fileToUpload"}})],1)]}}],null,false,3587022321)}):_vm._e(),_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:errors[0] || (!_vm.validateDate && _vm.medicalRest.end_date) || _vm.maxDays
                      ? 'border-danger'
                      : '',attrs:{"id":"start_date","first-day-of-week":1,"placeholder":"MM/DD/YYYY","format":'MM/dd/yyyy',"disabled":_vm.action == 'show' ? true : false,"state":errors[0] || (!_vm.validateDate && _vm.medicalRest.end_date) || _vm.maxDays
                      ? false
                      : null},model:{value:(_vm.medicalRest.start_date),callback:function ($$v) {_vm.$set(_vm.medicalRest, "start_date", $$v)},expression:"medicalRest.start_date"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:errors[0] || (!_vm.validateDate && _vm.medicalRest.end_date) || _vm.maxDays
                      ? 'border-danger'
                      : '',attrs:{"id":"end_date","first-day-of-week":1,"min":_vm.minDateRange,"max":_vm.maxDateRange,"placeholder":"MM/DD/YYYY","disabled":_vm.action == 'show' || !_vm.medicalRest.start_date ? true : false,"format":'MM/dd/yyyy',"state":errors[0] || (!_vm.validateDate && _vm.medicalRest.end_date) || _vm.maxDays
                      ? false
                      : null},model:{value:(_vm.medicalRest.end_date),callback:function ($$v) {_vm.$set(_vm.medicalRest, "end_date", $$v)},expression:"medicalRest.end_date"}}),(!_vm.validateDate && _vm.medicalRest.end_date)?_c('small',{staticClass:"text-danger"},[_vm._v("End date cannot be less than start date")]):_vm._e(),(_vm.maxDays)?_c('small',{staticClass:"text-danger"},[_vm._v(" Date range cannot be more than 6 months ")]):_vm._e()],1)]}}])})],1),_c('b-col',{staticClass:"pl-1 d-block justify-content-center align-items-center text-right",attrs:{"cols":"6"}},[_c('div',{staticStyle:{"width":"100%","height":"300px"}},[(_vm.action == 'show')?_c('a',{staticClass:"bg-primary text-white rounded text-decoration-none",staticStyle:{"padding":"5px 10px"},attrs:{"href":_vm.medicalRest.fileImg,"target":"_blank","download":""}},[_vm._v(" View file "),_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_vm._e(),(_vm.isImage | !_vm.medicalRest.fileImg)?_c('img',{staticStyle:{"height":"300px"},attrs:{"src":_vm.medicalRest.fileImg
                    ? _vm.medicalRest.fileImg
                    : 'https://programacion.net/files/article/20160819020822_image-not-found.png',"alt":"Medical rest","width":"100%","height":"100%"}}):(['doc', 'docx'].includes(_vm.medicalRest.extension))?_c('div',{staticClass:"text-primary list-group-item-primary d-flex justify-content-center align-items-center rounded-lg",staticStyle:{"width":"100%","height":"100%"}},[_c('tabler-icon',{attrs:{"icon":"FileTextIcon","size":"50"}}),_c('label',{staticClass:"text-primary d-flex justify-content-center align-content-center mt-2 ml-2 text-uppercase",staticStyle:{"letter-spacing":"5px","font-size":"20px"},attrs:{"for":""}},[_vm._v(_vm._s(_vm.medicalRest.extension))])],1):_c('iframe',{staticStyle:{"height":"300px"},attrs:{"src":_vm.medicalRest.fileImg,"frameborder":"0","width":"100%","height":"100%"}})])])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{staticClass:"text-area-box roboto-class",class:errors[0] ? 'border-danger' : '',attrs:{"id":"medicalRest_motive","name":"text","cols":"30","rows":"2","max-length":"255","placeholder":"What is the reason?","state":errors[0] ? false : null,"disabled":_vm.action == 'show' ? true : false},model:{value:(_vm.medicalRest.motive),callback:function ($$v) {_vm.$set(_vm.medicalRest, "motive", $$v)},expression:"medicalRest.motive"}})],1)]}}])})],1)],1)],1),(_vm.validateDates != [])?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h4',[_vm._v("Validate Hours Date")]),_c('hr'),(_vm.validateDates.length)?[_c('div',{staticClass:"scroll-container"},[_c('b-list-group',[_c('validation-observer',{ref:"formHoursValidate"},_vm._l((_vm.validateDates),function(validateDate,index){return _c('b-list-group-item',{key:validateDate.date},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(_vm._f("myDateGlobal")(validateDate.date)))])]),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":("hour-" + index),"rules":"required|between:0,8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",class:errors[0] ? 'border-danger' : null,attrs:{"disabled":_vm.action == 'show' ? true : false,"type":"number","min":"0","max":"8","max-length":2,"state":errors[0] ? false : null},model:{value:(validateDate.hours),callback:function ($$v) {_vm.$set(validateDate, "hours", $$v)},expression:"validateDate.hours"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validateDate.hours > 8 ? "Max 8" : errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1)}),1)],1)],1)]:[_c('b-card',{staticClass:"shadow-none",style:(!_vm.isDarkSkin
                  ? 'background-color: #d6eaf8'
                  : 'background-color: #3E3E3F')},[_c('b-container',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"text-center",staticStyle:{"color":"#2980b9"}},[_c('tabler-icon',{staticClass:"mr-1 mb-2",attrs:{"icon":"MoodHappyIcon","size":"40"}}),_c('br'),_c('span',{staticStyle:{"font-size":"25px"}},[_vm._v("AMG is happy that you are well !!!")])],1)])],1)]],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }