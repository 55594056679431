<template>
  <div
    style="
      padding: 2rem;
      border-radius: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    "
    :style="
      isDarkSkin
        ? 'background-color: #17171A'
        : 'background-color: white; box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;'
    "
  >
    <div>
      <h4
        class="m-0 text-center font-size"
        style="
          font-family: Avenir;
          font-weight: 900;
          margin-bottom: 10px !important;
        "
        :style="
          !isDarkSkin
            ? 'text-stroke: 2px solid #2F2F31 !important;color: #2F2F31;'
            : 'text-stroke: 2px solid #b8c2cc !important;color: #b8c2cc;'
        "
      >
        CUSTOMER TICKETS
      </h4>
    </div>
    <b-list-group
      v-if="pendingTicketsCustomer.length > 0"
      class="scrollable-list list-item p-0 shadow-none bg-transparent"
      style="max-height: 200px; display: flex; gap: 1rem"
    >
      <b-list-group-item
        v-for="(data, index) in pendingTicketsCustomer"
        :key="index"
        v-b-tooltip.hover="'ANSWER MESSAGES'"
        :style="isDarkSkin ? 'background: #181d24' : ''"
        class="p-0 m-0 list-item pointer border-light rounded-lg"
        style="min-width: 220px"
        @click="openCustomerChat(data)"
      >
        <div class="p-1 d-flex justify-content-around align-items-center">
          <div
            class="text-center d-flex d-md-block justify-content-center align-items-center mr-1 position-relative"
            style="border-radius: 50%"
          >
            <span
              class="text-data"
              style="
                font-family: Avenir;
                background-color: red;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
              "
            >
              {{ data.pendingMessages > 10 ? "+10" : data.pendingMessages }}
            </span>
          </div>
          <div style="min-width: 170px">
            <span>
              <feather-icon icon="UserIcon" /> {{ data.clientLeadName }}</span
            >
            <div class="d-block d-md-flex justify-content-between">
              <small>
                <feather-icon icon="BookmarkIcon" />
                {{ data.code || "NO CODE" }}</small
              >
              <b-badge :variant="getPriorityColor(data.priority)">
                {{ data.priority }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div
      v-if="pendingTicketsCustomer.length == 0"
      class="text-warning text-center"
    >
      <span>
        <tabler-icon icon="TicketIcon" size="40" />
      </span>
      <span>
        <p
          style="margin: 0; font-size: 15px; font-weight: bold; padding: 1rem 0"
        >
          NO TICKET CUSTOMER YET
        </p>
      </span>
    </div>
    <b-button
      block
      variant="primary"
      style="margin: 1rem 0 0 0"
      :to="{
        name: redirectTicket(),
      }"
    >
      VIEW TICKETS
    </b-button>
    <ticket-costumer-chat
      v-if="openchat == true"
      :customer-ticket-chat="customerTicketChat"
      :params="paramsTicket"
      @close="closeCustomerChat()"
      @refreshTable="refreshTable()"
    />
  </div>
</template>

<script>
import MenuService from "@/views/main/menu.service";
import { mapGetters } from "vuex";

import TicketCostumerChat from "@/views/commons/components/customer-tickets/chat-components/TicketCostumerChat.vue";

import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";

export default {
  components: {
    TicketCostumerChat,
  },

  data() {
    return {
      pendingMessages: [],
      openchat: false,
      paramsTicket: {
        ticketCustomerId: null,
      },
      customerTicketChat: {},
      moduleIdToRouteMap: {
        2: "crm-customer-tickets",
        3: "business-customer-tickets",
        4: "administration-customer-tickets",
        5: "debt-solution-customer-tickets",
        6: "credit-experts-customer-tickets",
        7: "boost-credit-customer-tickets",
        8: "tax-research-customer-tickets",
        9: "correspondence-customer-tickets",
        10: "court-info-customer-tickets",
        11: "analyst-department-customer-tickets",
        12: "paragon-customer-tickets",
        14: "bookeeping-customer-tickets",
        15: "social-network-customer-tickets",
        16: "management-customer-tickets",
        17: "rrhh-customer-tickets",
        18: "quality-customer-tickets",
        19: "logistic-customer-tickets",
        20: "connection-customer-tickets",
        21: "credit-experts-customer-tickets",
        22: "customer-service-customer-tickets",
        23: "financial-customer-tickets",
        25: "specialist-digital-customer-tickets",
        26: "sales-customer-tickets",
        27: "creative-customer-tickets",
      },
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      pendingTicketsCustomer: "NotificationStore/G_PENDING_CUSTOMER_TICKETS",
    }),
  },

  created() {
    // this.$store.dispatch('NotificationStore/A_USERS_AVAILABILITY_CUSTOMER_TICKETS');
    this.getPendingCustomerTicket();
  },

  methods: {
    redirectTicket() {
      const { user_id, modul_id, main_module, arrRoles } = this.currentUser;
      if (
        this.$store.getters[
          "NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS"
        ].includes(user_id)
      ) {
        return this.moduleIdToRouteMap[main_module];
      }
      const resultado = arrRoles.find(
        (rol) =>
          (rol.role_id === 6 || rol.role_id === 17) &&
          rol.module_id !== 24 &&
          rol.user_id !== 38
      );
      return this.moduleIdToRouteMap[resultado.module_id];
    },
    async refreshTable() {
      this.showModalCreateCustomerTicket = false;
      this.customerTicketSelected = {};
      this.state_ticket = null;
    },
    closeCustomerChat() {
      this.getPendingCustomerTicket();
      this.openchat = false;
    },

    async openCustomerChat(ticket) {
      this.paramsTicket.ticketCustomerId = ticket.customerId;
      this.addPreloader();
      try {
        const params = {
          client_account: null,
          from: null,
          module_id: null,
          name_text: "",
          order: "DESC",
          orderBy: 10,
          page: 1,
          perPage: 50,
          priority: null,
          program_id: null,
          sortBy: "",
          sortOrder: "ASC",
          state_ticket: null,
          status: null,
          tab: 7,
          to: null,
          user_id: 1,
          ticketChat: ticket.customerId,
          in_out: null,
        };
        const data = await CustomerTicketsService.getCustomerTickets(params);
        this.customerTicketChat = data.data.data[0];
        this.openchat = true;
        this.removePreloader();
        if (data.status === 200) {
          this.refreshTable();
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
      return [];
    },

    async getPendingCustomerTicket() {
      const response = await MenuService.getPendingCustomerTicket({
        user_id: this.currentUser.user_id,
      });
      this.pendingMessages = response.data;
    },

    getPriorityColor(priority) {
      const arrColors = {
        LOW: "light-primary",
        MEDIUM: "light-warning",
        HIGH: "light-danger",
      };

      return arrColors[priority];
    },
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/avenir");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.text-data {
  font-size: 1rem;
  font-weight: bolder;
  color: #ffffff;
  font-family: Montserrat;
}

.scrollable-list {
  max-height: 350px;
  overflow-y: auto;
  padding: 10px;
}

.list-item {
  border: none;
  background: transparent;
  /* border-radius: 10px; */
  padding: 30px;
  /* box-shadow: 0px 0px 8.839050293px 0px #00000040; */
}
</style>
