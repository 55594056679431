<template>
  <div class="my-table">
    <b-table
      slot="table"
      ref="refClientsList"
      small
      no-provider-filtering
      :items="miItems"
      :fields="myFields"
      primary-key="id"
      table-class="text-nowrap"
      responsive="sm"
      show-empty
      sticky-header="70vh"
      :busy.sync="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="paginate.currentPage"
      :per-page="paginate.perPage"
      :filter="searchInput"
      style="border-radius: 7px"
      striped
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(name_date)="data">
        {{ data.item.date_mark | nameDate }}
        {{ data.item.date_mark | numDate }}

        <br />
        <b-badge
          v-if="data.item.tolerance == 'late'"
          variant="danger"
          class="mt-1"
          style="border-radius: 8px; background: #ff6d6d"
        >
          LATE
        </b-badge>
      </template>
      <template #cell(sessionusers)="data">
        {{ data.item.sessionusers }}
      </template>

      <!-- <template #cell(date_mark)="data">
      {{ data.value | myGlobal }}
    </template> -->
      <template #cell(session_started)="data">
        <span style="padding: 8px" class="text-white font-weight-bolder">{{
          data.value
        }}</span>
      </template>
      <template #cell(first_mark)="data">
        <span
          class="state-style"
          :class="
            typeAssistance(data.item.type)
              ? ''
              : !data.item.state
              ? 'bg-secondary'
              : !data.value
              ? 'bg-danger'
              : 'bg-primary'
          "
          :style="
            typeAssistance(data.item.type) ? optionsState[data.item.type] : ''
          "
        >
          <template v-if="typeAssistance(data.item.type)">{{
            data.item.type
          }}</template>
          <label
            v-else
            class="text-white text-montserrat"
            style="font-weight: 600; line-height: 18px; font-size: 16px"
            >{{ data.value || data.item.type }}</label
          ></span
        >
      </template>
      <template #cell(second_mark)="data">
        <span
          class="state-style"
          :class="
            typeAssistance(data.item.type)
              ? ''
              : !data.item.state
              ? 'bg-secondary'
              : !data.value
              ? 'bg-danger'
              : 'bg-primary'
          "
          :style="
            typeAssistance(data.item.type) ? optionsState[data.item.type] : ''
          "
        >
          <template v-if="typeAssistance(data.item.type)">
            {{ data.item.type }}</template
          >
          <label
            v-else
            class="text-white text-montserrat"
            style="font-weight: 600; line-height: 18px; font-size: 16px"
            >{{ data.value || data.item.type }}</label
          ></span
        >
      </template>
      <template #cell(third_mark)="data">
        <span
          style="padding: 8px"
          class="state-style"
          :class="
            typeAssistance(data.item.type)
              ? ''
              : !data.item.state
              ? 'bg-secondary'
              : !data.value
              ? 'bg-danger'
              : 'bg-primary'
          "
          :style="
            typeAssistance(data.item.type) ? optionsState[data.item.type] : ''
          "
        >
          <template v-if="typeAssistance(data.item.type)">{{
            data.item.type
          }}</template>
          <label
            v-else
            class="text-white text-montserrat"
            style="font-weight: 600; line-height: 18px; font-size: 16px"
            >{{ data.value || data.item.type }}</label
          ></span
        >
      </template>
      <template #cell(fourth_mark)="data">
        <span
          class="state-style"
          :class="
            typeAssistance(data.item.type)
              ? ''
              : !data.item.state
              ? 'bg-secondary'
              : !data.value
              ? 'bg-danger'
              : 'bg-primary'
          "
          :style="
            typeAssistance(data.item.type) ? optionsState[data.item.type] : ''
          "
        >
          <template v-if="typeAssistance(data.item.type)">{{
            data.item.type
          }}</template>
          <label
            v-else
            class="text-white text-montserrat"
            style="font-weight: 600; line-height: 18px; font-size: 16px"
            >{{ data.value || data.item.type }}</label
          ></span
        >
      </template>
      <template #cell(last_mark)="data">
        <span
          class="state-style"
          :class="
            typeAssistance(data.item.type)
              ? ''
              : !data.item.state
              ? 'bg-secondary'
              : !data.value
              ? 'bg-danger'
              : 'bg-primary'
          "
          :style="
            typeAssistance(data.item.type) ? optionsState[data.item.type] : ''
          "
        >
          <template v-if="typeAssistance(data.item.type)">{{
            data.item.type
          }}</template>
          <label
            v-else
            class="text-white text-montserrat"
            style="font-weight: 600; line-height: 18px; font-size: 16px"
            >{{ data.value || data.item.type }}</label
          ></span
        >
      </template>

      <template #cell(working_hours)="data">
        <span class="font-weight-bolder">
          {{ data.item.working_hours | countHoursMinute }}
        </span>
      </template>
      <template #cell(working_hours_rounded)="data">
        <span class="font-weight-bolder">{{
          `${data.item.working_hours_rounded} h`
        }}</span>
      </template>
      <template #cell(working_mark_hours_valid)="data">
        <span class="font-weight-bolder">{{
          `${data.item.working_mark_hours_valid} h`
        }}</span>
      </template>

      <template #cell(overtime_approved)="data">
        <span class="font-weight-bolder">
          {{ `${data.item.overtime_approved} h` }}
        </span>
      </template>
      <template #cell(total_hours_validate)="data">
        <span class="font-weight-bolder">
          {{ `${data.item.total_hours_validate} h` }}
        </span>
      </template>
      <template #cell(holidays)="data">
        <span class="font-weight-bolder">
          {{ `${data.item.holidays} h` }}
        </span>
      </template>
      <template #custom-foot>
        <b-tr>
          <b-td colspan="7" />
          <!-- total -->
          <b-td class="d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-center">
              <span
                class="text-montserrat py-1 my-2"
                style="font-weight: 400; font-size: 19px; line-height: 14px"
                >Total (week)</span
              >
            </div>
          </b-td>
          <!-- Rounded Hours -->
          <b-td class="font-weight-bolder">
            <div class="d-flex justify-content-center">
              <label
                class="d-flex justify-content-center align-items-center total-tag my-2"
                :style="
                  isDarkSkin
                    ? 'background: #292C33'
                    : 'background: #F6F6FE; color: #18173D !important;'
                "
                >{{ roundedHours }}h</label
              >
            </div>
          </b-td>
          <!-- Valid Hours Worked -->
          <b-td class="font-weight-bolder">
            <div class="d-flex justify-content-center">
              <label
                class="d-flex justify-content-center align-items-center total-tag my-2"
                :style="
                  isDarkSkin
                    ? 'background: #292C33'
                    : 'background: #F6F6FE; color: #18173D !important;'
                "
                >{{ validHoursWorked }}h</label
              >
            </div>
          </b-td>
          <!-- Approved justified hours -->
          <b-td class="font-weight-bolder">
            <div class="d-flex justify-content-center">
              <label
                class="d-flex justify-content-center align-items-center total-tag my-2"
                :style="
                  isDarkSkin
                    ? 'background: #292C33'
                    : 'background: #F6F6FE; color: #18173D !important;'
                "
                >{{ overtimeApproved }}h</label
              >
            </div>
          </b-td>
          <!-- Holidays -->
          <b-td class="font-weight-bolder">
            <div class="d-flex justify-content-center">
              <label
                class="d-flex justify-content-center align-items-center total-tag my-2"
                :style="
                  isDarkSkin
                    ? 'background: #292C33'
                    : 'background: #F6F6FE; color: #18173D !important;'
                "
                >{{ holidays }}h</label
              >
            </div>
          </b-td>
          <!-- Total Hours -->
          <b-td class="font-weight-bolder">
            <div class="d-flex justify-content-center">
              <label
                class="d-flex justify-content-center align-items-center total-tag my-2"
                :style="
                  isDarkSkin
                    ? 'background: #292C33'
                    : 'background: #F6F6FE; color: #18173D !important;'
                "
                >{{ totalHours }}h</label
              >
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import serviceDashboard from "@/views/assistance/views/dashboard/services/dashboard.services";
import myFields from "@/views/assistance/views/dashboard/data/fields.dashboardUser";

export default {
  filters: {
    nameDate(value) {
      return value
        ? `${moment(value).format("dddd").substring(0, 3).toUpperCase()},`
        : "-";
    },
    numDate(value) {
      const day = moment(value).format("DD");
      let formattedDay;

      switch (day) {
        case "01":
          formattedDay = `${day}st`;
          break;
        case "02":
          formattedDay = `${day}nd`;
          break;
        case "03":
          formattedDay = `${day}rd`;
          break;
        default:
          formattedDay = `${day}th`;
          break;
      }

      return value ? formattedDay : "-";
    },
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}), // Ensure it defaults to an empty object
    },
    typeDashboard: {
      type: String,
      require: false,
      default: "",
    },
  },
  data() {
    return {
      myFields,
      items: [],
      // roundingHoursPerMonth: 0,
      overtimePerMonth: 0,
      overtimeApprovedPerMonth: 0,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      searchInput: "",
      optionsState: {
        BIRTHAY:
          "border: 1px solid #FFB826 !important;color: #FFB826 !important",
        UNMARKED: "background-color:#4D4D54;font-size:12px !important;",
        FAULTS:
          "background-color:#4D4D54;color:white !important;font-size:12px !important;",
        "MEDICAL REST": "background-color:#85C1E9;color:white !important;",
        VACATIONS:
          "background-color:#F7DC6F;color:white;font-size:12px !important;",
        HOLIDAYS:
          "background-color:#9370DB;color:#FFD700; !important;font-size:12px !important; font-weight: 500 !important;",
        REMOTE:
          "background-color:#9370DB;color:#FFD700; !important;font-size:12px !important; font-weight: 500 !important;",
        "NOT REGISTER":
          "color:#4D4D54 !important;border:1px solid #4D4D54 !important;font-size:11px;",
        "NO REGISTERED":
          "color:#4D4D54 !important;border:1px solid #4D4D54 !important;font-size:11px;",
        ABSENCE: "background-color:#4D4D54;font-size:12px !important;",
        INCONSISTENCY: "background-color:#FFB826;font-size:12px !important;",
        ATTENDANCE: "background-color:#2ECC71;font-size:12px !important;",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    roundedHours() {
      return this.items
        .map((item) => parseInt(item.working_hours_rounded))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    overtime() {
      return this.items
        .map((item) => parseInt(item.overtime))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    overtimeApproved() {
      return this.items
        .map((item) => parseInt(item.overtime_approved))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    validHoursWorked() {
      return this.items
        .map((item) => parseInt(item.working_mark_hours_valid))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    holidays() {
      return this.items
        .map((item) => parseInt(item.holidays))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    totalHours() {
      return this.items
        .map((item) => parseInt(item.total_hours_validate))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    miItems() {
      return this.items.map((item) => ({
        ...item,
        date_mark: moment(item.date_mark).format("YYYY-MM-DD"),
        day: moment(item.date_mark).format("DD"),
        state: item.type.toLowerCase(),
      }));
    },
  },
  async mounted() {
    await this.getData();
  },

  methods: {
    getFormattedDay(date) {
      const day = date.getDate();
      let formattedDay;

      if (day >= 11 && day <= 13) {
        formattedDay = `${day}th`;
      } else {
        switch (day % 10) {
          case 1:
            formattedDay = `${day}st`;
            break;
          case 2:
            formattedDay = `${day}nd`;
            break;
          case 3:
            formattedDay = `${day}rd`;
            break;
          default:
            formattedDay = `${day}th`;
            break;
        }
      }

      return formattedDay;
    },

    async getData() {
      try {
        this.isBusy = true;
        const params = {
          user_id: this.filters.userId,
          filterDay: this.filters.day,
          filterWeekStart: this.filters.weekStart,
          filterWeekEnd: this.filters.weekEnd,
          filterMonth: parseInt(this.filters.month),
          filterYear: parseInt(this.filters.year),
        };
        const { data } = await serviceDashboard.getDetailsAttendance(params);
        this.items = data.data;
        this.isBusy = false;
        if (this.typeDashboard == "dashboardUser") {
          this.$emit("hoursAcumulated", this.hoursAccumulated());
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.isBusy = false;
      }
    },
    hoursAccumulated() {
      const hoursAccumulated = this.items.reduce(
        (a, b) => parseInt(a) + parseInt(b.total_hours_validate),
        0
      );
      return hoursAccumulated;
    },
    typeAssistance(type) {
      return [
        "HOLIDAYS",
        "MEDICAL REST",
        "VACATIONS",
        "NOT REGISTER",
        "NO REGISTERED",
        "FAULTS",
        "BIRTHAY",
      ].includes(type);
    },
  },
};
</script>

<style>
.text-montserrat {
  font-family: "Montserrat";
}

.state-style {
  padding: 8px;
  border-radius: 7px !important;
  height: 37px;
  width: 106px;
}

.total-tag {
  width: 124px;
  height: 44px;
  background: #292c33;
  border-radius: 8px;
  font-family: Montserrat;
  font-size: 20px;
  color: #a09fa5 !important;
}

.my-table {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.my-table thead th {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

@media (max-width: 768px) {
  /* Add your mobile-specific styles here */
  .my-table {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 10px;
  }
  /* You can adjust other styles as needed for mobile devices */
}
</style>
