<template>
  <div style="height:100%">
    <b-card
      tag="article"
      style="margin:0; height:100%"
    >

      <b-card-header
        style="padding: 1rem 0;
    display: flex;
    gap: 0.5rem;"
      >
        <div
          class="text-dark"
          style="font-size: 22px; max-width:140px; font-family: Avenir; font-weight: 900; line-height: 23.10px; word-wrap: break-word "
        >
          Organigram
        </div>
        <div>
          AMGer, Explore your departmental structure here.
        </div>
      </b-card-header>

      <b-button
        href="#"
        block
        variant="outline-secondary"
        class="py-1"
        :style="containerStyle"
        @click="toDepartamentalOrganigram()"
      >
        <div class="center-content">
          <b-row>
            <b-img
              src="~@/assets/images/icons/organigrama1.svg"
              width="80"
              height="80"
              alt="Circle image"
            />
          </b-row>
          <b-row>
            <strong
              style="font-size: 17px; "
              class="
              text-dark"
            >Departmental</strong>
          </b-row>
        </div>
      </b-button>

      <b-button
        href="#"
        block
        variant="outline-secondary"
        class="py-1 mt-1"
        :style="containerStyle"
        @click="toGeneralOrganigram()"
      >
        <div class="center-content">
          <b-row>
            <b-img
              src="~@/assets/images/icons/organigrama1.svg"
              width="80"
              height="80"
              alt="Circle image"
            />
          </b-row>
          <b-row>
            <strong
              style="font-size: 17px; "
              class="text-dark"
            >General</strong>
          </b-row>
        </div>
      </b-button>
    </b-card>
    <modal-menu-organigram-dep
      v-if="modal"
      :operation="operation"
      @hidden="modal = false"
      @closeModal="modal = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import ModalMenuOrganigramDep from '@/views/main/dashboard-components/ModalMenuOrganigramDep.vue';

export default {
  components: {
    ModalMenuOrganigramDep,
  },
  data() {
    return {
      modal: false,
      operation: null,
    };
  },
  computed: {
    todayMonth() {
      return moment().format('MMMM');
    },
    lionColor() {
      if (this.isDarkSkin) {
        return 'white';
      }
      return 'black';
    },
    containerStyle() {
      if (this.isDarkSkin) {
        return {
          backgroundColor: '#ffffff40',
          borderRadius: '15px',
          border: '1px solid #ffffff40 !important',
        };
      }
      return {
        border: '1px solid #ccc !important',
        borderRadius: '15px',
      };
    },
  },
  methods: {
    toDepartamentalOrganigram() {
      this.operation = 1;
      this.modal = true;
    },
    toGeneralOrganigram() {
      this.operation = 2;
      this.modal = true;
    },
  },
};
</script>
<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
</style>
