export default [
  {
    key: 'name_date',
    label: 'Day',
    tdClass: 'text-center pt-1 pb-1',
    thClass: 'text-center pt-2 pb-2 border-top-left',

  },
  // {
  //   key: 'date_mark',
  //   label: 'Date',
  //   tdClass: 'text-center pt-1 pb-1',
  //   thClass: 'text-center pt-2 pb-2',

  // },
  {
    key: 'sessionusers',
    label: 'Start Login',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },
  {
    key: 'first_mark',
    label: 'Start Hour',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',
  },
  {
    key: 'second_mark',
    label: 'Start Break',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',
  },
  {
    key: 'third_mark',
    label: 'End Break',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',
  },
  {
    key: 'fourth_mark',
    label: 'Fourth Hour',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },
  {
    key: 'last_mark',
    label: 'End Hour',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },
  {
    key: 'working_hours',
    label: 'Total',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },

  {
    key: 'working_hours_rounded',
    label: 'Rounded Hours',
    tdClass: 'pt-2 pb-2 text-center',
    thClass: 'text-center pt-2 pb-2',

  },
  {
    key: 'working_mark_hours_valid',
    label: 'Valid Hours Worked',
    tdClass: 'pt-2 pb-2 text-center',
    thClass: 'text-center pt-2 pb-2',

  },

  {
    key: 'overtime_approved',
    label: 'Approved justified hours',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },
  {
    key: 'holidays',
    label: 'Holidays',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2',

  },

  {
    key: 'total_hours_validate',
    label: 'Total Hours',
    tdClass: 'text-center pt-2 pb-2',
    thClass: 'text-center pt-2 pb-2 border-top-right',

  },
];
