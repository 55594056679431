/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { ca } from 'date-fns/locale';
import { amgApi } from '@/service/axios';

class Dashboard {
  async getOvertimeAttendance(body) {
    try {
      return await amgApi.post('/attendance/get-overtime-attendance', body);
    } catch (error) {
      console.log('Something went wrong on getOvertime:', error);
      throw error;
    }
  }

  async getDetailsAttendance(body) {
    try {
      return await amgApi.post('/attendance/get-details-attendance', body);
    } catch (error) {
      console.log('Something went wrong on getDetails:', error);
      throw error;
    }
  }

  async getIndicatorsAttendance(body) {
    try {
      return await amgApi.post('/attendance/get-indicators-attendance', body);
    } catch (error) {
      console.log('Something went wrong on getIndicators:', error);
      throw error;
    }
  }

  async getRolByUser(body) {
    try {
      return await amgApi.post('/attendance/get-rol-by-user', body);
    } catch (error) {
      console.log('Something went wrong on getIndicators:', error);
      throw error;
    }
  }

  async getBirthdays(body) {
    try {
      return await amgApi.post('/attendance/get-birthdays', body);
    } catch (error) {
      console.log('Something went wrong on getIndicators:', error);
      throw error;
    }
  }

  async getHolidays(body) {
    try {
      return await amgApi.post('/attendance/get-holidays', body);
    } catch (error) {
      console.log('Something went wrong on getIndicators:', error);
      throw error;
    }
  }

  async getMotivationalPhrases(body) {
    try {
      return await amgApi.post('/attendance/get-motivational-phrases', body);
    } catch (error) {
      console.log('Something went wrong on getIndicators:', error);
      throw error;
    }
  }

  async getListEmployeesAbsence(body) {
    try {
      return await amgApi.post('/attendance/get-list-employees-absence', body);
    } catch (error) {
      console.log('Something went wrong on employees absence:', error);
      throw error;
    }
  }

  async getListEmployeesInconsistencies(body) {
    try {
      return await amgApi.post(
        '/attendance/get-list-employees-inconsistencies',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on employees inconsistencies:', error);
      throw error;
    }
  }

  async getListEmployeesAttendances(body) {
    try {
      return await amgApi.post(
        '/attendance/get-list-employees-attendances',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on employees attendances:', error);
      throw error;
    }
  }

  async getListEmployeesBirthday(body) {
    try {
      return await amgApi.post('/attendance/get-list-employees-birthday', body);
    } catch (error) {
      console.log('Something went wrong on employees birthday:', error);
      throw error;
    }
  }

  async getListEmployeesMedicalRest(body) {
    try {
      return await amgApi.post(
        '/attendance/get-list-employees-medical-rest',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on employees medical rest:', error);
      throw error;
    }
  }

  async insertJustification(body) {
    try {
      return await amgApi.post('/attendance/insert-justification', body);
    } catch (error) {
      console.log('Something went wrong on insert justification', error);
      throw error;
    }
  }

  async updateJustification(body) {
    try {
      return await amgApi.post('/attendance/update-justification', body);
    } catch (error) {
      console.log('Something went wrong on update justification', error);
      throw error;
    }
  }

  async getJustification(body) {
    try {
      return await amgApi.post('/attendance/get-justifications', body);
    } catch (error) {
      console.log('Something went wrong on get justifications', error);
    }
  }

  async getTrackingJustification(body) {
    try {
      return await amgApi.post('/attendance/get-tracking-justification', body);
    } catch (error) {
      console.log('Something went wrong on get tracking justification', error);
    }
  }

  async getListEmployeeOvertime(body) {
    try {
      return await amgApi.post('/attendance/get-employee-overtime', body);
    } catch (error) {
      console.log('Something went wrong on employees overtime:', error);
      throw error;
    }
  }

  async getListApprovedOvertime(body) {
    try {
      return await amgApi.post('/attendance/get-approved-overtime', body);
    } catch (error) {
      console.log('Something went wrong on employees overtime:', error);
      throw error;
    }
  }

  async getListPendingApprovedOvertimeRRHhManagement(body) {
    try {
      return await amgApi.post(
        '/attendance/get-approved-overtime-rrhh-management',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on employees overtime:', error);
      throw error;
    }
  }

  async insertApprovedOvertime(body) {
    try {
      return await amgApi.post('/attendance/insert-approved-overtime', body);
    } catch (error) {
      console.log('Something went wrong on employees overtime:', error);
      throw error;
    }
  }

  async approveDisapproveRRhhManagement(body) {
    try {
      return await amgApi.post(
        '/attendance/approve-disapprove-rrhh-management',
        body,
      );
    } catch (error) {
      console.log(
        'Something went wrong on employees overtime rrhh and management:',
        error,
      );
      throw error;
    }
  }

  async deletedJustification(body) {
    try {
      return await amgApi.post('/attendance/deleted-justification', body);
    } catch (error) {
      console.log('Something went wrong on deleted justification:', error);
      throw error;
    }
  }

  async addHour(body) {
    try {
      return await amgApi.post('/attendance/add-hour', body);
    } catch (error) {
      console.log('Something went wrong on add hour:', error);
      throw error;
    }
  }

  async generateAttendanceReportExcel(body) {
    try {
      const { data } = await amgApi.post(
        '/attendance/generate-attendance-report-excel',
        body,
        { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.log('Something went wrong on add hour:', error);
      throw error;
    }
  }

  async generateGeneralAttendanceReportExcel(body) {
    try {
      const { data } = await amgApi.post(
        '/attendance/generate-general-attendance-report-excel',
        body,
        { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.log(
        'Something went wrong on generate general attendance report excel:',
        error,
      );
      throw error;
    }
  }

  async getEmployeeByModule(body) {
    try {
      const { data } = await amgApi.post(
        '/attendance/get-employee-by-module',
        body,
      );
      return data;
    } catch (error) {
      console.log('Something went wrong on get employee by module:', error);
      throw error;
    }
  }

  async getTotalPerMonth(body) {
    try {
      return await amgApi.post('/attendance/get-total-per-month', body);
    } catch (error) {
      console.log('Something went wrong on get total hours per month:', error);
      throw error;
    }
  }

  async setAppPassowrd(body) {
    try {
      return await amgApi.post('/attendance/set-password-app', body);
    } catch (error) {
      console.log('Something went wrong on set app password:', error);
      throw error;
    }
  }

  async getDeviceTracking(body) {
    try {
      return await amgApi.get('/attendance/get-device-tracking', body);
    } catch (error) {
      console.log('Something went wrong on get device tracking:', error);
      throw error;
    }
  }

  async insertMarkEmployee(body) {
    try {
      return await amgApi.post(
        '/attendance/insert-mark-attendance-to-employee',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on insert mark to employee:', error);
      throw error;
    }
  }

  //  get-employees-by-id
  async getEmployeesById(body) {
    try {
      return await amgApi.post('/attendance/get-employees-by-id', body);
    } catch (error) {
      console.log('Something went wrong on get employees by id:', error);
      throw error;
    }
  }

  async listPayrollPaymentEmployee(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/list-payroll-payment-employee',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on get-payroll-payment-employee:',
        error,
      );
    }
  }

  async getPdfPayrollPayment(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/generate-pdf-payroll-payment',
        body,
        { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on generate-pdf-payroll-payment',
        error,
      );
    }
  }

  async editTotalHoursWorked(body) {
    try {
      const data = await amgApi.post(
        '/attendance/edit-total-hours-worked',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on edit-total-hours-worked',
        error,
      );
    }
  }

  async getLatenessOfEmployees(body) {
    try {
      const data = await amgApi.post(
        '/attendance/get-lateness-of-employees', body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on getLatenessOfEmployees',
        error,
      );
    }
  }

  async getMarksTracking(body) {
    try {
      const data = await amgApi.post(
        '/attendance/get-marks-tracking', body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on getLatenessOfEmployees',
        error,
      );
    }
  }
}
export default new Dashboard();
