<template>
  <div style="height:100%">
    <b-card
      tag="article"
      style="margin:0; height:100%"
    >
      <b-card-header
        style="display:flex; justify-content: center; padding:1rem 0;"
      >

        <div
          class="row"
          style="width:100%"
        >
          <div class="col-md-7 p-0">
            <div
              class="text-dark"
              style="font-size: 22px; max-width:140px; font-family: Avenir; font-weight: 900; line-height: 23.10px; word-wrap: break-word"
            >
              Staff Claims
            </div>
            <p>
              AMGer, check the status of your claims in real time.
            </p>
          </div>
          <div class="col p-0">
            <b-button
              href="#"
              variant="primary"
              class="ml-1"
              style="float: right;"
              @click="redirectToClaims(userParentModules[0].module_route)"
            >
              <strong>View All</strong>
            </b-button>
          </div>
        </div>

      </b-card-header>

      <div
        class="p-2 d-flex align-items-center"
        style="border-radius: 10px; background-color: #FFD2D2; color:black;"
      >
        <b-img
          src="~@/assets/images/icons/rechazado.svg"
          width="40"
          height="40"
          alt="Circle image"
        />
        <strong class="ml-1">Rejected</strong>
        <strong class="ml-auto">({{ rejectedCounter }} request(s))</strong>
      </div>

      <div
        class="p-2 mt-1 d-flex align-items-center"
        style="border-radius: 10px; background-color: #DEFFD2; color:black;"
      >
        <b-img
          src="~@/assets/images/icons/aprobado.svg"
          width="40"
          height="40"
          alt="Circle image"
        />
        <strong class="ml-1">Resolved</strong>
        <strong class="ml-auto">({{ resolvedCounter }} request(s))</strong>
      </div>

      <div
        class="p-2 mt-1 d-flex align-items-center"
        style="border-radius: 10px; background-color: #FFEAD2; color:black;"
      >
        <b-img
          src="~@/assets/images/icons/expired.svg"
          width="40"
          height="40"
          alt="Circle image"
        />
        <strong class="ml-1">Pending</strong>
        <strong class="ml-auto">({{ pendingCounter }} request(s))</strong>
      </div>

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Service from '@/views/main/menu.service';

export default {
  data() {
    return {
      rejectedCounter: 0,
      resolvedCounter: 0,
      pendingCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    userParentModules() {
      if (this.currentUser) {
        if (Array.isArray(this.currentUser.arrRoles)) {
          return this.currentUser.arrRoles.filter(
            item => item.parent_id == null && item.module_id !== 6,
          );
        }
        const modules = JSON.parse(this.currentUser.arrRoles).filter(
          item => item.parent_id == null && item.module_id !== 6,
        );
        return modules;
      }
      return [];
    },
  },
  mounted() {
    this.getClaimsCounters();
  },
  methods: {
    async getClaimsCounters() {
      try {
        this.addPreloader();
        const obj = {
          id_user: this.currentUser.user_id,
        };
        const res = await Service.getClaimsCounters(obj);
        if (res.status === 200) {
          this.rejectedCounter = res.data.rejected;
          this.resolvedCounter = res.data.resolved;
          this.pendingCounter = res.data.pending;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    redirectToClaims(route) {
      if (route === 'recovery') route = 'cedigital/customerservice';
      if (route === 'cedigital') route = 'cedigital/customerservice';
      const toClaims = `${route}/employee-claims/`;
      window.location.href = toClaims;
    },
  },
};
</script>
