<template>
  <div>

    <!-- List medical rest -->
    <b-modal
      v-model="oncontrol"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      no-close-on-backdrop
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      class="border-0 border-white"
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        <div class="w-100 d-flex justify-content-start align-items-center">
          <tabler-icon
            v-if="!valueFilterApproved"
            icon="AmbulanceIcon"
            class="mr-1"
            size="20"
          />
          <h4 class="font-weight-bolder p-0 m-0 text-white">
            {{
              valueFilterApproved ?
                'Justifications' :
                (showAllEmployees
                  ? "Medical Rests"
                  : `Medical rest of: ${employee.name}`)
            }}
          </h4>
        </div>
      </template>
      <b-container
        fluid
        class="p-1"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-if="!showAllEmployees"
              variant="primary"
              @click="uploadMedicalRest"
            >
              Upload medical rest
              <tabler-icon
                icon="UploadIcon"
                size="20"
                class="ml-1"
              />
            </b-button>
          </b-col>
        </b-row>
        <!-- list medical rest -->
        <b-row class="mt-2">
          <b-col cols="12">
            <filter-slot
              :filter="filteredFilters"
              :filter-principal="filterPrincipal"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              :send-multiple-sms="false"
              :change-columns-by-client="true"
              @reload="refreshTable()"
            >
              <b-table
                slot="table"
                ref="refMedicalRestList"
                no-provider-filtering
                :items="getMedicalRest"
                :fields="filteredFields"
                primary-key="id"
                table-class="text-nowrap"
                show-empty
                sticky-header="50vh"
                :busy.sync="isBusy"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="paginate.currentPage"
                :per-page="paginate.perPage"
                :filter="searchInput"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #cell(image)="data">
                  <template v-if="data.value">
                    <img
                      v-if="
                        ['png', 'jpg', 'jpeg'].includes(data.item.extension)
                      "
                      :src="data.value"
                      alt=""
                      width="25px"
                      height="25px"
                      class="cursor-pointer rounded border border-primary"
                      @click="showModalImage(data.value, data.item.extension)"
                    >
                    <tabler-icon
                      v-else-if="data.item.extension == 'pdf'"
                      icon="FileIcon"
                      size="20"
                      class="cursor-pointer text-danger"
                      @click="showModalImage(data.value, data.item.extension)"
                    />
                    <a
                      v-else-if="['doc', 'docx'].includes(data.item.extension)"
                      :href="data.value"
                      target="_blank"
                      download
                    >
                      <tabler-icon
                        icon="FileTextIcon"
                        size="20"
                        class="cursor-pointer text-primary"
                      />
                    </a>
                  </template>
                </template>
                <template #cell(start_date)="data">
                  {{ data.value | myGlobal }}
                </template>
                <template #cell(employee_name)="data">
                  <span>{{ formatTitleCase(data.item.employee_name) }}</span>
                </template>
                <template #cell(end_date)="data">
                  {{ data.value | myGlobal }}
                </template>
                <template #cell(hours_of_work)="data">
                  <span
                    class="badge badge-primary"
                    style="font-size: 15px"
                  >{{
                    data.value
                  }}</span>
                </template>
                <template #cell(observation)="data">
                  {{
                    data.value.length > 50
                      ? data.value.substring(0, 50) + "..."
                      : data.value
                  }}
                  <tabler-icon
                    v-if="data.value.length > 50"
                    class="text-info cursor-pointer ml-2"
                    size="20"
                    icon="EyeIcon"
                    @click="(showMotive = true), (motive = data.value)"
                  />
                </template>
                <template #cell(name_justification)="data">
                  <span>
                    {{ data.item.name_justification }}
                  </span>
                </template>
                <template #cell(process)="data">
                  <b-badge :variant="classProcess(data.item.process_id)">{{
                    data.item.name_process
                  }}</b-badge>
                </template>
                <template #cell(created_at)="data">
                  {{ data.value | myGlobalDay }}
                </template>
                <template #cell(tracking)="data">
                  <tabler-icon
                    icon="AlignLeftIcon"
                    size="20"
                    class="cursor-pointer text-primary"
                    @click="showModalTracking(data.item.id)"
                  />
                </template>
                <template #cell(actions)="data">
                  <feather-icon
                    v-if="
                      data.item.process_id == 1 ||
                        (data.item.process_id == 2 && currentUser.role_id == 2)
                    "
                    icon="Edit2Icon"
                    size="20"
                    class="cursor-pointer text-primary mr-1"
                    @click="editMedicalRest(data.item)"
                  />
                  <feather-icon
                    v-if="
                      data.item.process_id == 1 ||
                        (data.item.process_id == 2 && currentUser.role_id == 2)
                    "
                    icon="Trash2Icon"
                    size="20"
                    class="cursor-pointer text-danger"
                    @click="deletedJustification(data.item.id)"
                  />
                </template>
                <template #custom-foot>
                  <b-tr class="bg-dark">
                    <b-th />
                    <b-th />
                    <b-th />
                    <b-th />
                    <b-th>
                      <div class="footer-span">
                        <span class="font-small-3">{{ hoursJustified }} hour(s)</span>
                      </div>
                    </b-th>
                    <b-th />
                    <b-th />
                    <b-th />
                    <b-th />
                    <b-th v-if="valueFilterApproved" />
                  </b-tr>
                </template>
              </b-table>
            </filter-slot>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- modal motive medical rest -->
    <template>
      <div>
        <b-modal
          v-model="showMotive"
          size="lg"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="ligth"
          body-text-variant="dark"
          footer-bg-variant="ligth"
          footer-text-variant="dark"
          no-close-on-backdrop
          centered
          class="border-0 border-white"
        >
          <template #modal-header>
            <div class="w-100 d-flex justify-content-start align-items-center">
              <tabler-icon
                icon="EyeIcon"
                size="20"
                class="mr-2 text-white"
              />
              <h4 class="font-weight-bolder p-0 m-0 text-white">
                Motive
              </h4>
            </div>
          </template>
          <b-container
            fluid
            class="p-1"
          >
            <p>{{ motive }}</p>
          </b-container>
          <template #modal-footer>
            <b-button
              class="cursor-pointer"
              variant="danger"
              @click="(showMotive = false), (motive = '')"
            >
              <tabler-icon
                icon="EyeOffIcon"
                size="20"
              />
              Close</b-button>
          </template>
        </b-modal>
      </div>
    </template>

    <!-- modal tracking -->
    <modal-tracking-medical-rest
      v-if="showTracking"
      :id-justification="idJustification"
      @closeModal="closeTrackingMedicalRest"
    />

    <!--modal upload medical rest-->
    <modal-upload-medical-rest
      v-if="showUpload"
      :employee_id="employee.id"
      :data-justification="dataJustification"
      @closeModal="closeUploadMedicalRest"
      @refreshData="refreshTable"
    />

    <!-- modal image medical rest -->
    <modal-image-medical-rest
      v-if="showImage"
      :url-img="urlImage"
      :extension="extension"
      @closeModal="closeModalImage"
    />
  </div>
</template>
<script>
import ModalUploadMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalUploadMedicalRest.vue';
import ModalTrackingMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalTrackingMedicalRest.vue';
import ModalImageMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalImage.vue';
import Fields from '@/views/assistance/views/dashboard/data/field.medicalRest';
import Filters from '@/views/assistance/views/dashboard/data/filters.medicalRest';
import serviceDashboard from '@/views/assistance/views/dashboard/services/dashboard.services';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalUploadMedicalRest,
    ModalTrackingMedicalRest,
    ModalImageMedicalRest,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({
        id: null,
        name: null,
      }),
    },
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
    valueFilterModule: null,
    valueFilterEmployee: null,
    valueFilterType: null,
    valueFilterApproved: null,
  },
  data() {
    return {
      idJustification: null,
      filters: Filters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        label: 'Search',
        placeholder: 'Search by motive ...',
        model: null,
      },
      fields: Fields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: true,
      motive: '',
      searchInput: '',
      showTracking: false,
      showUpload: false,
      showMotive: false,
      showImage: false,
      urlImage: '',
      extension: '',
      oncontrol: true,
      dataJustification: {},
      justifications: [],
    };
  },
  async mounted() {
    if (this.showAllEmployees) {
      this.fields[9].visible = false;
      this.filters[0].visible = false;
      this.filters[1].visible = false;

      this.filters[0].model = this.valueFilterMonth;
      this.filters[1].model = this.valueFilterYear;
    } else {
      this.fields[1].visible = false;
    }
    this.getYears();

    if (!this.valueFilterApproved) {
      this.fields[5].visible = false;
    } else {
      this.fields[5].visible = true;
    }
  },
  methods: {
    formatTitleCase(text) {
      return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    getYears() {
      const startYearAttendance = 2023;
      const currentYear = moment().format('YYYY');
      this.filters[1].options = [];
      this.filters[1].options.push({
        label: 'All',
        value: null,
      });
      for (let index = startYearAttendance; index <= currentYear; index++) {
        this.filters[1].options.push({
          label: index,
          value: index,
        });
      }
    },
    classProcess(process_id) {
      switch (process_id) {
        case 1:
          return 'light-primary';
        case 2:
          return 'light-warning';
        case 4:
          return 'light-success';
        case 6:
          return 'light-success';
        default:
          return 'light-danger';
      }
    },
    showModalImage(urlImage, extension) {
      this.showImage = true;
      this.urlImage = urlImage;
      this.extension = extension;
    },
    async getMedicalRest(ctx) {
      this.addPreloader();
      try {
        const params = {
          user_id: this.valueFilterEmployee ? this.valueFilterEmployee : this.employee.id,
          type_justification: this.valueFilterType ? null : 4,
          month: this.filters[0].model,
          year: this.filters[1].model,
          day: this.valueFilterDay,
          startWeek: this.valueFilterWeekStart,
          endWeek: this.valueFilterWeekEnd,
          module_id: this.valueFilterModule,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          order_by: ctx?.sortBy,
          order: ctx?.sortDesc === true ? 'DESC' : 'ASC',
          search: this.filterPrincipal.model,
          proccess_id: this.filters[2].model,
          approved: this.valueFilterApproved ? this.valueFilterApproved : null,
        };
        const { data } = await serviceDashboard.getJustification(params);
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.justifications = data.data;
        this.removePreloader();
        return data.data;
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal('Error', 'Error getting medical rest');
        console.error(error);
      }
      return [];
    },
    closeModalList() {
      this.$emit('closeModal');
    },
    closeModalImage() {
      this.showImage = false;
    },
    closeTrackingMedicalRest() {
      this.showTracking = false;
    },
    async closeUploadMedicalRest() {
      this.showUpload = false;
    },
    async refreshTable() {
      this.$refs.refMedicalRestList.refresh();
    },
    showModalTracking(idJustification) {
      this.idJustification = idJustification;
      this.showTracking = true;
    },
    async deletedJustification(id_justification) {
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) {
          return;
        }
        const { status } = await serviceDashboard.deletedJustification({
          id_justification,
        });

        if (status == 200) {
          this.showSuccessSwal('Medical rest deleted');
          this.refreshTable();
        }
      } catch (error) {
        this.showErrorSwal();
        console.error(error);
      }
    },
    uploadMedicalRest() {
      this.dataJustification = {};
      this.showUpload = true;
    },
    editMedicalRest(data) {
      this.dataJustification = data;
      this.showUpload = true;
    },
  },
  computed: {
    showAllEmployees() {
      const result = this.employee.id;
      return !result;
    },
    filteredFields() {
      const fields = this.fields.filter(field => field.visible);
      return fields;
    },
    filteredFilters() {
      const filters = this.filters.filter(filter => filter.visible);
      return filters;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    hoursJustified() {
      return this.justifications
        .map(item => parseInt(item.hours_of_work, 10))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
  },
};
</script>
