<template>
  <b-modal
    v-model="onControl"
    size="xl"
    hide-footer
    centered
    :header-bg-variant="isDarkSkin ? 'transparent': 'white'"
    @hidden="hiddenModal"
  >
    <b-card
      class="shadow-none"
      :class="isDarkSkin ? 'dark-skin':''"
    >
      <b-row class="d-flex justify-content-center position-relative justify-content-center align-items-center">
        <img
          alt="amg-logo"
          class="img-bg"
          :src="require('@/assets/images/logo/amg_logo.svg')"
          :style="isDarkSkin?'filter: invert(100%) !important; /* Invierte los colores */':''"
        >
        <!-- <b-col
          cols="12"
          xl="3"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="text-center">
            <h1 class="font-weight-bolder text-center">
              MY SCHEDULE
            </h1>
            <img
              :src="require('@/assets/images/logo/amg_logo.svg')"
              class="my-0 my-md-2 my-xl-5"
              :class="isDarkSkin ? 'invert' : ''"
              width="70%"
              height="70%"
            >
          </div>
        </b-col> -->
        <b-col
          cols="12"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div
              class="action-btn d-flex justify-content-center align-items-center"
              :class="isDarkSkin ? 'action-btn-dark':''"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                style="color: #0090E7"
                @click="nextMonth(-1)"
              />
            </div>
            <h2
              class="font-weight-bolder mx-2"
              style="font-family: Avenir; font-weight: 800; font-size: 30px; margin-bottom: 0px;"
            >
              {{ `${optionsMonths[month -1]} ${year}` }}
            </h2>
            <div
              class="action-btn d-flex justify-content-center align-items-center"
              :class="isDarkSkin ? 'action-btn-dark':''"
            >
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                style="color: #0090E7"
                @click="nextMonth(1)"
              />
            </div>
          </div>
          <!--DAYS OF CALENDAR-->
          <b-card
            no-body
            class="w-100 my-3 container-wrapper mb-1"
            :style="isDarkSkin ? '': ''"
          >
            <!--NAME OF DAYS-->
            <div
              class="wrapper-head"
              style="min-width: 1000px;"
            >
              <!-- Names of Date -->
              <div
                v-for="(date) in optionsDays"
                :key="date"
                class="position-relative day-name-calendar"
                style="background: #0085FF63; border: 1px solid #CECECE;"
                :style="isDarkSkin? 'border: 1px solid #E8E8E8; background: #0090E7': ''"
              >
                <strong
                  class="d-flex justify-content-left align-items-center"
                  style="font-family: Avenir; font-size: 20px; color: #606060"
                  :style="isDarkSkin? 'color: white': ''"
                >
                  <span>
                    {{
                      date
                    }}
                  </span>
                </strong>
              </div>
            </div>
            <!--DAYS-->
            <div class="wrapper">
              <!--ONLY SPACE EMPTY TO START CALENDAR-->
              <div
                v-for="(empty) in spaceEmptyStart"
                :key="(empty * -1)"
                class="item-calendar"
              >
                <!--ONLY SPACE-->
              </div>
              <!--HERE SHOW DATA-->
              <template>
                <div
                  v-for="(item, index) in schedule"
                  :key="(index * 10)"
                  class="item-calendar"
                  :class="currentDay === item.working_date ? 'font-weight-bolder text-success':''"
                  style="height: 200px; border: 1px solid #CECECE"
                  :style="isDarkSkin?'border: 1px solid #E8E8E8':''"
                >
                  <div class="text-left ml-2 my-1">
                    <span style="color: #606060; font-family:Inter; font-size: 21px; font-style: normal; font-weight: 500; line-height: normal">
                      {{ item.working_date | numberDay }}
                    </span>

                  </div>
                  <ul
                    class="customized-list"
                  >
                    <template
                      v-for="(time, indexTime) in JSON.parse(item.horario)"
                    >
                      <li
                        :key="indexTime + 1 * 100"
                        class="text-left my-1"
                      >
                        <span
                          class="start-style"
                          :style="isDarkSkin ? 'bakcground-color: #00BA3433': ''"
                        >
                          Start: {{ time.work_start_time | myFullTime }}
                        </span>
                      </li>
                      <li
                        :key="indexTime + 1 * 1000"
                        class="text-left mb-1"
                      >
                        <span
                          class="end-style"
                          :style="isDarkSkin ? 'background: rgba(0, 133, 255, 0.10);': ''"
                        >
                          End: {{ time.work_end_time | myFullTime }}
                        </span>
                      </li>
                      <li
                        :key="indexTime + 1 * 10000"
                        class="text-left"
                      >
                        <span>
                          <feather-icon icon="ClockIcon" /> Hours: {{ time.total_hours }}
                        </span>
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
              <!--ONLY SPACE EMPTY TO END CALENDAR-->
              <div
                v-for="(empty) in spaceEmptyEnd"
                :key="empty"
                class="item-calendar"
              >
                <!--ONLY SPACE EMPTY-->
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>
<script>
import moment from 'moment';
import MenuService from '@/views/main/menu.service';

export default {
  filters: {
    numberDay(value) {
      return moment(value, 'YYYY-MM-DD').format('DD') || '-';
    },
  },
  props: {
    params: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      onControl: true,
      month: parseInt(this.params.month, 10),
      year: parseInt(this.params.year, 10),
      optionsMonths: [
        'JANUARY',
        'FEBRUARY',
        'MARCH',
        'APRIL',
        'MAY',
        'JUNE',
        'JULY',
        'AUGUST',
        'SEPTEMBER',
        'OCTOBER',
        'NOVEMBER',
        'DECEMBER',
      ],
      schedule: [
      ],
      optionsDays: [
        'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY',
      ],
      currentDay: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    spaceEmptyStart() {
      return parseInt(
        moment(`${this.year}-${this.month}`, 'YYYY-MM').startOf('month').day(),
        10,
      ) === 0
        ? 6
        : parseInt(
          moment(`${this.year}-${this.month}`, 'YYYY-MM').startOf('month').day(),
          10,
        ) - 1;
    },
    spaceEmptyEnd() {
      return Math.abs(parseInt(
        moment(`${this.year}-${this.month}`, 'YYYY-MM').endOf('month').day(),
        10,
      ) - 7);
    },
  },
  async created() {
    await this.generateSchedule();
  },
  methods: {
    async nextMonth(value) {
      const newDate = moment(`${this.year}-${this.month}`, 'YYYY-MM').add(value, 'month');
      console.log(newDate.format('YYYY-MM'));
      this.year = newDate.format('YYYY');
      this.month = newDate.format('MM');
      await this.generateSchedule();
    },
    hiddenModal() {
      this.$emit('closeModal');
    },
    async generateSchedule() {
      try {
        const params = {
          user_id: this.params.user_id,
          month: this.month,
          year: this.year,
        };
        const { data } = await MenuService.getScheduleByEmployee(params);
        this.schedule = data;
      } catch (error) {
        console.log('error in generateSchedule: ', error);
      }
    },
  },
};
</script>
<style scoped>
.wrapper,
.wrapper-head {
  width: 100rem !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}
.wrapper {
  grid-auto-rows: minmax(auto, 95px);
}
.wrapper-head {
  justify-content: center;
  align-items: center;
  grid-auto-rows: minmax(auto, 50px);
}
.container-wrapper{
  overflow: auto;
  /* border: 1px solid #EAEDED; */
}
.dark-skin .day-name-calendar{
  color: white !important;
}
.day-name-calendar{
  color: #34495e;
  padding: 15px;
}
.item-calendar{
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: font-size 1000ms ease 0s;
  transition: font-size 1000ms ease 0s;
}
.item-calendar:hover{
  font-weight: bolder;
  font-size: 18px;
}
.item-calendar:nth-child(7n+1),
.item-calendar:nth-child(7n+3),
.item-calendar:nth-child(7n+5),
.item-calendar:nth-child(7n+7),
.day-name-calendar:nth-child(7n+1),
.day-name-calendar:nth-child(7n+3),
.day-name-calendar:nth-child(7n+5),
.day-name-calendar:nth-child(7n+7){
  /* background-color: #F8F9F9; */
}

.dark-skin .item-calendar:nth-child(7n+1),
.dark-skin .item-calendar:nth-child(7n+3),
.dark-skin .item-calendar:nth-child(7n+5),
.dark-skin .item-calendar:nth-child(7n+7),
.dark-skin .day-name-calendar:nth-child(7n+1),
.dark-skin .day-name-calendar:nth-child(7n+3),
.dark-skin .day-name-calendar:nth-child(7n+5),
.dark-skin .day-name-calendar:nth-child(7n+7){
  /* background-color: #1f2122 !important; */
}
.item-calendar .day{
  font-size: 15px;
  font-weight: bolder;
}
.customized-list{
  list-style: none;
  padding: 5px 20px;
  margin: 0px;
}

.invert {
  filter: invert(100%) !important; /* Invierte los colores */
}

.my-card{
  background-image: require('@/assets/images/logo/amg_logo.svg');
  background-size: cover; /* Ajusta la imagen al tamaño de la tarjeta */
  background-position: center; /* Centra la imagen en la tarjeta */
  /* Agrega cualquier otro estilo necesario */
}
.img-bg{
  position: absolute;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  opacity: 0.05;
  z-index: 1;
}

.action-btn{
  background: white;width: 30px; height: 30px; border-radius: 50px;
  opacity: 1;
  transition: opacity 1000ms ease 0s;
}

.action-btn:hover{
  background: #f4f4f4;
  opacity: 0.6;
}

.action-btn-dark{
  background: #17171A;width: 30px; height: 30px; border-radius: 50px;
  opacity: 1;
  transition: opacity 1000ms ease 0s;
}

.action-btn-dark:hover{
  background: #000000;
  opacity: 0.6;
}

.start-style{
  color: #00BA34; background-color: #00BA3433; border-radius: 5px; padding: 5px; font-family: Avenir; font-size: 16px;  font-style: normal; font-weight: 800;
}
.end-style{
  color: #0085FF; background-color: #0085FF1A; border-radius: 5px; padding: 5px; font-family: Avenir; font-size: 16px;  font-style: normal; font-weight: 800;
}
</style>
