<template>
  <div style="height:100%; ">
    <b-card
      tag="article"
      style="margin:0; height:100%"
    >
      <b-card-header style="margin:0 0 2rem 0; padding:0">

        <div
          class="text-dark"
          style="font-size: 22px; max-width:140px; font-family: Avenir; font-weight: 900; line-height: 23.10px; word-wrap: break-word"
        >
          Notes ({{ notesArray.length }})
        </div>

        <b-button
          href="#"
          variant="primary"
          class="ml-1"
          style="float: right;"
          @click="openModal(1,{})"
        >
          <strong>+ New note</strong>
        </b-button>

      </b-card-header>
      <div
        v-if="notesArray.length > 0"
        style="height: 300px; overflow: auto;"
      >
        <div
          v-for="(item, index) in notesArray"
          :key="index"
          class="p-1 rounded mb-1"
          :style="getSeverityColor(item.color)"
        >
          <div class="row">
            <div class="col col-md-8">
              <strong>{{ item.subject }}</strong>
              <p>{{ item.created_at | myGlobalDay }}</p>
            </div>
            <div class="col col-md-4 d-flex align-self-start justify-content-end">
              <feather-icon
                icon="MoreVerticalIcon"
                size="13"
                style="cursor: pointer;"
                @click="openModal(2,item)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-center"
        style="height: 0px !important;margin: 7rem 0 0 0"
      >
        <div class="center-content text-warning mt-1">
          <b-row>
            <feather-icon
              icon="SmileIcon"
              size="40"
            />
          </b-row>
          <b-row>
            <h4 class="text-warning mt-1">
              <strong>No notes yet</strong>
            </h4>
          </b-row>
        </div>
      </div>
    </b-card>
    <sticky-notes-compose
      v-if="openNewNoteModal"
      :shall-show-email-compose-modal="openNewNoteModal"
      :note-edit="noteEdit"
      @hiddenAction="openNewNoteModal = false"
      @closeModal="closeModal"
      @getNotesUser="getNotesUser"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Service from '@/views/main/menu.service';
import StickyNotesCompose from '@/layouts/components/navbar/components/sticky-notes/StickyNotesCompose.vue';

export default {
  components: {
    StickyNotesCompose,
  },
  data() {
    return {
      openNewNoteModal: false,
      notesArray: [],
      noteEdit: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    userParentModules() {
      if (this.currentUser) {
        if (Array.isArray(this.currentUser.arrRoles)) {
          return this.currentUser.arrRoles.filter(
            item => item.parent_id == null && item.module_id !== 6,
          );
        }
        const modules = JSON.parse(this.currentUser.arrRoles).filter(
          item => item.parent_id == null && item.module_id !== 6,
        );
        return modules;
      }
      return [];
    },
  },
  mounted() {
    this.getNotesUser();
  },
  methods: {
    openModal(operation, note) {
      if (operation === 1) this.openNewNoteModal = true;
      if (operation === 2) {
        this.noteEdit = note;
        this.openNewNoteModal = true;
      }
    },
    async getNotesUser() {
      try {
        this.addPreloader();
        const obj = {
          id_user: this.currentUser.user_id,
        };
        const res = await Service.getNotesUser(obj);
        if (res.status === 200) {
          this.notesArray = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    getSeverityColor(color) {
      let background = 'background-color: #ffffff40;';
      if (this.isDarkSkin) background = 'severity-1';
      return `border-left: 10px solid ${color};border-top: 1px solid #ccc;border-right: 1px solid #ccc;border-bottom: 1px solid #ccc; ${background}`;
    },
    closeModal() {
      this.noteEdit = null;
      this.openNewNoteModal = false;
    },
  },
};
</script>
<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
</style>
