<template>
  <div class="pt-2 pb-2">
    <b-list-group
      horizontal
      class="wrapper-head"
    >
      <b-list-group-item
        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color:#A09FA5;background-color: #16171B;border-top: 1px solid #373A41 !important; border-left: 1px solid #373A41 !important;':
          'color:#353455;background-color: #FFFFFF;border-top: 1px solid #CDCDCC !important; border-left: 1px solid #CDCDCC !important;'"
        style="border-radius: 10px 0 0 0 !important; "
      >monday</b-list-group-item>
      <b-list-group-item
        style="background-color: #222226"

        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color: #A09FA5;background-color: #222327;border-top: 1px solid #373A41 !important;':
          'color:#353455;background-color: #F7F6FF;border-top: 1px solid #CDCDCC !important;'"
      >tuesday</b-list-group-item>
      <b-list-group-item
        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color: #A09FA5;background-color: #16171B;border-top: 1px solid #373A41 !important;':
          'color:#353455;background-color: #FFFFFF;border-top: 1px solid #CDCDCC !important;'"
      >wednesday</b-list-group-item>
      <b-list-group-item

        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color: #A09FA5;background-color: #222327;border-top: 1px solid #373A41 !important;':
          'color:#353455;background-color: #F7F6FF;border-top: 1px solid #CDCDCC !important;'"
      >thursday</b-list-group-item>
      <b-list-group-item
        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color: #A09FA5;background-color: #16171B;border-top: 1px solid #373A41 !important;':
          'color:#353455;background-color: #FFFFFF;border-top: 1px solid #CDCDCC !important;'"
      >friday</b-list-group-item>
      <b-list-group-item

        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color: #A09FA5;background-color: #222327;border-top: 1px solid #373A41 !important;':
          'color:#353455;background-color: #F7F6FF;border-top: 1px solid #CDCDCC !important;'"
      >saturday</b-list-group-item>
      <b-list-group-item
        class="text-uppercase w-100 text-center pointer-events-none border-0"
        :style="isDarkSkin ?
          'color:#A09FA5;background-color: #16171B;border-top: 1px solid #373A41 !important; border-right: 1px solid #373A41 !important;':
          'color:#353455;background-color: #FFFFFF;border-top: 1px solid #CDCDCC !important; border-right: 1px solid #CDCDCC !important;'"
        style="border-radius: 0 10px 0 0 !important; "
      >sunday</b-list-group-item>
    </b-list-group>
    <!-- data -->
    <div
      class="wrapper"
      :class="isBusy == true ? 'busy' : ''"
      :style="isDarkSkin ?
        'border: 1px solid #373A41 !important;':
        'border: 1px solid #CDCDCC !important;'"
      style="margin-top: -4px;border-radius: 0 0 10px 10px !important;"
    >
      <div
        v-for="i in spaceEmpty"
        :key="i"
        class="w-400"
        :style="
          isDarkSkin
            ? (i % 2 === 0 ? 'background-color:#222327 !important;' : 'background-color:#16171B !important;')
            : (i % 2 === 0 ? 'background-color:#F7F6FF !important;' : 'background-color:#FFFFFF !important;')
        "
      >
        <!-- generated spaces until first day of month-->
      </div>
      <div
        v-for="item in miItems"
        :key="item.day"
        :class="item.state ? 'cursor-pointer' : 'pointer-events-none'"
        class="text-center hover-me position-relative"
        :style="
          [
            'NOT REGISTER',
            'HOLIDAYS',
            'INCONSISTENCY',
            'UNMARKED',
            'ATTENDANCE',
            'DELAY',
            'MEDICAL REST',
            'BIRTHAY',
          ].includes(item.state)
            ? (item.state === 'NOT REGISTER' ? getItemStyle(item) : optionsState[item.state])
            : isDarkSkin? 'border: 1px solid orange; color: orange !important;background-color: #16171B' :
              'border: 1px solid orange; color: orange !important;background-color: white'
        "
        @click="item.state ? detailsMarks(item) : ''"
      >
        <span
          v-if="currentDay === item.date_mark"
          class="current-day"
        />
        <strong
          class="mt-2 w-100 d-block number-day text-center"
          style="font-size: 26px; font-family: Rubik; font-weight: 600 !important;"
        >{{ item.day }}</strong>
        <strong class="state text-uppercase d-block text-center" style="font-family: Montserrat; font-weight: 400; font-size: 15px;">{{
          item.state || "no registered"
        }}</strong>
      </div>
      <div
        v-for="i2 in spaceEmptyAfter"
        :key="i2"
        class="w-100"
        :style="
          isDarkSkin
            ? (i2 % 2 === 0 ? 'background-color:#222327 !important;' : 'background-color:#16171B !important;')
            : (i2 % 2 === 0 ? 'background-color:#F7F6FF !important;' : 'background-color:#FFFFFF !important;')
        "
      >
        <!-- generated spaces until first day of month-->
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center mx-auto">
      <div class="d-flex justify-content-center align-items-center">
        <label class="text-montserrat mr-1">Hours worked:</label>
        <div
          class="text-center d-flex align-items-center tag"
          :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D'"
        >
          <span>{{ roundingHoursPerMonth }}</span>
        </div>
      </div>
      <feather-icon
        class="mt-1 ml-1 mr-1"
        icon="PlusIcon"
        size="30"
      />
      <div class="d-flex justify-content-center align-items-center">
        <label class="text-montserrat mr-1">Holidays:</label>
        <div
          class="text-center d-flex align-items-center tag"
          :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D'"
        >
          <span>{{ holidaysTotal }}</span>
        </div>
      </div>
      <feather-icon
        class="mt-1 ml-1 mr-1"
        icon="PlusIcon"
        size="30"
      />
      <!-- <b-button variant="outline-primary" class="ml-1" size="lg">
        <b
          >Total overtime per month :
          {{ overtimePerMonth }}
        </b>
      </b-button> -->

      <div class="d-flex justify-content-center align-items-center">
        <label class="text-montserrat mr-1">Hours justified:</label>
        <div
          class="text-center d-flex align-items-center tag"
          :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D'"
        >
          <span>{{ overtimePerMonthApproved }}</span>
        </div>
      </div>

      <!-- <b-button
        variant="outline-primary"
        class="ml-1"
        size="lg"
      >
        <b>Hours justified:
          {{ overtimePerMonthApproved }}
        </b>
      </b-button> -->

      <tabler-icon
        class="mt-1 ml-1 mr-1"
        icon="MenuIcon"
        size="30"
      />

      <div class="d-flex justify-content-center align-items-center">
        <label class="text-montserrat mr-1">Total hours:</label>
        <div
          class="text-center d-flex align-items-center tag"
          :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D'"
        >
          <span>{{ roundingHoursPerMonth + overtimePerMonthApproved + holidaysTotal }}</span>
        </div>
      </div>
      <!-- <b-button
        variant="outline-success"
        class="ml-1"
        size="lg"
      >
        <b>Total hours:
          {{ roundingHoursPerMonth + overtimePerMonthApproved + holidaysTotal }}
        </b>
      </b-button> -->
    </div>

    <!-- details marks -->
    <modal-details-marks
      v-if="showDetailsMarks"
      :details="dataMarks"
      @hidden="hiddenDetailsMarks"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import modalDetailsMarks from '@/views/assistance/views/dashboard/components/modalDetailsMarks.vue';
import serviceDashboard from '@/views/assistance/views/dashboard/services/dashboard.services';

export default {
  components: {
    modalDetailsMarks,
  },
  filters: {
    day(value) {
      return value ? moment(value).format('DD') : '-';
    },
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
      required: true,
    },
    typeDashboard: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      isBusy: false,
      roundingHoursPerMonth: 0,
      overtimePerMonth: 0,
      overtimePerMonthApproved: 0,
      items: [],
      dataMarks: {},
      showDetailsMarks: false,
      spaceEmpty:
        parseInt(moment(`${this.filters.month}-${this.filters.year}`, 'MM-YYYY').startOf('month').day()) == 0
          ? 6
          : parseInt(moment(`${this.filters.month}-${this.filters.year}`, 'MM-YYYY').startOf('month').day())
            - 1,
      spaceEmptyAfter:
        parseInt(moment(`${this.filters.month}-${this.filters.year}`, 'MM-YYYY').endOf('month').day()) == 0
          ? 0
          : 7 - parseInt(moment(`${this.filters.month}-${this.filters.year}`, 'MM-YYYY').endOf('month').day()),
      optionsState: {
        UNMARKED: 'background-color:#FF6D6D;color:black !important;',
        FAULTS: 'background-color:#fc424a;color:white !important;',
        'MEDICAL REST': 'background-color:#85C1E9;color:white !important;',
        VACATIONS: 'background-color:#F7DC6F;color:white !important;',
        HOLIDAYS: 'background-color:#9370DB;color:#FFD700; !important;',
        DELAY: 'background-color:rgb(248 255 147);color:black !important;',
        'NOT REGISTER':
          'color:#808B96 !important',
        'NO REGISTERED':
          'color:#808B96 !important;border:1px solid #D5D8DC !important',
        ABSENCE: 'background-color:#fc424a;',
        INCONSISTENCY: 'background-color:#FEB827;color:black !important;',
        ATTENDANCE: 'background-color:#01F2C6; color:black !important;',
      },
      currentDay: moment().format('YYYY-MM-DD'),
      holidaysTotal: 0,
    };
  },
  async mounted() {
    await this.getData();
    await this.getTotalPerMonth();
  },
  methods: {
    getItemStyle(item) {
    // Obtener el día de la semana para el elemento actual
      const dayOfWeek = moment(item.date_mark).format('dddd');

      // Verificar si el estado es 'NOT REGISTER' y si el día es martes, jueves o sábado
      if (item.state === 'NOT REGISTER' && ['Tuesday', 'Thursday', 'Saturday'].includes(dayOfWeek)) {
        if (this.isDarkSkin) {
          return 'background-color: #222327;';
        }
        return 'background-color: #F8F9FA;';
      }
      if (this.isDarkSkin) {
        return 'background-color: #16171B;';
      }
      return 'background-color: #FFFFFF;';
    },
    async getTotalPerMonth() {
      try {
        const params = {
          user_id: this.filters.userId,
          month: this.filters.month,
          year: this.filters.year,
        };
        const { data } = await serviceDashboard.getTotalPerMonth(params);
        this.roundingHoursPerMonth = data.totalRoundingHours;
        this.overtimePerMonth = data.totalOvertime;
        this.overtimePerMonthApproved = data.totalOvertimeApproved;
        this.holidaysTotal = data.holidays;
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
      }
    },
    detailsMarks(item) {
      this.showDetailsMarks = true;
      this.dataMarks = item;
    },
    hiddenDetailsMarks() {
      this.showDetailsMarks = false;
    },
    async getData() {
      try {
        this.isBusy = true;
        const params = {
          user_id: this.filters.userId,
          filterDay: null,
          filterWeekStart: null,
          filterWeekEnd: null,
          filterMonth: this.filters.month,
          filterYear: this.filters.year,
        };
        const { data } = await serviceDashboard.getDetailsAttendance(params);
        this.items = data.data;
        this.isBusy = false;
        if (this.typeDashboard == 'dashboardUser') {
          this.$emit('hoursAcumulated', this.hoursAccumulated());
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.isBusy = false;
      }
    },
    hoursAccumulated() {
      const hoursAccumulated = this.items.reduce((a, b) => parseInt(a) + parseInt(b.total_hours_validate), 0);
      return hoursAccumulated;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    roundedHours() {
      return this.items
        .map(item => item.working_hours_rounded)
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    overtime() {
      return this.items
        .map(item => item.overtime)
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    miItems() {
      if (this.items.length > 0) {
        return this.items.map(item => ({
          ...item,
          date_mark: moment(item.date_mark).format('YYYY-MM-DD'),
          day: moment(item.date_mark).format('DD'),
          state: item.type,
        }));
      }
      // without data of bd
      const array = [];
      const startDay = parseInt(
        moment(this.filters.month).startOf('month').format('DD'),
      );
      const lastDay = parseInt(
        moment(this.filters.month).endOf('month').format('DD'),
      );

      // generated only calendar
      for (let index = startDay; index <= lastDay; index++) {
        array.push({
          day: moment(index, 'DD').format('DD'),
          cc: 0,
          date_mark: moment(
            `${this.filters.year}-${this.filters.month}-${index}`,
            'YYYY-MM-DD',
          ).format('YYYY-MM-DD'),
          employee: null,
          first_mark: null,
          first_mark_rounded: null,
          fourth_mark: null,
          last_mark: null,
          last_mark_rounded: null,
          missing_hours: null,
          num_marks: null,
          overtime: null,
          second_mark: null,
          third_mark: null,
          working_hours: null,
          working_hours_rounded: null,
          state: 'NOT REGISTER',
        });
      }
      return array;
    },
  },
};
</script>

<style scoped>
.wrapper.busy::before {
  content: "Loading...";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #82899194;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  color: white;
  font-weight: bolder;
  font-size: 30px;
}
.wrapper,
.wrapper-head {
  width: 92rem !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}
.wrapper {
  grid-auto-rows: minmax(auto, 90px);
}
.wrapper-head {
  justify-content: center;
  align-items: center;
  grid-auto-rows: minmax(auto, 50px);
}
.hover-me {
  overflow: hidden;
}
.hover-me .number-day,
.hover-me .state {
  transition: 200ms ease-in-out;
}
.hover-me:hover .number-day {
  transform: scale(0.8);
}
.hover-me:hover .state {
  transform: scale(1.2);
}
.hover-me:hover{
  z-index:1;
}
.current-day {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #0d96d6;
  border: 2px solid white;
  z-index: 9;
  border-radius: 50% 50% 50% 0%;
  animation: growup 0.5s ease-in-out infinite;
  right: 10px;
  top: calc(50% - 10px);
}
@keyframes growup {
  0% {
    transform: scale(1) translateY(0%) rotate(-45deg);
  }
  50% {
    transform: scale(0.8) translateY(-50%) rotate(-45deg);
  }
  100% {
    transform: scale(1) translateY(0%) rotate(-45deg);
  }
}

.tag{
  background: #332b29;
  font-size: 23px;
  width: 92px;
  height: 55px;
  border-radius: 8px;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 23px;
}

.text-montserrat{
  font-family: Montserrat;
  font-weight: 500;
  font-size: 19px;
  line-height: 14px;
}
</style>
