<template>
  <div class="p-0 m-0">
    <b-card
      tag="article"
      style="margin:0 !important"
    >
      <b-row>
        <b-col
          cols="12"
          xl="7"
        >
          <div>
            <b-row>
              <b-col
                cols="12"
                class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row"
                style="justify-content: space-between;"
              >
                <div
                  class="d-flex"
                  style="align-items:center;justify-content:center"
                >
                  <change-month
                    @changeMonth="changeData"
                  />
                </div>
                <div
                  class="d-flex"
                  style="align-items:center;justify-content:center"
                >
                  <router-link
                    to="/attendances"
                  >
                    <b-button
                      variant="primary"
                      class="d-flex align-items-center justify-content-center btn btn-sm"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        size="16"
                      />
                      <span
                        style="text-align:start;margin-left:5px;font-family:'Avenir',sans-serif;font-weight:500;font-size:15px"
                      >
                        Attendance Dashboard
                      </span>
                    </b-button>
                  </router-link>
                </div>
                <div
                  class="d-flex"
                  style="align-items:center;justify-content:center"
                >
                  <tabler-icon
                    icon="RefreshIcon"
                    class="cursor-pointer"
                    style="padding:5px"
                    size="25"
                    @click="getIndicators"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="!loading"
            >
              <!-- FAULTS -->
              <b-col
                cols="12"
                xl="5"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#FFD2D2"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openFaults"
                >
                  <div class="d-flex align-items-center justify-content-around mr-2">
                    <div>
                      <b-img
                        src="~@/assets/images/icons/your_faults.svg"
                        width="50"
                        height="50"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Your Absences</span>
                    </div>
                    <div
                      style="font-size: 15px;background-color:#FD2424;border-radius: 5px;padding:10px 15px;white-space: nowrap;"
                      class="text-white rounded d-flex align-items-center justify-content-center"
                    >
                      <strong>{{ indicators.employees_absence }} days</strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- FAULTS -->
              <!-- ATTENDANCES -->
              <b-col
                cols="12"
                xl="5"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#DEFFD2"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openAttendances"
                >
                  <div class="d-flex align-items-center justify-content-around mr-2">
                    <div>
                      <b-img
                        src="~@/assets/images/icons/attendances.svg"
                        width="50"
                        height="50"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Attendances</span>
                    </div>
                    <div
                      style="font-size: 15px;background-color:#0BEB3C;border-radius: 5px;padding:10px 15px"
                      class="text-white rounded d-flex align-items-center justify-content-center"
                    >
                      <strong>{{ indicators.employees_attendance }} days</strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- ATTENDANCES -->
              <!-- LATES -->
              <b-col
                cols="12"
                xl="2"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#FFD2D2"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openTotalLates"
                >
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Total Lates</span>
                    </div>
                    <div
                      style="font-size: 15px;background-color:#FD2424;border-radius: 5px;padding:10px 15px"
                      class="text-white rounded d-flex align-items-center justify-content-center"
                    >
                      <strong>{{ indicators.lateness_indicator }}</strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- LATES -->
              <!-- JUSTIFICATIONS -->
              <b-col
                cols="12"
                xl="5"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#FFEAD2"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openJustifications"
                >
                  <div class="d-flex align-items-center justify-content-around mr-1">
                    <div>
                      <b-img
                        src="~@/assets/images/icons/justifications.svg"
                        width="50"
                        height="50"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Justifications</span>
                    </div>
                    <div
                      style="font-size: 15px;background-color:#FF900D;border-radius: 5px;padding:10px 15px"
                      class="text-white rounded d-flex align-items-center justify-content-center"
                    >
                      <strong>{{ indicators.justified_hours ? indicators.justified_hours : 0 }} hrs</strong>
                    </div>
                  </div>
                </div>

              </b-col>
              <!-- JUSTIFICATIONS -->
              <!-- MEDICAL REST -->
              <b-col
                cols="12"
                xl="5"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#A8DFFF"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openMedicalRest"
                >
                  <div class="d-flex align-items-center justify-content-around mr-2">
                    <div>
                      <b-img
                        src="~@/assets/images/icons/medical_rest.svg"
                        width="50"
                        height="50"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Medical Rest</span>
                    </div>
                    <div
                      style="font-size: 15px;background-color:#0993E3;border-radius: 5px;padding:10px 15px"
                      class="text-white rounded d-flex align-items-center justify-content-center"
                    >
                      <strong>{{ indicators.medical_rest }}</strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- MEDICAL REST -->
              <!-- CALENDAR -->
              <b-col
                cols="12"
                xl="2"
                style="padding:5px;"
              >
                <div
                  style="border-radius:10px;background-color:#FFDEB7"
                  class="d-flex flex-row align-items-center justify-content-center px-3 py-1 cursor-pointer"
                  @click="openCalendar"
                >
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <span
                        style="font-family: Avenir, sans-serif; font-weight: 500; font-size: 15px;white-space: nowrap;"
                        class="text-black"
                      >Calendar</span>
                    </div>
                    <div>
                      <b-img
                        src="~@/assets/images/icons/Calendar.svg"
                        width="40"
                        height="40"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-else
              class="loading-spinner"
              style="margin-top:5rem;margin-bottom:5rem"
            >
              <b-col
                cols="12"
                class="d-flex"
                style="align-items:center;justify-content:center"
              >
                <div class="spinner" />
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="5"
        >
          <hours-of-employees
            v-if="month!=null"
            :key="keyDonut"
            :month="month"
            :year="year"
            :user-id="currentUser.user_id"
          />
        </b-col>
      </b-row>

      <modal-employees-absence
        v-if="showModalAbsence"
        :value-filter-day="valueFilterDay"
        :value-filter-month="month"
        :value-filter-year="year"
        :value-filter-week-start="valueFilterWeekStart"
        :value-filter-week-end="valueFilterWeekEnd"
        :value-filter-module="valueFilterModule"
        :value-filter-employee="currentUser.user_id"
        @closing="showModalAbsence=false"
      />
      <modal-employees-attendances
        v-if="showModalAttendances"
        :value-filter-day="valueFilterDay"
        :value-filter-month="month"
        :value-filter-year="year"
        :value-filter-week-start="valueFilterWeekStart"
        :value-filter-week-end="valueFilterWeekEnd"
        :value-filter-module="valueFilterModule"
        :value-filter-employee="currentUser.user_id"
        @closing="showModalAttendances=false"
      />
      <modal-employees-birthday
        v-if="showModalBirthday"
        :value-filter-day="valueFilterDay"
        :value-filter-month="month"
        :value-filter-year="year"
        :value-filter-week-start="valueFilterWeekStart"
        :value-filter-week-end="valueFilterWeekEnd"
        :value-filter-module="valueFilterModule"
        :value-filter-employee="currentUser.user_id"
        @closing="showModalBirthday=false"
      />
      <modal-employees-medical-rest
        v-if="showModalMedicalRest"
        :value-filter-day="valueFilterDay"
        :value-filter-month="month"
        :value-filter-year="year"
        :value-filter-week-start="valueFilterWeekStart"
        :value-filter-week-end="valueFilterWeekEnd"
        :value-filter-module="valueFilterModule"
        :value-filter-employee="currentUser.user_id"
        :value-filter-type="typeJustification"
        :value-filter-approved="approved"
        @closeModal="showModalMedicalRest=false"
      />
      <modal-lateness
        v-if="showModalLate"
        :value-filter-day="valueFilterDay"
        :value-filter-month="month"
        :value-filter-year="year"
        :value-filter-week-start="valueFilterWeekStart"
        :value-filter-week-end="valueFilterWeekEnd"
        :value-filter-module="valueFilterModule"
        :value-filter-employee="currentUser.user_id"
        @closeModal="showModalLate=false"
      />
      <modal-details-employee
        v-if="showDetailsEmployee"
        :second-filter="secondFilter"
        :string-type-view-detail="'calendar'"
        :name-employee="currentUser.fullName"
        :filters-mark="{
          userId: currentUser.user_id,
          day: null,
          weekStart: null,
          weekEnd: null,
          month: month,
          year: year,
        }"
        :filters-grid="{
          userId: currentUser.user_id,
          month: month,
          year: year,
        }"
        @hidden="showDetailsEmployee = false"
      />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';
import ModalEmployeesAbsence from '@/views/assistance/views/dashboard/components/ModalEmployeesAbsence.vue';
import ModalEmployeesBirthday from '@/views/assistance/views/dashboard/components/ModalEmployeesBirthday.vue';
import ModalEmployeesMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalMedicalRest.vue';
import ModalLateness from '@/views/assistance/views/dashboard/components/ModaLateness.vue';
import ModalDetailsEmployee from '@/views/assistance/views/dashboard/components/ModalDetailsEmployee.vue';
import ModalEmployeesAttendances from '@/views/assistance/views/dashboard/components/ModalEmployeesAttendances.vue';
import ChangeMonth from '@/views/main/dashboard-components/ChangeMonth.vue';
import HoursOfEmployees from '@/views/main/dashboard-components/HoursOfEmployees.vue';

export default {
  components: {
    ModalEmployeesAbsence,
    ModalEmployeesBirthday,
    ModalEmployeesMedicalRest,
    ModalLateness,
    ModalDetailsEmployee,
    ModalEmployeesAttendances,
    ChangeMonth,
    HoursOfEmployees,
  },
  props: {
    valueFilterMonth: {
      type: Number,
      default: null,
    },
    valueFilterYear: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      indicators: [],
      showModalAbsence: false,
      showModalIncosistencies: false,
      showModalAttendances: false,
      showModalBirthday: false,
      showModalMedicalRest: false,
      showModalLate: false,
      showModalCalendar: false,
      showDetailsEmployee: false,
      valueFilterDay: null,
      valueFilterWeekStart: null,
      valueFilterWeekEnd: null,
      valueFilterModule: null,
      month: null,
      year: null,
      secondFilter: {
        options: [
          {
            text: 'Day',
            value: 'day',
          },
          {
            text: 'Week',
            value: 'week',
          },
          {
            text: 'Month',
            value: 'month',
          },
        ],
        value: 'month',
      },
      typeJustification: null,
      approved: null,
      loading: true,
      keyDonut: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.month = this.valueFilterMonth;
    this.year = this.valueFilterYear;
    this.getIndicators();
  },
  methods: {
    getMonthName(month) {
      return this.monthNames[month - 1];
    },
    changeData(month, year) {
      this.month = month;
      this.year = year;
      this.getIndicators();
    },
    async getIndicators() {
      this.loading = true;
      try {
        const params = {
          module_id: null,
          user_id: this.currentUser.user_id,
          filterDay: null,
          filterWeekStart: null,
          filterWeekEnd: null,
          filterMonth: this.month,
          filterYear: this.year,
        };
        const data = await DashboardServices.getIndicatorsAttendance(params);
        if (data.status === 200) {
          this.loading = false;
          this.indicators = data.data.data[0];
          this.keyDonut += 1;
          return this.indicators;
        }
      } catch (error) {
        this.loading = false;
        this.showErrorSwal(error);
        console.error(error);
        return null;
      }
      return null;
    },
    openFaults() {
      this.showModalAbsence = true;
    },
    openAttendances() {
      this.showModalAttendances = true;
    },
    openJustifications() {
      this.approved = 1;
      this.typeJustification = 'all';
      this.showModalMedicalRest = true;
    },
    openCalendar() {
      this.showDetailsEmployee = true;
    },
    openMedicalRest() {
      this.approved = null;
      this.typeJustification = null;
      this.showModalMedicalRest = true;
    },
    openTotalLates() {
      this.showModalLate = true;
    },
  },
};
</script>
<style scoped>
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 2s linear infinite;
  margin-bottom: 10px;
}

.padspace{
  padding: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
