<template>
  <div>
    <b-modal
      v-model="onControl"
      size="md"
      hide-footer
      centered
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong>ROLES</strong>
      </template>
      <b-card class="shadow-none pb-0 mb-0">
        <b-row>
          <b-col
            cols="12"
          >
            <div v-if="!roles || roles.length === 0">
              <p>No roles were found.</p>
            </div>
            <b-list-group v-else>

              <b-list-group-item
                v-for="(rol,index) in roles"
                :key="index"

                :style="isDarkSkin ? 'background-color: black !important;':'bg-light'"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-uppercase">{{ rol.value }}</span>
                  <feather-icon
                    v-if="rol.have_mof === 1"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.left
                    icon="BookOpenIcon"
                    size="16"
                    class="cursor-pointer text-primary hover-me':'text-secondary"
                    title="Show MOF"
                    @click="getMOF(rol.role_id)"
                  />
                  <feather-icon
                    v-else
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.left
                    icon="BookOpenIcon"
                    size="16"
                    class="text-secondary"
                    title="No haven't MOF"
                  />
                </div>
                <div style="border-top: 1px solid gray; margin-top: 10px">
                  <ul class="mt-1">
                    <li
                      v-for="(employee, id) in rol.employees"
                      :key="id"
                      style="list-style: none;"
                    >
                      <tabler-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-1 text-primary"
                      />
                      {{ employee.employee_name }} <span class="text-warning">({{ employee.work_area }})</span>
                    </li>
                  </ul>
                </div>

              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
      <template #modal-footer>
        <div />
      </template>
    </b-modal>
    <!--Modal Review-->
    <modal-review
      v-if="showModalReviewMofByCeo"
      :mof-data="selectedMOF"
      :readonly="true"
      @hidden="showModalReviewMofByCeo = false"
      @reload="refreshTable()"
    />
  </div>
</template>
<script>
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';
import ModalReview from '@/views/commons/components/manual-of-functions/modals/ModalReviewByCEO.vue';

export default {
  components: {
    ModalReview,
  },
  props: {
    nodeId: null,
  },
  data() {
    return {
      onControl: false,
      showModalReviewMofByCeo: false,
      selectedMOF: {},
      roles: [],
    };
  },
  mounted() {
    this.onControl = true;
    this.getRoles(this.nodeId);
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async getRoles(nodeId) {
      try {
        this.addPreloader();
        const params = {
          work_area_id: nodeId,
        };
        const data = await WorkAreasService.getRoles(params);
        this.roles = data.data;
        // Consolidar empleados antes de eliminar duplicados
        this.roles.forEach((role, index, self) => {
          const duplicateIndex = self.findIndex(t => t.role_id === role.role_id);
          if (duplicateIndex !== index) {
            // Encontrado un duplicado, combinar empleados
            self[index].employees.push(...self[duplicateIndex].employees);
            self.splice(duplicateIndex, 1); // Eliminar el duplicado
          }
          // recorrer employees y eliminar el duplicado pero antes combinar work_area
          role.employees.forEach((employee, index, self) => {
            // si existen employee_name duplicados, combinar sus work_area
            const duplicateIndex = self.findIndex(t => t.employee_name === employee.employee_name);
            if (duplicateIndex !== index) {
              // Encontrado un duplicado, combinar work_area
              self[index].work_area = `${self[index].work_area}, ${self[duplicateIndex].work_area}`;
              self.splice(duplicateIndex, 1); // Eliminar el duplicado
            }
          });
        });
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
      }
      this.removePreloader();
    },
    async getMOF(rolId) {
      try {
        const params = {
          page: 1,
          perpage: 100,
          campo: null,
          tab: 2,
          user_id: null,
          status: null,
          from: null,
          to: null,
          rol_id: rolId,
        };
        const { data } = await WorkAreasService.getMof(params);
        this.selectedMOF = data?.data[0] || {};
        this.showModalReviewMofByCeo = true;
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
      }
    },
  },
};
</script>
