<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <tabler-icon
          icon="CashIcon"
          size="20"
          ref="notificationIcon"
          :badge="
            G_OFFER_NOTIFICATION_COUNTER > 99
              ? '+99'
              : G_OFFER_NOTIFICATION_COUNTER
          "
          badge-classes="badge-important"
          class="text-body"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Settlements</h4>
          <b-badge pill variant="light-primary"
            >{{ G_OFFER_NOTIFICATION_COUNTER }} New</b-badge
          >
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-if="G_OFFER_NOTIFICATION_COUNTER > 0"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <template v-for="notification in S_OFFER_NOTIFICATION.settlements">
          <div
            :key="notification.id"
            class="cursor-pointer"
            @click="
              currentUser.role_id == 1
                ? resumeOfferModal(notification)
                : settlementOfferModal(notification)
            "
          >
            <b-media>
              <template #aside>
                <b-avatar size="32" variant="light-secondary">
                  <feather-icon icon="InfoIcon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span
                  class="font-weight-bolder"
                  v-html="notification.notification"
                />

                <span
                  v-if="notification.status == 0"
                  class="float-right cursor-pointer unread-notification"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="5"
                      cy="5"
                      r="5"
                      fill="#00b5b8"
                    />
                  </svg>
                </span>
              </p>

              <small class="notification-text">
                {{ notification.created_at | myDateGlobal }}
              </small>
            </b-media>
          </div>
        </template>
      </vue-perfect-scrollbar>

      <!-- No Notifications -->
      <li v-else class="text-center my-3">
        <p class="mb-0">No settlements found to evaluate</p>
      </li>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="notificationModal = true"
          >Read all settlements</b-button
        >
      </li>
    </b-nav-item-dropdown>
    <b-modal
      v-model="notificationModal"
      title-class="h3 text-white"
      size="lg"
      scrollable
      title="Settlements"
      hide-footer
      modal-class="modal-primary"
    >
      <offer-notification-list
        @closeNotifications="notificationModal = false"
      ></offer-notification-list>
    </b-modal>

    <OfferResumeModal
      :show="showResumeModal"
      :info="resumeModalInfo"
      @closing="closeResumeModal"
      @reload="updateNotifications"
    ></OfferResumeModal>

    <SettlementOfferModal
      v-if="showSettlementOfferModal"
      :offerInfo="offerInfo"
      @closing="closeSettlementOfferModal"
      @reload="updateNotifications"
    ></SettlementOfferModal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import NotificationList from "./components/notifications/NotificationList.vue";
import NewClientStatusHoldModal from "./components/notifications/NewClientStatusHoldModal.vue";
import PriorityOfferService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService.js";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import OfferResumeModal from "@/views/management/views/approval-process/settlement-approval/components/ResumedOfferModal.vue";
import OfferNotificationList from "@/layouts/components/navbar/components/notifications/OfferNotificationList.vue";

export default {
  mounted() {
    if (this.currentUser) {
      this.A_GET_OFFER_NOTIFICATIONS({ role_id: this.currentUser.role_id });
    }
  },
  components: {
    VuePerfectScrollbar,
    NotificationList,
    NewClientStatusHoldModal,
    OfferResumeModal,
    SettlementOfferModal,
    OfferNotificationList,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_OFFER_NOTIFICATION_COUNTER:
        "OfferNotificationStore/G_OFFER_NOTIFICATION_COUNTER",
    }),
    ...mapState({
      S_OFFER_NOTIFICATION: (state) =>
        state.OfferNotificationStore.S_OFFER_NOTIFICATION,
    }),
  },
  data() {
    return {
      showSettlementOfferModal: false,
      offerInfo: {},
      showResumeModal: false,
      resumeModalInfo: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationModal: false,
      accountsHoldModal: false,
      selectedNotification: {},
    };
  },
  watch: {
    G_OFFER_NOTIFICATION_COUNTER: {
      handler: function (val) {
        if (val > 0) {
          setTimeout(() => {
            // play audio
            // this.$emit("play");
            this.$refs.notificationIcon.classList.add("wiggle");
          }, 1000);

          setTimeout(() => {
            this.$refs.notificationIcon.classList.remove("wiggle");
          }, 10000);
        }

        // } else {
        //   setTimeout(() => {
        //     this.$refs.notificationIcon.classList.remove("wiggle");
        //   }, 5000);
        // }
      },
      deep: true,
    },
  },

  methods: {
    closeSettlementOfferModal() {
      this.showSettlementOfferModal = false;
    },
    // show to supervisor
    async settlementOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      this.offerInfo = {
        mode: "watch",
        client: data[0].client,
        creditorName: data[0].creditor,
        creditorAccount: data[0].creditor_account,
        creditorId: data[0].creditor_id,
        balance: data[0].balance,
        id: data[0].id,
        clientAccountId: data[0].client_account_id,
        creditor_id: data[0].ds_credit_id,
      };
      this.showSettlementOfferModal = true;
    },

    updateNotifications() {
      this.A_GET_OFFER_NOTIFICATIONS({ role_id: this.currentUser.role_id });
    },
    // show to ceo
    async resumeOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      this.resumeModalInfo = { ...data[0] };
      this.showResumeModal = true;
    },
    ...mapActions({
      A_GET_OFFER_NOTIFICATIONS:
        "OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS",
    }),
    ...mapMutations({
      DECREASE_NOTIFICATION_COUNTER:
        "OfferNotificationStore/DECREASE_NOTIFICATION_COUNTER",
    }),
    openModalAccountHold(notification) {
      this.accountsHoldModal = true;
      this.selectedNotification = notification;
    },
    closeResumeModal() {
      this.showResumeModal = false;
    },
  },
};
</script>

<style>
.wiggle {
  animation: wiggle 2s linear infinite;
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
</style>
