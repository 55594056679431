<template>
  <div class="bg-home">
    <div class="pt-1 center-card-home">
      <div class="btn-back" v-if="isCeo">
        <b-button variant="primary" @click="redirectDashboard">
          <feather-icon icon="CornerDownLeftIcon" class="mr-05" /> Go to
          Dashboard
        </b-button>
      </div>
      <b-row class="justify-content-center">
        <b-col
          v-for="(item, index) in userParentModules"
          :key="index"
          md="2"
          sm="4"
          cols="6"
          class="text-center mb-5 center-fix"
        >
          <offer-menu-badge
            v-if="isCeo && item.module_id === 5"
            v-b-tooltip.hover.top="'Settlements'"
            class="menu-badge"
          />
          <router-link :to="{ path: item.module_route }" class="card-logo">
            <img
              :src="`/assets/${item.module_icon}`"
              width="65"
              alt="Logo"
              height="65"
              class="mt-13"
              :class="
                [3, 5, 6, 7, 8, 11, 12, 15, 18, 21, 27, 14].includes(
                  item.module_id
                )
                  ? ''
                  : 'gray-home'
              "
            />

            <p
              class="font-name-home"
              :class="[
                skin == 'dark' ? 'text-light' : 'text-dark',
                item.module_id != 21 && 'mt-1',
              ]"
              :style="
                item.module_id == 21
                  ? 'margin-top: 10px !important; white-space: break-spaces;'
                  : ''
              "
            >
              {{ item.module_name }}
            </p>
          </router-link>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import OffersDropdown from "@/layouts/components/navbar/OffersDropdown.vue";
import OfferMenuBadge from "@/views/main/menu-badge/OfferMenuBadge.vue"; 
export default {
  components: {
    OfferMenuBadge,
    OffersDropdown, 
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      navbarConfig: this.$store.state.appConfig.layout.navbar.type,
      enviroment: process.env.VUE_APP_ENVIROMENT,
    };
  },
  computed: {
    isCeo() {
      return this.currentUser.role_id === 1;
    },
    userModules() {
      if (this.currentUser) {
        if (Array.isArray(this.currentUser.arrRoles))
          return this.currentUser.arrRoles;
        return JSON.parse(this.currentUser.arrRoles);
      }
      return [];
    },
    userParentModules() {
      if (this.currentUser) {
        if (Array.isArray(this.currentUser.arrRoles)) {
          return this.currentUser.arrRoles.filter(
            (item) => item.parent_id == null && item.module_id != 6
          );
        }
        const modules = JSON.parse(this.currentUser.arrRoles).filter(
          (item) => item.parent_id == null && item.module_id != 6
        );
        return modules;
      }
      return [];
    },
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
  },
  mounted() {},
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden); 
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    redirectDashboard() {
      this.$router.push({ name: "ceo-dashboard" });
    },
    redirectToOldSoft(route) {
      window.open(
        `${process.env.VUE_APP_ORIGINAL_SOFT}api/v1/auth/autologin?id=${this.currentUser.user_id}&route=${route}`,
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.bg-home {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* center */
  display: table;
  width: 100%;
}

.btn-back {
  /* width:100px; */
  border-radius: 10px;
  margin: 0px 50px 25px;
}

.center-card-home {
  display: table-cell;
  vertical-align: middle;
}
.content-wrapper {
  min-height: auto !important;
}
.content {
  min-height: calc(100vh - 110px) !important;
}
.card-logo {
  transition: all 0.2s ease-in-out;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  text-decoration: none !important;
  border: 1px solid #dbdbdb;
}
.card-logo:hover {
  border: 1.5px solid #3764ff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.center-fix {
  display: flex;
  justify-content: center;
}
.font-name-home {
  font-family: "Rubik", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
}
.gray-home {
  transition: all 0.1s ease;
  filter: invert(93%) sepia(12%) saturate(0%) hue-rotate(127deg) brightness(83%)
    contrast(80%);
}
.card-logo:hover .gray-home {
  filter: invert(59%) sepia(7%) saturate(18%) hue-rotate(11deg) brightness(97%)
    contrast(89%);
}
.mt-13 {
  margin-top: 13px !important;
}

.menu-badge {
  position: absolute;
  right: 25%;
  top: -12%;
}
.mr-05 {
  margin-right: 0.5rem;
}
</style>
