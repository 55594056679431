<template>
  <div>
    <b-modal
      v-model="oncontrol"
      size="lg"
      header-bg-variant="ligth"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      centered
      class="border-0 border-white"
    >
      <template #modal-header>
        <div class="w-100 d-flex justify-content-start align-items-center">
          <h4 class="font-weight-bolder p-0 m-0">Medical Rest</h4>
        </div>
      </template>
      <b-container fluid class="p-1" style="height: 700px">
        <iframe
          v-if="!['png', 'jpg', 'jpeg'].includes(extension)"
          :src="urlImg"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
        <img
          v-else
          :src="urlImg"
          alt="Medical rest"
          width="100%"
          height="100%"
        />
      </b-container>
      <template #modal-footer>
        <b-button class="cursor-pointer" variant="danger" @click="closeModal">
          <tabler-icon icon="EyeOffIcon" size="20"></tabler-icon>
          Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
  
<script>
export default {
  props: {
    urlImg: {
      type: String,
      default: "",
    },
    extension: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      oncontrol: false,
    };
  },
  mounted() {
    this.oncontrol = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>