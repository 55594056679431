<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
      >
        <b-row class="section-1">
          <b-col>
            <welcome-component />
          </b-col>
          <b-col
            v-if="isSupervisor || isPermitedUserCustomerTickets || isChiefParagon"
            cols="12"
            md="3"
            class="mt-1 mt-md-0"
          >
            <ticket-customer />
          </b-col>
        </b-row>
        <b-row class="section-2">
          <b-col
            cols="12"
            xl="4"
          >
            <schedule-and-hours
              style="height: 100% !important"
              :month="
                parseInt(valueFilterMonth) ? parseInt(valueFilterMonth) : 0
              "
              :year="parseInt(valueFilterYear) ? parseInt(valueFilterYear) : 0"
            />
          </b-col>
          <b-col
            cols="12"
            xl="8"
          >
            <indicators-attendances-home
              :value-filter-month="parseInt(valueFilterMonth)"
              :value-filter-year="parseInt(valueFilterYear)"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="3"
      >
        <birthdays-component />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        xl="9"
      >
        <b-row class="section-3">
          <b-col
            cols="12"
            xl="4"
          >
            <payroll-payments-home />
          </b-col>
          <b-col
            cols="12"
            xl="5"
          >
            <staff-claims-home />
          </b-col>
          <b-col
            cols="12"
            xl="3"
          >
            <organigram-home />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="3"
      >
        <notes-home />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment';
import OrganigramHome from '@/views/main/dashboard-components/OrganigramHome.vue';
import PayrollPaymentsHome from '@/views/main/dashboard-components/PayrollPaymentsHome.vue';
import StaffClaimsHome from '@/views/main/dashboard-components/StaffClaimsHome.vue';
import IndicatorsAttendancesHome from '@/views/main/dashboard-components/IndicatorsAttendancesHome.vue';
import ScheduleAndHours from '@/views/main/dashboard-components/ScheduleAndHours/ScheduleAndHours.vue';
import BirthdaysComponent from '@/views/main/dashboard-components/Birthday.vue';
import WelcomeComponent from '@/views/main/dashboard-components/Welcome.vue';
import NotesHome from '@/views/main/dashboard-components/NotesHome.vue';
import TicketCustomer from '@/views/main/dashboard-components/TicketCustomer.vue';
import Service from '@/views/main/menu.service';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    OrganigramHome,
    PayrollPaymentsHome,
    StaffClaimsHome,
    IndicatorsAttendancesHome,
    ScheduleAndHours,
    BirthdaysComponent,
    WelcomeComponent,
    NotesHome,
    TicketCustomer,
  },
  data() {
    return {
      valueFilterMonth: null,
      valueFilterYear: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isSupervisor() {
      if (this.currentUser.arrRoles == null) return false;
      if (this.currentUser.arrRoles.find(rol => (rol.role_id === 1 || rol.role_id === 6) && rol.module_id !== 24 && rol.user_id !== 38)) return true;
      return false;
    },
    isPermitedUserCustomerTickets() {
      return this.$store.getters['NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS'].includes(this.currentUser.user_id);
    },

    isChiefParagon(){
      if (this.currentUser.arrRoles == null) return false;
      if (this.currentUser.arrRoles.find(rol => rol.role_id == 17 && rol.module_id == 12)) return true;
    }
  },
  mounted() {},
  created() {
    // this.$store.dispatch('NotificationStore/A_USERS_AVAILABILITY_CUSTOMER_TICKETS');
    this.valueFilterMonth = parseInt(moment().format('MM'), 10);
    this.valueFilterYear = parseInt(moment().format('YYYY'), 10);
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'sticky' });
    this.verifyIfHasEssentialDocuments();
  },
  methods: {
    async verifyIfHasEssentialDocuments() {
      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
        };
        const res = await Service.verifyIfHasEssentialDocuments(obj);
        if (res.status === 200) {
          await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(res.data.value);
          if (res.data.value === 1) {
            await Vue.prototype.$amgAlert({
              title: 'Human Talent has send you a notification',
              icon: 'AlertCircleIcon',
              subtitle: `${res.data.message}`,
              data: [],
              ok: async () => {
                await window.amgApi.post(
                  '/commons/close-all-swal',
                  res.data.message,
                );
                this.$router.push('/my-profile/other-information');
              },
            });
          }
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        'UserStore/A_SAVE_USER_STATUS_ESSENTIAL_DOCS',
    }),
  },
};
</script>
<style lang="scss" scoped>
.section-1 {
  margin-bottom: 15px;
}
@media (width <= 600px) {
  .section-2 {
    & > div {
      margin-bottom: 15px;
    }
  }
  .section-3 {
    & > div {
      margin-bottom: 15px;
    }
  }
}
</style>
