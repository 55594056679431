<template>
  <b-modal
    :id="`compose-mail`"
    ref="nodal_sticky_compose"
    :visible="shallShowEmailComposeModal"
    title="Write Note"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    no-close-on-backdrop
    hide-backdrop
    static
    @hidden="hiddenModal"
  >

    <!-- Modal: Body -->
    <div class="p-2">
      <validation-observer ref="form">
        <b-form>
          <!-- Field: Subject -->
          <div class="compose-mail-form-field">
            <label for="email-subject">Category:</label>
            <div
              class="
                form-control
                d-flex
                justify-content-start
                align-items-center
              "
              :style="isDarkSkin ? 'background-color: #17171A' : 'background-color:white'"
            >
              <span
                v-for="color in colors"
                :key="color.id"
                class="mr-1"
              >
                <div
                  class="border-category"
                  :style="`
                    border: 2px ${
                    noteData.colorId == color.id
                      ? color.category
                      : 'transparent'
                  } solid;`"
                  @click="noteData.colorId = color.id"
                >
                  <div
                    class="color-category"
                    :style="`background: ${color.category};`"
                  />
                </div>
              </span>
            </div>
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            rules="required"
          >
            <div class="compose-mail-form-field">
              <label for="email-subject">Title:</label>

              <b-form-input
                id="email-subject"
                v-model="noteData.title"
                :state="errors[0] ? false : valid ? true : null"
              />
            </div>
            <span
              v-if="errors[0]"
              class="ml-2 text-danger"
              style="font-size: 13px"
            >
              Title {{ errors[0] }}
            </span>
          </validation-provider>

          <!-- Field: Message - Quill Editor -->

          <div class="message-editor">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <div style="height: 250px; overflow: auto">
                <quill-editor
                  id="quil-content"
                  ref="quillEditor"
                  v-model="noteData.note"
                  :options="editorOption"
                  style="overflow: auto;height:90%;margin-top:10px"
                />
              </div>
              <span
                v-if="errors[0]"
                class="ml-2 text-danger"
                style="font-size: 13px"
              >Note {{ errors[0] }}</span>
            </validation-provider>

            <div
              :id="toolbarId"
              class="d-flex border-bottom-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-loading.full="isLoading"
          variant="primary"
          @click="saveUserNote()"
        >
          Save
        </b-button>

        <b-button
          v-if="noteEdit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-loading.full="isLoading"
          class="ml-1"
          variant="danger"
          @click="deleteNotesUser()"
        >
          Delete
        </b-button>
        <!-- v-loading.full="{background: 'rgba(255,255,255,.5)', show: isLoading, color: '#FF9F43'} " -->
      </div>
      <div>{{ moment() | myGlobal }}</div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import StickyNotesService from './service/sticky-notes-service';

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    note: {
      type: Object,
      required: false,
      default: null,
    },
    noteEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar-1',
        },
        placeholder: 'Note',
      },
      toolbarId: 'quill-toolbar',
      noteData: {
        title: null,
        note: null,
        userId: null,
        noteId: null,
        colorId: null,
      },
      colors: [],
      isLoading: false,
      isLoadingFull: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    note(newVal) {
      this.noteData.title = newVal.subject;
      this.noteData.note = JSON.parse(newVal.content);
      this.noteData.noteId = newVal.id;
      this.noteData.colorId = this.colors.find(
        color => color.category === newVal.category,
      ).id;
      // Mixin create class
    },
  },
  created() {
    if (this.note) {
      this.toolbarId = 'quill-toolbar-view';
    } else {
      this.toolbarId = 'quill-toolbar-create';
    }
    this.editorOption.modules.toolbar = `#${this.toolbarId}`;

    if (this.noteEdit) {
      this.noteData.title = this.noteEdit.subject;
      this.noteData.note = JSON.parse(this.noteEdit.content);
      this.noteData.noteId = this.noteEdit.id;
      this.noteData.colorId = this.colors.find(
        color => color.category === this.noteEdit.category,
      ).id;
    }
  },
  mounted() {
    this.getColorNotes();
  },
  methods: {
    ...mapActions({
      setNotes: 'sticky-notes/setNotes',
      getNotes: 'sticky-notes/getNotes',
    }),
    discardEmail() {
      this.$emit('update:shall-show-email-compose-modal', false);
    },
    moment() {
      return moment();
    },
    async getColorNotes() {
      const data = await StickyNotesService.getColorNotes();
      this.colors = data;
      if (this.colors[0]) {
        this.noteData.colorId = this.colors[0].id;
      }
    },
    saveUserNote() {
      this.$refs.form.validate().then(async confirm => {
        if (!confirm) {
          return;
        }
        this.noteData.userId = this.currentUser.user_id;
        try {
          const res = await StickyNotesService.saveUserNote(this.noteData);
          this.noteData.noteId = res.id;
          this.getNotes(this.currentUser.user_id);
          this.showSuccessSwal();
          this.$emit('update:shall-show-email-compose-modal', false);
          this.resetData();
          this.$emit('getNotesUser');
          this.$emit('closeModal');
        } catch (error) {
          this.showErrorSwal();
          console.log(error);
        }
      });
    },
    async deleteNotesUser() {
      try {
        const resultState = await this.showConfirmSwal();
        if (!resultState.isConfirmed) return;
        this.addPreloader();
        const obj = {
          id_user: this.currentUser.user_id,
          id: this.noteData.noteId,
        };
        const res = await StickyNotesService.deleteNotesUser(obj);
        if (res.status === 200) {
          this.$emit('getNotesUser');
          this.$emit('closeModal');
          this.showSuccessSwal();
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    resetData() {
      this.noteData = {
        title: null,
        note: null,
        userId: null,
        noteId: null,
        colorId: null,
      };
    },
    hiddenModal() {
      this.$emit('hiddenAction');
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
.border-category {
  width: 35px;
  height: 22px;
  border-radius: 4px;
  opacity: 1;
  overflow: hidden;
  padding: 0px 1px 1px 1px !important;
  position: relative;
  cursor: pointer;

  .color-category {
    border-radius: 3px;
    width: 29px;
    height: 18px;
    opacity: 0.8;
    position: absolute;
  }
}

form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
