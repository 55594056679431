<template>
  <div
    class="d-flex"
    style="align-items:center"
  >
    <div
      class="cursor-pointer"
      style="padding:5px"
      @click="changeMonth(-1)"
    >
      <tabler-icon
        icon="ChevronLeftIcon"
        class="feather-icon-arrow-left cursor-pointer text-primary"
        size="16"
      />
    </div>
    <span style="font-size: 25px; font-family: Avenir; font-weight: 900; line-height: 32.12px; word-wrap: break-word">{{ getMonthName(currentMonth) }} {{ currentYear }}</span>
    <div
      class="cursor-pointer"
      style="padding:5px"
      @click="changeMonth(1)"
    >
      <tabler-icon
        icon="ChevronRightIcon"
        class="feather-icon-arrow-right cursor-pointer  text-primary"
        size="16"
      />
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {

  },
  data() {
    return {
      currentMonth: new Date().getMonth() + 1, // Obtiene el mes actual (1-12)
      currentYear: new Date().getFullYear(), // Obtiene el año actual
      monthNames: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
      valueFilterMonth: null,
      valueFilterYear: null,
    };
  },
  mounted() {
  },
  methods: {
    getMonthName(month) {
      return this.monthNames[month - 1];
    },
    changeMonth(delta) {
    // Cambiar el mes
      this.currentMonth += delta;
      // Si el mes es mayor que 12, avanzar al siguiente año
      if (this.currentMonth > 12) {
        this.currentMonth = 1; // Vuelve a enero
        this.currentYear += 1;
      }
      // Si el mes es menor que 1, retroceder al año anterior
      if (this.currentMonth < 1) {
        this.currentMonth = 12; // Vuelve a diciembre
        this.currentYear -= 1;
      }
      console.log(this.currentMonth, this.currentYear);
      this.$emit('changeMonth', this.currentMonth, this.currentYear);
    },
  },
};
</script>
