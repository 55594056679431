<template>
  <div
    class="d-flex welcome-container"
    :class="!isDarkSkin ? 'bg-white ' : ''"
    :style="isDarkSkin ? 'background-color: #17171A' : ''"
    style="
      width: 100%;
      padding: 2rem;
      height: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    "
  >
    <div class="welcome-container-greetings pt-2">
      <div class="text-dark" style="width: 100%">
        <span
          style="
            font-size: 20px;
            font-family: Avenir;
            font-weight: 800;
            line-height: 25.7px;
            word-wrap: break-word;
          "
          >{{ greetingMessage }}, <br />
        </span>
        <span
          style="
            font-size: 25px;
            font-family: Avenir;
            font-weight: 900;
            line-height: 32.12px;
            word-wrap: break-word;
          "
          >{{ user.fullName }}
        </span>
      </div>
      <div
        style="
          font-size: 18px;
          font-family: Avenir;
          font-weight: 500;
          line-height: 23.2px;
          letter-spacing: 0.36px;
          word-wrap: break-word;
        "
      >
        {{ result.name }}
      </div>
      <div
        v-if="result.days_worked > 0"
        class="text-dark"
        style="
          padding-top: 12px;
          width: 100%;
          font-size: 17px;
          font-family: Inter;
          font-weight: 400;
          line-height: 26.67px;
          word-wrap: break-word;
        "
      >
        <!-- “{{ randomPhrase }}" -->
        Thank you for being part of the AMG family since
        <strong>{{ result.start_date | myGlobal }}</strong
        >. Your contribution during these
        <strong>
          {{ result.years_worked > 0 ? result.years_worked : "" }}
        </strong>
        <strong><span v-if="result.years_worked >= 1">years</span></strong>
        <strong>
          {{ result.months_worked > 0 ? result.months_worked : "" }}
        </strong>
        <strong><span v-if="result.months_worked >= 1">months</span></strong>
        <strong>
          {{ result.days_worked > 0 ? result.days_worked : "" }}
        </strong>
        <strong><span v-if="result.days_worked >= 1">days</span></strong>
        have been very valuable for the growth of the company.
      </div>

      <div
        v-else
        class="text-dark"
        style="
          padding-top: 12px;
          width: 100%;
          font-size: 17px;
          font-family: Inter;
          font-weight: 400;
          line-height: 26.67px;
          word-wrap: break-word;
        "
      >
        <!-- “{{ randomPhrase }}" -->
        <span>
          <strong>Welcome</strong>, Thank you for being part of the
          <strong>AMG Family,</strong>
          <br />
          Your contribution would be very valuable to
          <strong> grow together.</strong>
        </span>
      </div>
    </div>
    <module-selector />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Services from "@/views/assistance/views/dashboard/services/dashboard.services";
import ModuleSelector from "@/views/main/dashboard-components/ModuleSelector.vue";

export default {
  components: {
    ModuleSelector,
  },
  props: {},

  data() {
    return {
      user: {},
      currentHour: "",
      greetingMessage: null,
      result: {
        name: "",
        start_date: "",
        years_worked: "",
        months_worked: "",
        days_worked: "",
      },
      motivationalPhrases: {},
      randomPhrase: null,
      currentDay: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
  },

  watch: {},
  mounted() {
    this.user = this.currentUser;
    this.updateCurrentHour();
    this.updateGreetingMessage();
    this.getRolBUyser();
    this.getMotivationalPhrases();
    const currentDate = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    this.currentDay = currentDate.toLocaleDateString("es-ES", options);
    // console.log(this.currentDay);
  },

  methods: {
    ...mapActions({
      A_UPDATE_PROFILE: "ProfileStore/A_UPDATE_PROFILE",
      updateCurrentUserInformation: "auth/updateCurrentUserInformation",
    }),
    updateCurrentHour() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      this.currentHour = `${hours}:${minutes}:${seconds}`;
    },
    updateGreetingMessage() {
      const now = new Date();
      const hours = now.getHours();
      let greetingMessage = "";

      if (hours >= 5 && hours < 12) {
        greetingMessage = "Good Morning";
      } else if (hours >= 12 && hours < 18) {
        greetingMessage = "Good Afternoon";
      } else {
        greetingMessage = "Good night";
      }
      this.greetingMessage = greetingMessage;
    },
    async getRolBUyser() {
      try {
        const params = {
          userId: this.currentUser.user_id,
        };
        const { data } = await Services.getRolByUser(params);
        this.errorMessage = false;
        if (data.length > 0) {
          this.result = data[0];
        }
        // console.log(this.result);
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async getMotivationalPhrases() {
      try {
        const data = await Services.getMotivationalPhrases();
        this.errorMessage = false;
        this.motivationalPhrases = data.data;
        const randomIndex = Math.floor(
          Math.random() * this.motivationalPhrases.length
        );
        const randomObject = this.motivationalPhrases[randomIndex];
        this.randomPhrase = randomObject.phrase;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.cdnfonts.com/css/avenir");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.welcome-container-greetings {
  max-width: 30rem;
  height: 100%;
}
.welcome-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 20em;
}
@media (max-width: 820px) {
  .welcome-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
