<template>
  <b-card class="pt-2 pl-2 pr-2 pb-0">
    <b-row class="pl-1 position-relative">
      <!-- <b-col
        cols="12"
        class="d-flex justify-content-end align-items-start"
      > -->
      <b-button
        variant="primary"
        style="white-space: nowrap;position: absolute;top:0px;right: 10px;z-index: 1;"
        @click="showModalSchedule"
      >
        <feather-icon icon="ClockIcon" />
        VIEW SCHEDULE
      </b-button>
      <!-- </b-col> -->
      <b-col
        cols="12"
        class="mt-2"
      >
        <div class="d-flex justify-content-start align-items-center">
          <img
            :src="require('@/assets/images/icons/schedule_icon.svg')"
            style="width: 65px;height: 65px;"
            class="mr-1"
          >
          <div class="d-flex flex-column justify-content-left">
            <strong
              style="font-size: 16px;font-weight: 900;line-height: 16px;"
              :class="isDarkSkin ? 'text-white' : 'text-black'"
            >Today</strong>
            <div class="d-flex">
              <strong
                class="mr-1"
                :class="isDarkSkin ? '' : 'text-primary'"

                style="font-size: 40px;"
              >{{ dayInNumber }}</strong>
              <span
                class="d-flex justify-content-start align-items-center"
                style="font-size: 18px;line-height:18px;max-width: 150px;"
              >{{ currentDay }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card
          style="margin:10px 0px 0px 0px !important; padding:0 !important"
          :style="isDarkSkin ? 'background: #222222; border: solid 5px #222222':'background: white;  border: solid 5px white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);'"
        >
          <b-list-group
            horizontal="xl"
            class="w-100 pointer-events-none"
            style="padding: 10px 5px; background-color:transparent !important"
          >
            <b-list-group-item
              class="d-flex flex-column text-center w-100 border-right"
              style="border: none"
              :style="isDarkSkin ? 'background: #222222; ':'background: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); '"
            >
              <strong
                v-if="start_time"
                class="time-marks"
              >{{ start_time | myFullTime }}</strong>
              <span
                v-else
                class="time-marks"
              >--:--</span>
              <span class="time-text">Clock In</span>
              <b-badge
                v-if="isLate"
                class="time-text"
                variant="danger"
                style="margin-top: 10px;"
              >
                Late
              </b-badge>
            </b-list-group-item>
            <template v-if="hours_to_work === 8">
              <b-list-group-item
                :style="isDarkSkin ? 'background: #222222; ':'background: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); '"
                class="d-flex flex-column text-center w-100 border-right"
                style="border: none;"
              >
                <strong
                  v-if="start_break"
                  class="time-marks"
                >{{ start_break | myFullTime }}</strong>
                <span
                  v-else
                  class="time-marks"
                >--:--</span>
                <span class="time-text">Start  Break</span>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex flex-column text-center w-100 border-right"
                style="border: none;"
                :style="isDarkSkin ? 'background: #222222; ':'background: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); '"
              >
                <strong
                  v-if="end_break"
                  class="time-marks"
                >{{ end_break | myFullTime }}</strong>
                <span
                  v-else
                  class="time-marks"
                >--:--</span>
                <span class="time-text">End of break</span>
              </b-list-group-item>
            </template>
            <b-list-group-item
              class="d-flex flex-column text-center w-100"
              style="border: none;"
              :style="isDarkSkin ? 'background: #222222; ':'background: white; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); '"
            >
              <strong
                v-if="end_time"
                class="time-marks"
              >{{ end_time | myFullTime }}</strong>
              <strong
                v-else
                class="time-marks"
              >--:--</strong>
              <span class="time-text">Clock Out</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <!--MODAL SCHEDULE-->
    <modal-schedule
      v-if="paramsModalSchedule.showSchedule"
      :params="paramsModalSchedule"
      @closeModal="paramsModalSchedule.showSchedule = false"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ProfileService from '@/views/main/menu.service';
import ModalSchedule from '@/views/main/dashboard-components/ScheduleAndHours/ModalSchedule.vue';

export default {
  components: {
    ModalSchedule,
  },
  props: {
    month: {
      type: Number,
      default: 0,
      required: false,
    },
    year: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      currentDate: null,
      dayInNumber: null,
      currentDay: null,
      lastDay: parseInt(moment(`${this.year}-${this.month}`, 'YYYY-MM').endOf('month').format('DD'), 10),
      isLate: false,
      paramsModalSchedule: {
        showSchedule: false,
        user_id: null,
        month: this.month,
        year: this.year,
      },
      start_time: '00:00',
      start_break: '00:00',
      end_break: '00:00',
      end_time: '00:00',
      hours_to_work: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async mounted() {
    const day = moment().format('DD');
    this.currentDate = moment(`${this.year}-${this.month}-${day}`).format('YYYY-MM-DD');
    this.currentDay = moment(`${this.year}-${this.month}-${day}`, 'YYYY-MM-DD').format('dddd MMMM, YYYY');
    this.dayInNumber = parseInt(moment(`${this.year}-${this.month}-${day}`, 'YYYY-MM-DD').format('DD'), 10);
    if (this.month > 0 && this.year > 0) {
      await this.getMarksEmployeeByDay();
    }
  },
  methods: {
    showModalSchedule() {
      this.paramsModalSchedule.showSchedule = true;
      this.paramsModalSchedule.user_id = this.currentUser.user_id;
    },
    async getMarksEmployeeByDay() {
      try {
        const params = {
          filterDay: this.currentDate,
          filterMonth: null,
          filterWeekEnd: null,
          filterWeekStart: null,
          filterYear: null,
          user_id: this.currentUser.user_id,
        };
        const { data } = await ProfileService.getMarksEmployeeByDay(params);
        const {
          first_mark, second_mark, third_mark, fourth_mark, type, hours_to_work, last_mark,
        } = data.data?.[0] || {
          first_mark: null, second_mark: null, third_mark: null, fourth_mark: null, type: null, hours_to_work: 8, lask_mark: null,
        };

        this.start_time = first_mark;
        this.start_break = second_mark;
        this.end_break = third_mark;
        this.end_time = hours_to_work === 8 ? fourth_mark : last_mark;
        this.isLate = type ? ['late', 'delay'].includes(type.toLowerCase()) : false;
        this.hours_to_work = hours_to_work;
      } catch (error) {
        console.log('error in getTotalPerMonth:', error);
      }
    },
  },
};
</script>
<style scoped>
*{
  font-family: Avenir;
}
.time-marks{
  font-size: 18px;
  font-weight: 800px;
}
.time-text{
  font-size: 16px;
  line-height: 16px;
  margin: 2px 0px;
}
.card-body{
  padding: 0 !important
}
</style>
