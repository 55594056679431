<template>
  <div
    :class="!isDarkSkin ? 'bg-white' : ''"
    :style="isDarkSkin ? 'background-color: #17171A' : ''"
    style="width: 100%; padding: 2rem; height: 100%;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); border-radius: 10px"
  >
    <div
      class="d-flex justify-content-between"
      style="align-items:center;"
    >
      <div
        class="text-dark"
        style="font-size: 22px; max-width:140px; font-family: Avenir; font-weight: 900; line-height: 23.10px; word-wrap: break-word"
      >
        News of the month
      </div>
      <div>
        <div
          class="d-flex"
          style="align-items:center"
        >
          <div
            style="padding:0.5rem"
            class="cursor-pointer"
            @click="changeMonth(-1)"
          >
            <tabler-icon
              icon="ChevronLeftIcon"
              class="feather-icon-arrow-left cursor-pointer text-primary"
              size="20"
            />
          </div>
          <div style="text-align: center; font-size: 20px; font-family: Avenir; font-weight: 800; word-wrap: break-word">
            {{ getMonthName(currentMonth) }} {{ currentYear }}
          </div>
          <div
            style="padding:0.5rem"
            class="cursor-pointer"
            @click="changeMonth(1)"
          >
            <tabler-icon
              icon="ChevronRightIcon"
              class="feather-icon-arrow-right cursor-pointer text-primary"
              size="20"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
    >
      <div
        class="text-dark py-1"
        style="font-size: 17px; font-family: Avenir; font-weight: 800; line-height: 26.67px; word-wrap: break-word; padding: 0.5rem 0"
      >
        <b-img
          src="~@/assets/images/icons/birthday.svg"
        />
        Birthdays ({{ results.length > 0 ? results[0].total : 0 }})
      </div>
      <div class="birthday-container">
        <ul
          v-if="results.length === 0"
        >
          <li>
            <!-- Display a "Not found" message when there are no birthdays items -->
            <div
              class="text-dark"
              style="font-size: 17px; font-family: Avenir; font-weight: 500; line-height: 21.84px; word-wrap: break-word"
            >
              No Birthdays found.
            </div>
          </li>
        </ul>
        <b-list-group-item
          v-for="(result, index) in results"
          v-else
          :key="index"
          class="d-flex align-items-center m-0 p-0"
          style="background-color: transparent; border:none; padding: 0.5rem 0 !important"
        >
          <b-avatar
            variant="info"
            :src="result.photo"
            :text="result.first_name.charAt(0) + result.last_name.charAt(0)"
            style="margin: 0 12px 0 0;"
          />
          <div
            class="d-flex flex-column"
            style="font-size: 17px; font-family: Avenir; font-weight: 500; line-height: 21.84px; word-wrap: break-word"
          >
            <span class="mr-auto">{{ capitalizeFirstLetter(result.first_name) }} {{ capitalizeFirstLetter(result.last_name) }}</span>
            <span>{{ result.day_of_week }} {{ result.day_of_month }}</span>
          </div>
        </b-list-group-item>
      </div>
    </div>
    <div
      v-else
      class="loading-spinner"
      style="margin-top:5rem;margin-bottom:5rem"
    >
      <b-col
        cols="12"
        class="d-flex"
        style="align-items:center;justify-content:center"
      >
        <div class="spinner" />
      </b-col>
    </div>
    <div>
      <div
        class="text-dark"
        style="font-size: 17px; font-family: Avenir; font-weight: 800; line-height: 26.67px; word-wrap: break-word; padding: 2rem 0 .5rem 0"
      >
        <b-img
          src="~@/assets/images/icons/holidays.svg"
        />
        Holidays ({{ resultsHolidays.length > 0 ? resultsHolidays[0].total : 0 }})
      </div>
      <ul
        class="p-0 m-0 d-flex justify-content-start pl-5 flex-wrap: wrap holiday-container"
        style="gap:2rem 7rem; flex-wrap: wrap"
      >
        <li v-if="resultsHolidays.length === 0">
          <!-- Display a "Not found" message when there are no resultHoliday items -->
          <div
            class="text-dark"
            style="font-size: 17px; font-family: Avenir; font-weight: 500; line-height: 21.84px; word-wrap: break-word"
          >
            No holidays found.
          </div>
        </li>
        <li
          v-for="(resultHoliday, index) in resultsHolidays"
          v-else
          :key="index"
        >
          <div
            class="d-flex align-items-center"
            style="background-color: transparent; border: none;"
          >
            <div
              class="d-flex flex-column"
              style="font-size: 17px; font-family: Avenir; font-weight: 500; line-height: 21.84px; word-wrap: break-word"
            >
              <span class="mr-auto">{{ capitalizeFirstLetter(resultHoliday.description) }}</span>
              <span class="mr-auto">{{ resultHoliday.day }}/{{ resultHoliday.month }}</span>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Services from '@/views/assistance/views/dashboard/services/dashboard.services';

export default {
  components: {
  },
  props: {
  },

  data() {
    return {

      loading: true,
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      results: [],
      totalBirthdays: null,
      resultsHolidays: [],
      formattedCurrentMonth: '',

      currentMonth: new Date().getMonth() + 1, // Obtiene el mes actual (1-12)
      currentYear: new Date().getFullYear(), // Obtiene el año actual
      monthNames: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
      valueFilterMonth: null,
      valueFilterYear: null,

      process: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      CounterEmployee: 'EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS',
    }),
  },
  async mounted() {
    this.process = process.env;
    await this.getBirthdays();
    await this.getHolidays();
  },
  created() {
    this.formattedCurrentMonth = this.calculateFormattedCurrentMonth();
  },
  methods: {
    ...mapActions({
      A_UPDATE_PROFILE: 'ProfileStore/A_UPDATE_PROFILE',
      updateCurrentUserInformation: 'auth/updateCurrentUserInformation',
    }),
    async getBirthdays() {
      this.loading = true;
      try {
        const params = {
          current_year: this.currentYear,
          current_month: this.currentMonth,
        };
        const data = await Services.getBirthdays(params);
        this.loading = false;
        this.errorMessage = false;
        this.results = data.data.data;
        this.totalBirthdays = this.results[0].total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async getHolidays() {
      try {
        const params = {
          current_month: this.currentMonth.toString().padStart(2, '0'), // Formatea el número del mes
        };
        const data = await Services.getHolidays(params);
        this.errorMessage = false;
        this.resultsHolidays = data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    getMonthName(month) {
      return this.monthNames[month - 1];
    },
    changeMonth(delta) {
    // Cambiar el mes
      this.currentMonth += delta;
      // Si el mes es mayor que 12, avanzar al siguiente año
      if (this.currentMonth > 12) {
        this.currentMonth = 1; // Vuelve a enero
        this.currentYear += 1;
      }
      // Si el mes es menor que 1, retroceder al año anterior
      if (this.currentMonth < 1) {
        this.currentMonth = 12; // Vuelve a diciembre
        this.currentYear -= 1;
      }
      // console.log(this.currentMonth, this.currentYear);
      //   this.$emit('changeMonth', this.currentMonth, this.currentYear);
      this.getBirthdays();
      this.getHolidays();
    },
    calculateFormattedCurrentMonth() {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      return month;
    },
    capitalizeFirstLetter(name) {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    },
  },
};
</script >
<style scoped>
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 2s linear infinite;
  margin-bottom: 10px;
}

.padspace{
  padding: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.cdnfonts.com/css/avenir');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  .birthday-container {
    max-height: 220px;
    overflow-y: auto;
  }

  /* Estilos para el contenedor de vacaciones */
  .holiday-container {
    max-height: 150px;
    overflow-y: auto;
  }
</style>
