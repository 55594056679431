<template>
  <div style="height:100%">
    <b-card
      tag="article"
      style="margin:0; height:100%"
    >
      <b-card-header
        class="mb-2 d-flex justify-content-between align-items-center"
        style="padding: 1rem 0 !important;"
      >
        <div
          class="text-dark"
          style="font-size: 22px; max-width:140px; font-family: Avenir; font-weight: 900; line-height: 23.10px; word-wrap: break-word;"
        >
          My Payroll Payments
        </div>
        <div>
          <b-button
            href="#"
            variant="info"
            @click="downloadCurrentPayroll()"
          >
            <strong><feather-icon
              icon="RefreshCwIcon"
              size="13"
            /></strong>
          </b-button>

          <b-button
            href="#"
            :variant="buttonVariant"
            class="ml-1"
            @click="redirectToDashboard()"
          >
            <strong>View All</strong>
          </b-button>

        </div>

        <strong
          v-if="payrollSendedExists"
          :class="{ 'blinking': blinking}"
        >You have your pending payroll for signing. Please sign it as soon as possible.</strong>

      </b-card-header>

      <div
        v-for="(item, index) in pdfArray"
        :key="index"
      >
        <div class="d-flex align-items-center">
          <b-img
            src="~@/assets/images/icons/pdf1.svg"
            rounded="circle"
            width="40"
            height="40"
            alt="Circle image"
          />
          {{ item.file_name }}
          <b-badge
            href="#"
            variant="primary"
            class="ml-auto"
            style="background-color:#A8DFFF;color:black;"
            @click="contentClicked(item.file_approved_route)"
          >
            <feather-icon
              icon="DownloadIcon"
              size="15"
            />
          </b-badge>
        </div>
        <hr class="hr">
      </div>
      <div
        v-if="pdfArray.length == 0"
        class="d-flex align-items-center justify-content-center"
      >
        <div class="center-content text-warning mt-1">
          <b-row>
            <feather-icon
              icon="SmileIcon"
              size="40"
            />
          </b-row>
          <b-row>
            <h4 class="text-warning mt-1">
              <strong>No payroll payments yet</strong>
            </h4>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Service from '@/views/main/menu.service';

export default {
  data() {
    return {
      pdfArray: [],
      payrollSendedExists: false,
      blinking: false,

    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    buttonVariant() {
      return this.pdfArray.length === 0 ? 'warning' : 'primary';
    },
  },

  mounted() {
    this.downloadCurrentPayroll();
  },
  methods: {
    async downloadCurrentPayroll() {
      try {
        this.addPreloader();
        const obj = {
          id_user: this.currentUser.user_id,
        };
        const res = await Service.downloadCurrentPayroll(obj);
        if (res.status === 200) {
          this.pdfArray = res.data.payroll;
          this.payrollSendedExists = res.data.payrollSendedExists;
          if (this.payrollSendedExists) {
            this.blinking = true;
          }
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    redirectToDashboard() {
      const directToPayroll = 1;
      this.$router.push({ name: 'user-attendance-dashboard', params: { directToPayroll } });
    },
    contentClicked(url) {
      window.open(
        url,
        '_blank',
      );
    },
  },
};
</script>
<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

 .blinking {
   animation: blinker 0.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
 }

@keyframes blinker {
  from {
    color: inherit;
  }
  to {
    color: red; /* Puedes ajustar el color aquí */
  }
}

</style>
