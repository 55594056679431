export default [
  {
    key: 'image',
    label: 'File',
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'employee_name',
    label: 'Employee Name',
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'start_date',
    label: 'Start date',
    sortable: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'end_date',
    label: 'End Date',
    sortable: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'hours_of_work',
    label: 'Hours of work',
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'name_justification',
    label: 'Type',
    width: '100px',
    visible: true,
  },
  {
    key: 'observation',
    label: 'Motive',
    width: '100px',
    visible: true,
  },
  {
    key: 'process',
    label: 'Process',
    width: '100px',
    visible: true,

  },
  {
    key: 'created_at',
    label: 'Created at',
    width: '100px',
    visible: true,
  },
  {
    key: 'tracking',
    label: 'Tracking',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  {
    key: 'actions',
    label: 'Actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
];
